import React, { useState, useCallback, useEffect } from "react"
import styled from "@emotion/styled"
import { Box, BoxProps, IconButton, Flex } from "@chakra-ui/core"
import { useEmblaCarousel } from "embla-carousel/react"
import { EmblaCarouselType } from "embla-carousel/embla-carousel-vanilla"
import colors from "../../constants/colors"
import useInterval from "../../hooks/useInterval"

type SliderProps = {
  items: React.ReactNodeArray
  startIndex?: number
  containerProps?: BoxProps
  slideContainerProps?: BoxProps
  dotsProps?: BoxProps
  draggable?: boolean
  onNext?: () => void
  onPrev?: () => void
  autoPlay?: boolean
  autoPlayInterval?: number
  hideArrows?: boolean
  loop?: boolean
  slidesToScroll?: number
  children: (options: {
    Slides: React.ReactNode
    Dots: React.ReactNode
    embla: EmblaCarouselType | undefined
    scrollTo: (index: any) => void | undefined
    selectedIndex: number
    scrollSnaps: number[]
    setScrollSnaps: (scrollSnaps: number[]) => void
    scrollNext: () => void
    scrollPrev: () => void
  }) => JSX.Element
}

const Embla = styled(Box)`
  overflow: hidden;
  height: 100%;
`

const EmblaViewport = styled(Box)`
  overflow: hidden;
  width: 100%;
  height: 100%;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
  &.is-dragging {
    cursor: grabbing;
  }
`

const EmblaContainer = styled(Box)`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
`
const EmblaSlide = styled(Box)`
  position: relative;
  height: 100%;
  flex: 0 0 100%;
`

const NavButton = styled(IconButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1000;
  border-radius: 100px;
`

export const PrevButton = ({ enabled, onClick }) => (
  <NavButton
    left="0.75rem"
    onClick={onClick}
    isDisabled={!enabled}
    icon="chevron-left"
    aria-label="Previous Image"
  />
)

export const NextButton = ({ enabled, onClick }) => (
  <NavButton
    right="0.75rem"
    onClick={onClick}
    isDisabled={!enabled}
    icon="chevron-right"
    aria-label="Next Image"
  />
)

export const DotButton = styled.button<{ selected: boolean }>`
  border-radius: 100px;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${({ selected }) =>
    selected ? colors.primary : colors.lightGray};
  margin: 0 0.5rem;
`

const Slider = ({
  items,
  startIndex = 0,
  children,
  containerProps,
  slideContainerProps,
  dotsProps,
  onNext,
  onPrev,
  draggable = true,
  hideArrows = false,
  autoPlay = false,
  autoPlayInterval = 5000,
  loop = false,
  slidesToScroll = 1,
}: SliderProps): ReturnType<SliderProps["children"]> => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    startIndex,
    draggable,
    loop,
    slidesToScroll,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [pause, setPause] = useState(false)

  const scrollPrev = useCallback(
    (pause?: boolean) => {
      embla && embla.scrollPrev()
      onPrev?.()
      pause && setPause(true)
    },
    [embla]
  )
  const scrollNext = useCallback(
    (pause?: boolean) => {
      embla && embla.scrollNext()
      onNext?.()
      pause && setPause(true)
    },
    [embla]
  )
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla,
  ])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useInterval(
    () => {
      if (embla) embla.scrollNext()
    },
    autoPlay && !pause ? autoPlayInterval : null
  )

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
  }, [embla, setScrollSnaps, onSelect])

  const onClick = () => {
    setPause(true)
  }

  const onKeyPress: BoxProps["onKeyPress"] = (e) => {
    if (e.key === " ") {
      setPause(!pause)
    } else if (e.key === "ArrowLeft") {
      scrollPrev()
    } else if (e.key === "ArrowRight") {
      scrollNext()
    }
  }

  const Slides = (
    <Box
      {...containerProps}
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={1}
    >
      <Embla>
        {!hideArrows && (
          <>
            <PrevButton onClick={() => scrollPrev()} enabled={prevBtnEnabled} />
            <NextButton onClick={() => scrollNext()} enabled={nextBtnEnabled} />
          </>
        )}
        <EmblaViewport ref={viewportRef}>
          <EmblaContainer className="cb_slide_container" {...slideContainerProps}>
            {items.map((item, index) => (
              <EmblaSlide className="cb_slide" key={index}>{item}</EmblaSlide>
            ))}
          </EmblaContainer>
        </EmblaViewport>
      </Embla>
    </Box>
  )

  const Dots = (
    <Flex
      width="100%"
      align="center"
      justify="center"
      py="0.75rem"
      {...dotsProps}
    >
      {scrollSnaps.map((_, index) => (
        <DotButton
          key={index}
          selected={index === selectedIndex}
          onClick={() => scrollTo(index)}
        />
      ))}
    </Flex>
  )

  return children({
    Slides,
    Dots,
    embla,
    scrollTo,
    selectedIndex,
    scrollSnaps,
    setScrollSnaps,
    scrollNext,
    scrollPrev,
  })
}

export default Slider
