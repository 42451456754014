const requireAll = (o: any) => {
  Object.keys(o).forEach((k) => {
    if (o[k] === undefined || o[k] === null) {
      throw new Error(`Key ${k} is missing from config`)
    }
  })
  return o
}

const Config = {
  GATSBY_SHOPIFY_STOREFRONT_API_KEY:
    process.env.GATSBY_SHOPIFY_STOREFRONT_API_KEY,
  GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT:
    process.env.GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT,
  GATSBY_SHOPIFY_ADMIN_API_KEY:
    process.env.GATSBY_SHOPIFY_ADMIN_TOKEN,
  GATSBY_SHOPIFY_SHOP_NAME:
    process.env.GATSBY_SHOPIFY_SHOP_NAME,
  GATSBY_CONTENTFUL_SPACE_ID: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  GATSBY_CONTENTFUL_ACCESS_TOKEN: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  GATSBY_CONTENTFUL_HOST: process.env.GATSBY_CONTENTFUL_HOST,
  GATSBY_ORDER_CANCELLATION_GOOGLE_SHEETS_ID:
    process.env.GATSBY_ORDER_CANCELLATION_GOOGLE_SHEETS_ID,
  GATSBY_SHIPPING_ADDRESS_GOOGLE_SHEETS_ID:
    process.env.GATSBY_SHIPPING_ADDRESS_GOOGLE_SHEETS_ID,
  GATSBY_ITEM_STOCK_GOOGLE_SHEETS_ID:
    process.env.GATSBY_ITEM_STOCK_GOOGLE_SHEETS_ID,
  GATSBY_ORDER_TRACKING_GOOGLE_SHEETS_ID:
    process.env.GATSBY_ORDER_TRACKING_GOOGLE_SHEETS_ID,
  GATSBY_WRONG_BIKE_GOOGLE_SHEETS_ID:
    process.env.GATSBY_WRONG_BIKE_GOOGLE_SHEETS_ID,
  GATSBY_MISSING_BIKE_GOOGLE_SHEETS_ID:
    process.env.GATSBY_MISSING_BIKE_GOOGLE_SHEETS_ID,
  GATSBY_GOOGLE_CLIENT_ID: process.env.GATSBY_GOOGLE_CLIENT_ID,
  GATSBY_FACEBOOK_APP_ID: process.env.GATSBY_FACEBOOK_APP_ID,
  GATSBY_LOYALTY_LION_TOKEN: process.env.GATSBY_LOYALTY_LION_TOKEN,
  GATSBY_TRUSTPILOT_USERNAME: process.env.GATSBY_TRUSTPILOT_USERNAME,
  GATSBY_TRUSTPILOT_TEMPLATE_ID: process.env.GATSBY_TRUSTPILOT_TEMPLATE_ID,
  GATSBY_TRUSTPILOT_BUSINESSUNIT_ID:
    process.env.GATSBY_TRUSTPILOT_BUSINESSUNIT_ID,
  GATSBY_SHOPIFY_SHOP_DOMAIN: process.env.GATSBY_SHOPIFY_SHOP_DOMAIN,
  GATSBY_SHOPIFY_SHOP_DOMAIN_ALT:
    process.env.GATSBY_SHOPIFY_SHOP_DOMAIN_ALT || "shop.sixthreezero.com",
  GATSBY_AFFIRM_API_KEY: process.env.GATSBY_AFFIRM_API_KEY,
  GATSBY_ZENDESK_KEY: process.env.GATSBY_ZENDESK_KEY,
  FIREBASE_FUNCTIONS_URL: process.env.GATSBY_FIREBASE_FUNCTIONS_URL,
  GATSBY_FEEDBACK_GOOGLE_SHEETS_ID:
    process.env.GATSBY_FEEDBACK_GOOGLE_SHEETS_ID,
  GATSBY_KLAVIYO_QUIZ_LIST_ID: process.env.GATSBY_KLAVIYO_QUIZ_LIST_ID,
  GATSBY_PINTEREST_TAG_ID: process.env.GATSBY_PINTEREST_TAG_ID || "",
  GATSBY_TIKTOK_PIXEL_ID: process.env.GATSBY_TIKTOK_PIXEL_ID || "",
}

type ConfigType = { [V in keyof typeof Config]: string }

export default requireAll(Config) as ConfigType
