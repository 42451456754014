import {
  Order,
  OrderLineItem,
  OrderLineItemResponse,
  OrderResponse,
} from "./types/OrderTypes"

export const orderMapping = ({ node }: OrderResponse): Order => ({
  id: node.id,
  items: node.lineItems.edges.map(orderItemsMapping),
  orderNumber: node.orderNumber,
  total: node.totalPriceV2.amount,
  subtotal: node.subtotalPriceV2.amount,
  tax: node.totalTaxV2.amount,
  shipping: node.totalShippingPriceV2.amount,
  discounts: node.discountApplications,
  timestamp: node.processedAt,
  fulfillmentStatus: node.fulfillmentStatus,
  fulfillments: node.successfulFulfillments,
  cancelReason: node.cancelReason,
  canceledAt: node.canceledAt,
  totalRefunded: node.totalRefundedV2,
})

export const orderItemsMapping = ({
  node: {
    title,
    quantity,
    variant,
    customAttributes,
    originalTotalPrice,
    discountedTotalPrice,
  },
}: OrderLineItemResponse): OrderLineItem => ({
  quantity: quantity,
  productTitle: title || variant?.product?.title || "N/A",
  variant: variant
    ? {
        id: variant.id,
        sku: variant.sku,
        image: variant.image,
      }
    : null,
  price: {
    currencyCode: originalTotalPrice.currencyCode,
    amount: originalTotalPrice.amount,
  },
  discount: {
    currencyCode: discountedTotalPrice.currencyCode,
    amount: discountedTotalPrice.amount,
  },
  customAttributes,
})
