import React, { useEffect, useState } from "react"
import config from "../../config"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

export const track = (title, data = {}) => {
  if (window) {
    try {
      //@ts-ignore
      window.pintrk("track", title, data)
    } catch (e) {
      console.log(e)
    }
  }
}

export const pintrkPageView = () => {
  if (window) {
    try {
      //@ts-ignore
      window.pintrk("page")
    } catch (e) {
      console.log(e)
    }
  }
}

export const pintrkAddToCart = (value: number, quantity: number) => {
  track("addtocart", {
    value,
    quantity,
    currency: "USD",
  })
}

export const pintrkSignUp = () => {
  track("signup")
}

export const pintrkSearch = (query: string) => {
  track("search", {
    search_query: query,
  })
}

export default function PinterestPixel({ location }) {
  if (!window || !config.GATSBY_PINTEREST_TAG_ID) return null
  const [inited, setInited] = useState(false)
  const { email } = useSelector(({ user }: BaseRootState) => ({
    email: user.email ?? "",
  }))

  useEffect(() => {
    const init = function (e) {
      //@ts-ignore
      if (!window.pintrk) {
        //@ts-ignore
        window.pintrk = function () {
          //@ts-ignore
          window.pintrk.queue.push(Array.prototype.slice.call(arguments))
        }
        //@ts-ignore
        var n = window.pintrk
        ;(n.queue = []), (n.version = "3.0")
        var t = document.createElement("script")
        ;(t.async = !0), (t.src = e)
        var r = document.getElementsByTagName("script")[0]
        r?.parentNode?.insertBefore(t, r)
      }
    }
    init("https://s.pinimg.com/ct/core.js")
    //@ts-ignore
    window.pintrk(
      "load",
      config.GATSBY_PINTEREST_TAG_ID,
      email ? { em: email } : {}
    )
    setInited(true)
  }, [])

  useEffect(() => {
    if (inited) {
      pintrkPageView()
    }
  }, [inited, location.pathname])

  return <></>
}
