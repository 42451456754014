import axios from "axios"

type SubscribeNewsletterData = {
  listId: string
  email: string
  phone_number?: string | null
  sms_consent?: boolean
  [key: string]: any
}

export const subscribeNewsletter = (data: SubscribeNewsletterData) =>
  axios.post("/.netlify/functions/subscribeNewsletter", data)
