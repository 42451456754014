import React from "react"
import { Text, BoxProps } from "@chakra-ui/core"

export type SmallCapsProps = Omit<BoxProps, "size"> & {
  size?: "large" | "lg" | "small" | "sm"
}

const SmallCaps = ({ size = "large", ...props }: SmallCapsProps) => {
  let fontSize
  switch (size) {
    case "large":
    case "lg":
      fontSize = "smallCapsLg"
      break
    case "small":
    case "sm":
      fontSize = "smallCapsSm"
      break
  }

  return (
    <Text
      fontFamily="din"
      fontSize={fontSize}
      lineHeight="normal"
      letterSpacing="wide100"
      textTransform="uppercase"
      {...props}
    ></Text>
  )
}

export default SmallCaps
