import React from "react"
import SmallCaps from "../../typography/SmallCaps"

export default function SectionTitle({ children }) {
  return (
    <SmallCaps
      size="sm"
      fontWeight="bold"
      lineHeight="0.93rem"
      color="primary"
      display="flex"
      alignItems="center"
      marginBottom="1.6rem"
    >
      {children}
    </SmallCaps>
  )
}
