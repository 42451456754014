import React from "react"
import DownArrowSvg from "./down-arrow.svg"
import { Image } from "@chakra-ui/core"

export default function ArrowDownIcon({ className, style, ...props }) {
  return (
    <Image
      className={className}
      style={style}
      src={DownArrowSvg}
      alt="Arrow Down"
      fallbackSrc="/images/emptyImg.png"
      {...props}
    />
  )
}
