import { ModelConfig } from "@rematch/core"
import createShopifyClient from "../../shopify/graphql/createClient"
import DefaultClient from "apollo-boost"
import axios from "axios"
import get from "lodash/get"

import { BaseRootState } from "../store"
import { getIn } from "../../utils"
import {
  UserLogin,
  LogUserOut,
  CreateAccount,
  CustomerAddressCreate,
  CustomerAddressUpdate,
  CustomerDefaultAddressUpdate,
  CustomerSendForgotPasswordEmail,
  MultipassLogin,
  CustomerResetPassword,
  CustomerResetPasswordResponse,
  CustomerActivate,
  CustomerActivateResponse,
} from "../../shopify/graphql/mutations"
import {
  GetCustomerInfo,
  GetShipsToCountries,
} from "../../shopify/graphql/queries"
import { LoginFormValues } from "../../components/Account/LoginForm"
import { BaseSuccessStatusResponse } from "./"
import * as UserTypes from "../types/UserTypes"
import * as OrderTypes from "../types/OrderTypes"
import { orderMapping } from "../utils"
import config from "../../config"

interface UserState extends UserTypes.UserInfo {
  accessToken: string | null
  tokenExpiration: Date | null
  error: any
  orderHistory: Array<OrderTypes.Order>
  bodyFit: UserTypes.BodyFitType | null
  shipsToCountries: string[]
}
const InitialState: UserState = {
  accessToken: null,
  tokenExpiration: null,
  error: null,
  userId: null,
  orderHistory: [],
  bodyFit: null,
  shipsToCountries: ["US"],
}

export const user: ModelConfig<UserState> = {
  state: InitialState,
  reducers: {
    setAccessToken(state, token: string | null, expiration: Date | null) {
      return { ...state, accessToken: token, tokenExpiration: expiration }
    },
    setError(state, error: any) {
      return { ...state, error }
    },
    setAccountInfo(state, userInfo: UserTypes.UserInfo) {
      return { ...state, ...userInfo }
    },
    setOrderHistory(state, orders: Array<OrderTypes.Order>) {
      return { ...state, orderHistory: orders }
    },
    setBodyFit(state, bodyFit: UserState["bodyFit"]) {
      return { ...state, bodyFit }
    },
    setShipsToCountries(state, shipsToCountries: string[]) {
      return { ...state, shipsToCountries }
    },
    resetState() {
      return { ...InitialState }
    },
  },
  effects: (dispatch: any) => ({
    async login(
      { email, password }: LoginFormValues,
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: UserLogin,
          variables: {
            email,
            password,
          },
        })
        const token = getIn(
          data,
          "customerAccessTokenCreate.customerAccessToken",
          null
        )
        const userErrors = getIn(
          data,
          "customerAccessTokenCreate.customerUserErrors"
        )
        if (token) {
          dispatch.user.setAccessToken(
            token.accessToken,
            new Date(token.expiresAt)
          )

          if (state.checkout.checkoutId && !state.checkout.data?.email) {
            await dispatch.checkout.updateCheckoutEmail(email)
          }
          const { success, error } = await dispatch.user.refreshUserData()

          response = { success, error: success ? "" : error }

          // Fetch Users Previous quizes
          const quizResults = await axios.get(
            `${config.FIREBASE_FUNCTIONS_URL}/quizResults`,
            {
              params: { shopifyToken: token.accessToken },
            }
          )
          const previousAnswers = get(quizResults, "data.data", [])
          dispatch.quiz.setPreviousAnswers(
            previousAnswers.map((prev) => ({
              ...prev,
              date: new Date(prev.timestamp).getTime().toString(),
              riderType: "",
            }))
          )
          if (previousAnswers.length > 0) {
            dispatch.quiz.setProgressState("previous")
          }
        } else if (errors) {
          response.error = "We encountered an issue. Please try again"
        } else if (userErrors && userErrors.length > 0) {
          response.error = "Incorrect email or password"
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async loginWithSocial(
      payload: {
        type: "GOOGLE" | "FACEBOOK"
        token: string
      },
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const authResponse = await fetch(`/.netlify/functions/auth`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: payload.type,
            token: payload.token,
          }),
        })

        const authData = (await authResponse.json()) as {
          success: boolean
          token: string
          error: string
        }

        if (!authData || !authData.success || !authData.token) {
          return {
            success: false,
            error: authData?.error || "An error occured",
          }
        }

        const { data, errors } = await client.mutate({
          mutation: MultipassLogin,
          variables: {
            multipassToken: authData.token,
          },
        })

        const token = getIn(
          data,
          "customerAccessTokenCreateWithMultipass.customerAccessToken",
          null
        )
        const userErrors = getIn(
          data,
          "customerAccessTokenCreateWithMultipass.customerUserErrors"
        )
        if (token) {
          dispatch.user.setAccessToken(
            token.accessToken,
            new Date(token.expiresAt)
          )
          const {
            success,
            error,
            customer,
          } = await dispatch.user.refreshUserData()
          if (state.checkout.checkoutId && !state.checkout.data?.email) {
            await dispatch.checkout.updateCheckoutEmail(customer?.email)
          }

          response = { success, error: success ? "" : error }
        } else if (errors) {
          response.error = "We encountered an issue. Please try again"
        } else if (userErrors && userErrors.length > 0) {
          response.error = "Incorrect email or password"
        }
      } catch (e) {
        response = { success: false, error: e.message }
      }
      return response
    },
    async logout(
      payload: null | undefined,
      rootState: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: LogUserOut,
          variables: {
            accessToken: rootState.user.accessToken,
          },
        })
        const accessToken = getIn(
          data,
          "customerAccessTokenDelete.deletedAccessToken",
          null
        )
        const userErrors = getIn(
          data,
          "customerAccessTokenDelete.userErrors",
          null
        )
        if (accessToken) {
          await dispatch.user.setAccessToken(null, null)
          await dispatch.user.setAccountInfo({
            userId: null,
            email: null,
            firstName: null,
            lastName: null,
          })
          response = { success: true, error: "" }
        } else if (errors) {
          response.error = "We encounterd an issue. Please try again."
        } else if (userErrors && userErrors.length > 0) {
          response.error =
            "Token has already expired or user is already logged out"
        } else {
          response.error = "No token returned"
        }
      } catch (err) {
      } finally {
        // Reset Quiz data
        await dispatch.quiz.resetQuizState()
        // Reset User state
        await dispatch.user.resetState()
        setTimeout(() => {
          // Have to reload to logout from loyalty lion
          window.location.reload()
        }, 200)
      }

      return response
    },
    async signup(
      { email, password, firstName, lastName }: UserTypes.SignUp,
      rootState: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: CreateAccount,
          variables: {
            firstName,
            lastName,
            password,
            email,
          },
        })

        const userData = getIn(data, "customerCreate.customer", null)
        const userErrors = getIn(
          data,
          "customerCreate.customerUserErrors",
          null
        )
        if (userData) {
          dispatch.user.setAccountInfo({
            userId: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
          })

          dispatch.user.authLoyaltyLion({
            id: userData.id,
            email: userData.email,
          })

          await dispatch.user.login({ email, password })

          response = { success: true, error: "" }
        } else if (errors) {
          response.error = "We encounterd an issue. Please try again."
          dispatch.user.setError(errors)
        } else if (userErrors && userErrors.length > 0) {
          response.error = "Account already exists"
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async authLoyaltyLion(
      payload: { id: string; email: string } | null | undefined,
      rootState: BaseRootState
    ) {
      let response = { success: false, error: "default error" }
      const id = payload?.id || rootState.user.userId
      const email = payload?.email || rootState.user.email
      try {
        const authResponse = await fetch("/.netlify/functions/loyaltyLion", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            email,
          }),
        })
        const data = await authResponse.json()
        if (data.token) {
          // @ts-ignore
          const loyaltyLion = window.loyaltylion
          loyaltyLion?.authenticateCustomer({
            customer: {
              id,
              email,
            },
            auth: {
              date: data.date,
              token: data.token,
            },
          })
          return { success: true }
        } else {
          response.error = data.error || "Failed to authenticate"
        }
      } catch (err) {
        response.error = err
      }
      return response
    },
    async refreshUserData(
      payload: null | undefined,
      rootState: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error", customer: null }
      try {
        const accessToken = getIn(rootState, "user.accessToken", null)
        if (accessToken) {
          const { data, errors } = await client.query({
            query: GetCustomerInfo,
            variables: { accessToken },
          })
          const customer = getIn(data, "customer", null)
          if (customer) {
            const {
              id,
              email,
              displayName,
              defaultAddress,
              addresses,
              lastIncompleteCheckout,
            } = customer

            dispatch.user.setAccountInfo({
              email,
              name: displayName,
              userId: id,
              defaultAddress: defaultAddress
                ? {
                    id: defaultAddress.id ?? "",
                    firstName: defaultAddress.firstName ?? "",
                    lastName: defaultAddress.lastName ?? "",
                    address1: defaultAddress.address1,
                    address2: defaultAddress.address2,
                    zip: defaultAddress.zip,
                    city: defaultAddress.city ?? "",
                    country: defaultAddress.country ?? "",
                    province: defaultAddress.province ?? "",
                    company: defaultAddress.company ?? "",
                  }
                : null,
              addresses: addresses.edges.map(({ node }) => ({
                id: node.id,
                address1: node.address1,
                address2: node.address2,
                city: node.city,
                company: node.company,
                province: node.province,
                zip: node.zip,
                firstName: node?.firstName ?? "",
                lastName: node?.lastName ?? "",
              })),
            })

            const orders = getIn(customer, "orders.edges", [])
              .map(orderMapping)
              .sort((a, b) => {
                const aDate = new Date(a.timestamp)
                const bDate = new Date(b.timestamp)
                return aDate > bDate ? 1 : aDate < bDate ? -1 : 0
              })
            dispatch.user.setOrderHistory(orders)

            dispatch.user.authLoyaltyLion({
              id,
              email,
            })

            if (lastIncompleteCheckout) {
              dispatch.checkout.updateCheckout(lastIncompleteCheckout)
            }

            response = { success: true, error: "", customer }
          } else if (errors && errors.length > 0) {
            response = { ...response, error: "invalid or expired token" }
          }
        } else {
          response.error = "Access token does not exist"
        }
      } catch (err) {
        response.error = err
        console.error(err)
      }
      return response
    },
    async addAddress(
      { setDefault, id, ...address }: UserTypes.CustomerAddress,
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: CustomerAddressCreate,
          variables: {
            customerAccessToken: state.user.accessToken,
            address,
          },
        })
        const userErrors = get(
          data,
          "customerAddressCreate.customerUserErrors",
          []
        )
        if (errors) {
          dispatch.user.setError(errors)
          response.error = "Request failed to create Address"
        } else if (userErrors.length > 0) {
          response.error = userErrors[0].message
        } else {
          if (setDefault) await dispatch.user.makeDefaultAddress(id)
          await dispatch.user.refreshUserData()
          response = { success: true, error: "" }
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async updateAddress(
      { setDefault, id, ...address }: UserTypes.CustomerAddress,
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }

      if (state.user.accessToken) {
        try {
          const { data, errors } = await client.mutate({
            mutation: CustomerAddressUpdate,
            variables: {
              customerAccessToken: state.user.accessToken,
              id,
              address,
            },
          })
          const userErrors = get(
            data,
            "customerAddressUpdate.customerUserErrors",
            []
          )
          if (errors) {
            dispatch.user.setError(errors)
            response.error = "Request failed to create Address"
          } else if (userErrors.length > 0) {
            response.error = userErrors[0].message
          } else {
            if (setDefault) await dispatch.user.makeDefaultAddress(id)
            await dispatch.user.refreshUserData()
            response = { success: true, error: "" }
          }
        } catch (err) {
          dispatch.user.setError(err)
          response.error = err
        }
      }
      return response
    },
    async makeDefaultAddress(
      addressId: string,
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: CustomerDefaultAddressUpdate,
          variables: {
            customerAccessToken: state.user.accessToken,
            addressId,
          },
        })
        if (errors) {
          dispatch.user.setError(errors)
          response.error = "Request failed to create Address"
        } else {
          await dispatch.user.refreshUserData()
          response = { success: true, error: "" }
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async sendForgotPasswordEmail(
      email: string,
      state: BaseRootState
    ): Promise<BaseSuccessStatusResponse> {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "default error message" }
      try {
        const { data, errors } = await client.mutate({
          mutation: CustomerSendForgotPasswordEmail,
          variables: {
            email,
          },
        })
        if (errors) {
          dispatch.user.setError(errors)
          response.error = "Request failed to send password reset email"
        } else {
          response = { success: true, error: "" }
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async resetPassword(
      {
        customerId,
        resetToken,
        password,
      }: { customerId: string; resetToken: string; password: string },
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "" }
      try {
        const { data, errors } = await client.mutate<
          CustomerResetPasswordResponse
        >({
          mutation: CustomerResetPassword,
          variables: {
            customerId,
            resetToken,
            password,
          },
        })
        if (errors) {
          dispatch.user.setError(errors)
          response.error = "Request failed to reset password"
        } else {
          const userErrors = data?.customerReset.customerUserErrors || []
          if (userErrors.length > 0) {
            response = {
              success: false,
              error: userErrors.map((err) => err.message).join(". "),
            }
          } else {
            response = { success: true, error: "" }
            const token = data?.customerReset.customerAccessToken
            if (token) {
              dispatch.user.setAccessToken(
                token.accessToken,
                new Date(token.expiresAt)
              )
              await dispatch.user.refreshUserData()
            }
          }
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async activateAccount(
      {
        customerId,
        activationToken,
        password,
      }: { customerId: string; activationToken: string; password: string },
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()
      let response = { success: false, error: "" }
      try {
        const { data, errors } = await client.mutate<CustomerActivateResponse>({
          mutation: CustomerActivate,
          variables: {
            customerId,
            activationToken,
            password,
          },
        })
        if (errors) {
          dispatch.user.setError(errors)
          response.error = "Request failed to reset password"
        } else {
          const userErrors = data?.customerActivate.customerUserErrors || []
          if (userErrors.length > 0) {
            response = {
              success: false,
              error: userErrors.map((err) => err.message).join(". "),
            }
          } else {
            response = { success: true, error: "" }
            const token = data?.customerActivate.customerAccessToken
            if (token) {
              dispatch.user.setAccessToken(
                token.accessToken,
                new Date(token.expiresAt)
              )
              await dispatch.user.refreshUserData()
            }
          }
        }
      } catch (err) {
        dispatch.user.setError(err)
        response.error = err
      }
      return response
    },
    async getShippingCountries() {
      const client: DefaultClient<any> = createShopifyClient()
      const { data } = await client.query({ query: GetShipsToCountries })
      const countries = get(data, "shop.shipsToCountries", [])
      dispatch.user.setShipsToCountries(countries)
    },
  }),
}
