import React from "react"
import { Link, LinkProps } from "@chakra-ui/core"

type ExternalLinkProps = LinkProps & {
  to?: string
}

export default function ExternalLink({ ...props }: ExternalLinkProps) {
  return (
    <Link
      {...props}
      href={props.to || props.href}
      target="_blank"
      rel="noreferrer noopener"
    />
  )
}
