import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { isColorWhite } from "../../utils"

type ColorDotProps = BoxProps & {
  colorCode: string
}

export default function ColorDot({ colorCode, ...props }: ColorDotProps) {
  return (
    <Box
      bg={colorCode}
      h="1rem"
      w="1rem"
      borderRadius="1rem"
      borderColor="dawn"
      borderWidth={isColorWhite(colorCode) ? "1px" : undefined}
      {...props}
    />
  )
}
