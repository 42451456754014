import { isSSR } from "."
import config from "../config"

export const getDynamicRedirect = (location: Location) => {
  if (isSSR)
    return {
      isRedirect: false,
      href: "",
    }

  // Redirect for shopify orders/invoices/checkouts page
  if (location?.pathname?.match(/^\/\d+\/orders|invoices|checkouts/)) {
    return {
      isRedirect: true,
      href: `https://${config.GATSBY_SHOPIFY_SHOP_DOMAIN_ALT}${location?.pathname}${location.search}`,
    }
  }

  return {
    isRedirect: false,
    href: "",
  }
}
