import React from "react"
import {
  Modal,
  IModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Image,
  Tooltip,
} from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import Heading from "../typography/Heading"
import { Button } from "../Buttons"
import Body from "../typography/Body"
import ColorSwatch from "../ColorSwatch"
import ModalCloseButton from "../Buttons/ModalCloseButton"

export type UnavailableOption = {
  color?: string
  src?: string
  text?: string
  fluid?: FluidObject
  tooltip: string
}

type UnavailableOptionsModalProps = Omit<IModal, "children"> & {
  unavailableOptions?: UnavailableOption[]
  newOptionName: string
  onContinue: VoidFunction
}

export default function UnavailableOptionsModal({
  unavailableOptions = [],
  newOptionName,
  onContinue,
  onClose,
  ...props
}: UnavailableOptionsModalProps) {
  const count = unavailableOptions.length
  return (
    <Modal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent
        borderRadius="0.5rem"
        width="30.9375rem"
        maxWidth="90%"
        textAlign="center"
        pt="5rem"
      >
        <ModalCloseButton mt="0.75rem" />
        <ModalHeader pt={0} pb="3.4744rem">
          <Heading as="h3" size="5" fontWeight="bold">
            {count} customization{count === 1 ? "" : "s"} will be removed
          </Heading>
        </ModalHeader>
        <ModalBody pt={0} px={["1rem", null, null, null, "6rem"]} pb="83.96px">
          {unavailableOptions.length > 0 && (
            <Flex
              justifyContent="center"
              mb="1.3269rem"
              flexWrap="wrap"
              pos="relative"
            >
              {unavailableOptions.map((item, index) => (
                <Tooltip
                  key={index}
                  label={item.tooltip}
                  aria-label={item.tooltip}
                  placement="top"
                  zIndex={1000000}
                  backgroundColor="night"
                  p="0.5rem 0.75rem"
                  borderRadius="0.25rem"
                  showDelay={250}
                  hasArrow
                >
                  <Flex
                    // p="0.7813rem"
                    boxShadow="smallDarker"
                    borderRadius="0.75rem"
                    align="center"
                    w="5.125rem"
                    h="5.125rem"
                    mr="0.7125rem"
                    mb="0.7125rem"
                  >
                    {item.color && (
                      <ColorSwatch m="0.7813rem" colorCode={item.color} />
                    )}
                    {item.text && (
                      <ColorSwatch m="0.7813rem" colorCode="noon">
                        <Heading
                          as="span"
                          size="5"
                          fontWeight="bold"
                          whiteSpace="normal"
                          px="0.5rem"
                        >
                          {item.text}
                        </Heading>
                      </ColorSwatch>
                    )}
                    {item.src && (
                      <Image
                        maxHeight="100%"
                        src={item.src}
                        borderRadius="0.5rem"
                      />
                    )}
                    {item.fluid && (
                      <GatsbyImage
                        style={{ width: "100%", margin: "0.5rem" }}
                        fluid={item.fluid}
                      />
                    )}
                  </Flex>
                </Tooltip>
              ))}
            </Flex>
          )}
          <Body size="md" color="dawn">
            These options aren’t available for{" "}
            <Body as="span" color="dusk">
              {newOptionName}
            </Body>
            . Clicking continue will remove them.
          </Body>
        </ModalBody>
        <ModalFooter
          flexDirection={["column-reverse", null, null, null, "row"]}
          alignContent="center"
          justifyContent="center"
          pt={0}
          pb="4.8881rem"
        >
          <Button
            theme="tertiary"
            mr={[null, null, null, null, "1.1963rem"]}
            onClick={onClose as VoidFunction}
          >
            Cancel
          </Button>
          <Button
            theme="primary"
            mb={["1.1963rem", null, null, null, "0"]}
            onClick={onContinue}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
