import React from "react"
import { Flex, Icon, Box, PseudoBox, FlexProps } from "@chakra-ui/core"

import SmallCaps from "../typography/SmallCaps"
import { QuizProgressStateType } from "../../redux/types/QuizTypes"
import IconButton from "../Buttons/IconButton"
import animations from "../../constants/animations"

export interface QuestionStepperProps {
  currentQuestionNumber: number
  totalQuestionNumber: number
  canGoToNextQuestion: boolean
  onNext?: VoidFunction
  onPrev?: VoidFunction
  hide?: boolean
}

export const MobileQuestionStepper: React.FC<QuestionStepperProps> = ({
  currentQuestionNumber,
  totalQuestionNumber,
  canGoToNextQuestion,
  hide = false,
  onNext = () => {},
  onPrev = () => {},
}) => (
  <>
    <Flex
      h="3.25rem"
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      p="0 1.75rem"
      display={hide ? "none" : "flex"}
    >
      <IconButton
        aria-label="previous question"
        icon="arrowShortLeft"
        width="2.5rem"
        height="2.5rem"
        color="dusk"
        onClick={currentQuestionNumber !== 1 ? onPrev : undefined}
        isDisabled={currentQuestionNumber === 1}
      />
      <SmallCaps fontWeight="medium" color="dusk" letterSpacing="wide100">
        {currentQuestionNumber}/{totalQuestionNumber}
      </SmallCaps>
      <IconButton
        aria-label="next question"
        icon="arrowShortRight"
        width="2.5rem"
        height="2.5rem"
        color="dusk"
        onClick={canGoToNextQuestion ? onNext : undefined}
        isDisabled={!canGoToNextQuestion}
      />
    </Flex>
    <Flex h="1px" bg="dividerLine">
      <Box
        bg="primary"
        w="100%"
        transform={`scaleX(${currentQuestionNumber / totalQuestionNumber})`}
        transformOrigin="left"
        transition={`transform 300ms ${animations.bezier}`}
        h="100%"
      />
    </Flex>
  </>
)

const IconBox: React.FC<{ disabled?: boolean; onClick?: VoidFunction }> = ({
  children,
  disabled = false,
  onClick,
}) => (
  <PseudoBox
    p="0.5rem 0.875rem"
    borderRadius="0.5rem"
    _active={!disabled ? { bg: "noon" } : {}}
    onClick={disabled ? undefined : onClick}
    cursor={disabled ? "not-allowed" : "pointer"}
  >
    {children}
  </PseudoBox>
)

export const DesktopQuestionStepper: React.FC<
  QuestionStepperProps &
    FlexProps & { quizProgressState: QuizProgressStateType }
> = ({
  currentQuestionNumber,
  totalQuestionNumber,
  canGoToNextQuestion,
  quizProgressState,
  onNext,
  onPrev,
  ...props
}) => (
  <Flex
    {...props}
    display={[
      quizProgressState === "inprogress" || quizProgressState === "paused"
        ? "flex"
        : "none",
      null,
      null,
      null,
      quizProgressState === "inprogress" || quizProgressState === "paused"
        ? "flex"
        : "none",
    ]}
    alignItems="center"
  >
    <IconBox
      disabled={currentQuestionNumber <= 1}
      onClick={() => {
        onPrev?.()
      }}
    >
      <Icon
        name="chevron"
        size="1.25rem"
        color={currentQuestionNumber <= 1 ? "dawn" : "dusk"}
      />
    </IconBox>
    <SmallCaps fontWeight="medium" color="dusk" m="0 0.875rem">
      {currentQuestionNumber}/{totalQuestionNumber}
    </SmallCaps>
    <IconBox
      onClick={() => {
        onNext?.()
      }}
      disabled={!canGoToNextQuestion}
    >
      <Icon
        name="chevron"
        size="1.25rem"
        color={!canGoToNextQuestion ? "dawn" : "dusk"}
        transform="rotate(180deg)"
      />
    </IconBox>
  </Flex>
)
