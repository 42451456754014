import { BodyFitType } from "../redux/types/UserTypes"
import { BikeType } from "../redux/types/BikeTypes"
import { decodeShopifyId } from "."
import { mapURL } from "./url"
import createShopifyClient from "../shopify/graphql/createClient"
import { ApolloClient, NormalizedCacheObject } from "apollo-boost"
import {
  GetShopifyVariantPricing,
  GetShopifyVariantPricingResponse,
} from "../shopify/graphql/queries"
import get from "lodash/get"

/**
 * Calculate if bikeFit input matches the bodyFit setting on the bike
 */
export function isFit(
  bikeFit?: {
    maxWeight?: number | null
    minHeight?: number | null
    maxHeight?: number | null
  },
  bodyFit?: BodyFitType
) {
  if (!bikeFit || !bodyFit) return false
  const heightInInches = bodyFit.heightFt * 12 + bodyFit.heightIn
  if (bikeFit.maxWeight && bodyFit.weight > bikeFit.maxWeight) return false
  if (bikeFit.minHeight && heightInInches < bikeFit.minHeight) return false
  if (bikeFit.maxHeight && heightInInches > bikeFit.maxHeight) return false
  return true
}

/**
 * Klaviyo Quiz Results formatting
 */

type ResultsData = {
  bikes: BikeType[]
  name?: string
  phone?: string
}
const formatBikeRecommendation = async (
  bike: BikeType,
  recommendationNumber: number,
  client: ApolloClient<NormalizedCacheObject>
) => {
  if (!bike) return {}
  const key = `recommendation${recommendationNumber}`
  const variant = bike.speeds?.[0]?.variants?.[0]
  const shopifyId = variant?.shopifyId
  const price = await client.query<GetShopifyVariantPricingResponse>({
    query: GetShopifyVariantPricing,
    variables: {
      productIds: [shopifyId],
    },
  })
  return {
    [`${key}_id`]: decodeShopifyId(variant?.shopifyId),
    [`${key}_featured_image`]: variant?.productListingImage?.fluid?.src,
    [`${key}_price`]: get(price, "data.nodes[0].priceV2.amount", ""),
    [`${key}_product_url`]: mapURL("bike-page", bike.internalTitle),
    [`${key}_title`]: bike.title,
  }
}
export const prepareKlaviyoResults = async ({
  bikes,
  name,
  phone,
}: ResultsData) => {
  const client = createShopifyClient()
  // Extract needed variables
  const names = name?.split(" ") || []
  const first_name = names[0] || ""
  const last_name = names.length > 1 ? names[names.length - 1] : ""
  const phone_number = phone ? `+1${phone.replace(/\D/g, "")}` : null

  return {
    first_name,
    last_name,
    phone_number,
    ...(await formatBikeRecommendation(bikes[0], 1, client)),
    ...(await formatBikeRecommendation(bikes[1], 2, client)),
  }
}
