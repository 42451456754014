import React from "react"
import { Box, BoxProps, Button, Icon } from "@chakra-ui/core"
import config from "../../config"

type GoogleSignInButtonProps = BoxProps & {
  onSuccess?: (token: string) => void
  onFailure?: (error: string) => void
}

export default function GoogleSignInButton({
  onSuccess,
  onFailure,
  ...props
}: GoogleSignInButtonProps) {
  const signIn = () => {
    // @ts-ignore
    window.gapi?.auth2?.authorize(
      {
        client_id: config.GATSBY_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "id_token permission",
      },
      function (response) {
        if (response.error) {
          onFailure?.(response.error)
        } else {
          onSuccess?.(response.access_token)
        }
      }
    )
  }
  const onClick = () => {
    // @ts-ignore
    if (window.gapi && !window.gapi.auth2) {
      // @ts-ignore
      window.gapi.load("auth2", () => {
        signIn()
      })
    } else {
      signIn()
    }
  }

  return (
    <Box {...props}>
      <Button
        variant="ghost"
        onClick={onClick}
        width="auto"
        height="auto"
        p="0.5rem"
        borderRadius="1000px"
      >
        <Icon name="google" color="dusk" width="2.0875rem" height="2.0875rem" />
      </Button>
    </Box>
  )
}
