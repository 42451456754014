import { useApolloClient } from "@apollo/react-hooks"
import { indexBy, prop, flatten, equals } from "ramda"
import chunk from "lodash/chunk"
import debounce from "lodash/debounce"
import {
  GetShopifyVariantPricing,
  GetShopifyVariantPricingResponse,
  ShopifyVariantPricing,
} from "../shopify/graphql/queries"
import { useState, useEffect, useCallback } from "react"
import { ShopifyProductVariantFieldsType } from "../shopify/graphql/fragments"
import { usePrevious } from "@chakra-ui/core"
import { stringToBase64 } from "../utils/text"

export default function useShopifyVariantPricing(
  shopifyIds: (string | undefined | null)[] = []
) {
  const previousShopifyIds = usePrevious(shopifyIds)
  const [results, setResults] = useState<ShopifyProductVariantFieldsType[]>([])
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  const debouncedFetchPricing = useCallback(
    debounce(
      async (productIds: String[]) => {
        setLoading(true)
        const chunkedIds = chunk(productIds, 250)
        const response = await Promise.all(
          chunkedIds.map((ids) => {
            return client.query<GetShopifyVariantPricingResponse>({
              query: GetShopifyVariantPricing,
              variables: {
                productIds: ids,
              },
            })
          })
        )

        setResults(flatten(response.map((res) => res.data.nodes)).map(((node) => {
          if(node !== null) {
            node.id = stringToBase64(node.id);
            node.product.id = node.id;
          }
          return node;
        })))
        setLoading(false)
      },
      250,
      { leading: true }
    ),
    []
  )

  useEffect(() => {
    const productIds = (shopifyIds || []).filter((id) => !!id) as String[]
    if (
      productIds?.length > 0 &&
      !equals(shopifyIds || [], previousShopifyIds || [])
    ) {
      debouncedFetchPricing(productIds)
    }
  }, [shopifyIds])

  const pricingMap = results
    ? indexBy<ShopifyVariantPricing>(prop("id"), results)
    : {}

  return {
    data: results,
    loading,
    pricingMap,
  }
}

export function useLazyShopifyVariantPricing() {
  const [results, setResults] = useState<ShopifyProductVariantFieldsType[]>([])
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  const debouncedFetchPricing = useCallback(
    debounce(
      async (productIds: String[]) => {
        setLoading(true)
        const chunkedIds = chunk(productIds, 250)
        const response = await Promise.all(
          chunkedIds.map((ids) => {
            return client.query<GetShopifyVariantPricingResponse>({
              query: GetShopifyVariantPricing,
              variables: {
                productIds: ids,
              },
            })
          })
        )

        setResults(flatten(response.map((res) => res.data.nodes)).map(((node) => {
          if(node !== null) {
            node.id = stringToBase64(node.id);
            node.product.id = node.id;
          }
          return node;
        })))
        setLoading(false)
      },
      250,
      { leading: true }
    ),
    []
  )

  const pricingMap = results
    ? indexBy<ShopifyVariantPricing>(prop("id"), results)
    : {}

  const data = { data: results, loading, pricingMap }
  return [debouncedFetchPricing, { data: results, loading, pricingMap }] as [
    typeof debouncedFetchPricing,
    typeof data
  ]
}
