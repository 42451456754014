import React from "react"
import { Flex, Box, Icon, FlexProps, Button } from "@chakra-ui/core"

import SmallCaps from "../typography/SmallCaps"

const HeaderText = ({
  children,
  active,
  disabled,
  ...props
}: {
  active?: boolean
  disabled?: boolean
} & FlexProps) => (
  <Flex h="100%" px="0.25rem" align="center" justify="center" {...props}>
    <SmallCaps
      fontWeight={active ? "bold" : "normal"}
      opacity={disabled ? 0.5 : 1}
    >
      {children}
    </SmallCaps>
  </Flex>
)

const Chevron = () => (
  <Icon
    name="chevron"
    size="1.25rem"
    color="white"
    transform="rotate(180deg)"
    opacity={0.5}
  />
)

interface MobileHeaderProps {
  onExit: VoidFunction
  state: "quiz" | "bike" | "customize"
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ onExit, state }) => (
  <Flex
    className="QuizMobileHeaderTabs"
    h="3.5rem"
    w="100%"
    bg="primary"
    justifyContent="space-between"
    alignItems="center"
    color="white"
    pl="1.5rem"
  >
    <HeaderText active={state === "quiz"}>Fit</HeaderText>
    <Chevron />

    <HeaderText active={state === "bike"} disabled={state === "quiz"}>
      Bike
    </HeaderText>
    <Chevron />

    <HeaderText active={state === "customize"} disabled={state !== "customize"}>
      Customize
    </HeaderText>

    <Button
      h="100%"
      px="1.5rem"
      borderRadius={0}
      className="QuizMobileExitBtn"
      _hover={{
        bg: "primary100",
      }}
      _active={{
        bg: "primary200",
      }}
      borderLeft="1px solid rgba(255, 255, 255, 0.7)"
      onClick={onExit}
    >
      <HeaderText>Exit</HeaderText>
    </Button>
  </Flex>
)

export default MobileHeader
