import React, { useCallback } from "react"
import debounce from "lodash/debounce"
import { isSSR } from "../utils"
import useBrowserLayoutEffect from "../hooks/useBrowserLayoutEffect"

const DEFAULT_WIDTH = 15

function getScrollBarWidth() {
  if (isSSR) return DEFAULT_WIDTH

  if (document.body.scrollHeight <= window.innerHeight) {
    return 0
  }

  const inner = document.createElement("p")
  inner.style.width = "100%"
  inner.style.height = "200px"

  const outer = document.createElement("div")
  outer.style.position = "absolute"
  outer.style.top = "-9999px"
  outer.style.left = "-9999px"
  outer.style.visibility = "hidden"
  outer.style.width = "200px"
  outer.style.height = "150px"
  outer.style.overflow = "hidden"
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = "scroll"
  let w2 = inner.offsetWidth

  if (w1 == w2) {
    w2 = outer.clientWidth
  }

  document.body.removeChild(outer)

  return w1 - w2
}

export const ScrollbarWidthContext = React.createContext<number>(DEFAULT_WIDTH)

export function ScrollbarWidthProvider({ children }) {
  const [width, setWidth] = React.useState(DEFAULT_WIDTH)
  const updateWidth = useCallback(
    debounce(() => {
      setWidth(getScrollBarWidth())
    }, 200),
    []
  )

  React.useEffect(() => {
    updateWidth()
    // Recalculate on window resize
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  // this needed to ensure the scrollbar width in case hook called before the DOM is ready
  useBrowserLayoutEffect(() => {
    if (typeof width !== "undefined") {
      return
    }

    const raf = requestAnimationFrame(() => {
      updateWidth()
    })

    return () => cancelAnimationFrame(raf)
  }, [])

  return (
    <ScrollbarWidthContext.Provider value={width}>
      {children}
    </ScrollbarWidthContext.Provider>
  )
}
