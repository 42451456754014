import React from "react"
import { Input, InputProps } from "@chakra-ui/core"
import { commonStyles } from "./utils"
import { useFormContext } from "react-hook-form"

export type TextFieldProps = InputProps & {}

const TextField = React.forwardRef(function TextField(
  { isInvalid, ...props }: TextFieldProps,
  ref: InputProps["ref"]
) {
  return (
    <Input
      isInvalid={isInvalid}
      {...commonStyles(isInvalid)}
      {...props}
      ref={ref}
    />
  )
})

export default TextField

export function ConnectedTextField({ isRequired, ...props }: TextFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <TextField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
