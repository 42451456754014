import React from "react"
import { Box, FlexProps, Flex } from "@chakra-ui/core"
import { CustomizerBikeType } from "../../redux/types/BikeTypes"
import { Button } from "../Buttons"
import GatsbyImage, { FluidObject } from "gatsby-image"
import { placeholderImage } from "../../utils"
import styled from "@emotion/styled"
import Heading from "../typography/Heading"
import Body from "../typography/Body"
import { Link, navigate } from "gatsby"
import FormattedTitle from "../FormattedTitle"
import { getCustomizerColor } from "../../utils/customizer"

type CustomizerSelectedBikeProps = FlexProps & {
  bike: CustomizerBikeType
  onStartOver: VoidFunction
}

const StyledImage = styled(GatsbyImage)`
  width: 6.4375rem;
  height: 100%;
`

const StyledLink = styled(Link)`
  width: 100%;
`

export default function CustomizerSelectedBike({
  bike,
  onStartOver,
  ...props
}: CustomizerSelectedBikeProps) {
  const color = getCustomizerColor(bike)
  const image = (color?.asset?.fluid ||
    bike?.speed?.variants?.[0]?.imageLayout?.layout?.[0]?.image?.asset?.fluid ||
    bike?.speed?.variants?.[0]?.productListingImage?.fluid ||
    placeholderImage) as FluidObject

  const handleCustomizeClick = () => {
    navigate(`/customizer/${bike?.bike?.internalTitle}`, {
      state: {
        previousPath: window.location.pathname,
        fromQuizResults: {
          speed: bike.speed?.speed,
          color: bike.variant?.color?.name,
        },
      },
    })
  }

  return (
    <Flex direction="column" h="100%" {...props}>
      <Flex flex={1} flexDirection="column" align="center" justify="center">
        <Flex width="100%" mb="1rem">
          <StyledImage fluid={image} imgStyle={{ objectFit: "contain" }} />
          <Flex direction="column" justify="center" minH="5rem" ml="1rem">
            <Heading as="span" size="6" color="dusk" mb="0.375rem">
              <FormattedTitle
                color="#fff"
                raw={bike?.bike?.formattedTitle || ""}
              />
            </Heading>
            <Body color="dawn">{bike?.speed?.speed} Speed</Body>
          </Flex>
        </Flex>
        <Button
          theme="primary"
          w="100%"
          onClick={handleCustomizeClick}
          className="QuizButtonWhite"
        >
          Customize
        </Button>
      </Flex>
      <Box py="1rem">
        <StyledLink to="/quiz-results">
          <Button theme="secondary" w="100%">
            Choose a different bike
          </Button>
        </StyledLink>
        <Button theme="secondary" w="100%" onClick={onStartOver} mt="1rem">
          Start over
        </Button>
      </Box>
    </Flex>
  )
}
