import React from "react"
import {
  PseudoBox,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  IconButtonProps,
} from "@chakra-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import { BaseRootState, BaseDispatch } from "../../redux/store"
import Body from "../typography/Body"
import useLoginDrawer from "../../context/loginDrawer"
import IconButton from "../Buttons/IconButton"
import PopoverCloseButton from "../Buttons/PopoverCloseButton"
import { bpWithSidebar } from "../../utils/MediaQueries"

const AccountLink: React.FC<{ label: string; onClick: VoidFunction }> = ({
  label,
  onClick,
}) => {
  return (
    <PseudoBox onClick={onClick} cursor="pointer">
      <Body>{label}</Body>
    </PseudoBox>
  )
}

export type AccountProps = Omit<IconButtonProps, "aria-label"> & {
  isSideBarOpen: boolean
}

const Account: React.FC<AccountProps> = ({
  onClick,
  isSideBarOpen,
  ...props
}) => {
  const { accessToken, tokenExpiration, email } = useSelector(
    (state: BaseRootState) => state.user
  )
  const dispatch = useDispatch<BaseDispatch>()
  const { toggleLoginDrawer } = useLoginDrawer()

  // Login
  const onLogin = () => {
    if (document) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      if (vw > 1024) {
        setIsPopoverOpen(true)
      } else {
        navigate("/account/orders")
      }
    }
  }

  const onDrawerToggle = () => {
    toggleLoginDrawer({
      onLogin,
    })
  }

  const isUserLoggedInFunction = () => {
    if (tokenExpiration === null) return false
    let dateComp = new Date(tokenExpiration ?? "") >= new Date(Date.now())
    return (accessToken && dateComp) as boolean
  }

  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(
    isUserLoggedInFunction()
  )

  React.useEffect(() => {
    if (isUserLoggedInFunction() !== isUserLoggedIn) {
      setIsUserLoggedIn(isUserLoggedInFunction())
    }
  }, [accessToken, tokenExpiration])

  // Popover
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false)
  const togglePopoverOpen = () => setIsPopoverOpen(!isPopoverOpen)

  if (!isUserLoggedIn) {
    return (
      <>
        {/* Trigger */}
        <IconButton
          onClick={(e) => {
            onClick?.(e)
            onDrawerToggle()
          }}
          {...props}
          size="xl"
          aria-label="Log in"
          icon="user"
        />
      </>
    )
  } else {
    return (
      <>
        {/* Mobile */}
        <IconButton
          display={bpWithSidebar(undefined, "none", isSideBarOpen)}
          onClick={(e) => {
            onClick?.(e)
            navigate("/account/orders")
          }}
          {...props}
          size="lg"
          aria-label="Log in"
          icon="user"
        />
        {/* Desktop */}
        <Popover
          placement="bottom-end"
          isOpen={isPopoverOpen}
          gutter={0}
          usePortal
          onClose={() => togglePopoverOpen()}
        >
          <PopoverTrigger>
            <IconButton
              display={bpWithSidebar("none", "inline-flex", isSideBarOpen)}
              onClick={(e) => {
                onClick?.(e)
                togglePopoverOpen()
              }}
              {...props}
              size="xl"
              aria-label="Log in"
              icon="user"
            />
          </PopoverTrigger>
          <PopoverContent
            w="18.25rem"
            h="22.75rem"
            px="1.25rem"
            pt="3rem"
            pb="2.375rem"
            zIndex={10003}
            boxShadow="big"
            _focus={{ outline: "none" }}
          >
            <PopoverArrow />
            <PopoverCloseButton color="dawn" top="0.5rem" right="0.5rem" />
            <Body color="night" fontWeight="semibold">
              Welcome back!
            </Body>
            <Body color="dawn" mb="2.75rem">
              {email ?? ""}
            </Body>
            <Box>
              <AccountLink
                label="Orders"
                onClick={() => {
                  navigate("/account/orders")
                }}
              />
              <Box h="1px" width="100%" bg="dividerLine" m="1rem 0" />
              <AccountLink
                label="Addresses"
                onClick={() => {
                  navigate("/account/addresses")
                }}
              />
              {/* <Box h="1px" width="100%" bg="dividerLine" m="1rem 0" />
              <AccountLink
                label="Pedal Points"
                onClick={() => {
                  navigate("/account/pedal-points")
                }}
              /> */}
              <Box h="1px" width="100%" bg="dividerLine" m="1rem 0" />
              <AccountLink
                label="Log Out"
                onClick={() => {
                  dispatch.user.logout()
                }}
              />
            </Box>
          </PopoverContent>
        </Popover>
      </>
    )
  }
}

export default Account
