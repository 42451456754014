import React from "react"
import { Link } from "gatsby"
import { Box, BoxProps, SimpleGrid } from "@chakra-ui/core"
import { LinkProps } from ".."
import Body from "../../typography/Body"
import SectionTitle from "../SectionTitle"
import LinkButton from "../../LinkButton"

export type NavColumnProps = Omit<BoxProps, "title"> & {
  title: string
  titleLink?: string
  links: LinkProps[]
  wrap?: boolean
  onLinkClick?: VoidFunction
  onShowChange?: (showAll: boolean) => void
}

const INITIAL_COUNT = 7

export default function NavColumn({
  title,
  titleLink,
  links,
  wrap,
  onLinkClick,
  onShowChange,
  ...props
}: NavColumnProps) {
  const [showAll, setShowAll] = React.useState(false)
  const hasOverflow = links.length > INITIAL_COUNT

  const setOverflow = (all: boolean) => {
    setShowAll(all)
    onShowChange?.(all)
  }

  return (
    <Box {...props}>
      {titleLink ? (
        <Link to={titleLink} onClick={onLinkClick}>
          <SectionTitle>{title}</SectionTitle>
        </Link>
      ) : (
        <SectionTitle>{title}</SectionTitle>
      )}

      <SimpleGrid columns={wrap ? 2 : 1}>
        {links
          .slice(0, showAll ? links.length : INITIAL_COUNT)
          .map(({ name, slug }) => (
            <Link to={slug} onClick={onLinkClick} key={`${name}-${slug}`}>
              <Body
                size="md"
                lineHeight="1.375rem"
                color="dusk"
                fontWeight="normal"
                fontStyle="normal"
                textTransform="capitalize"
                marginBottom="0.75rem"
              >
                {name}
              </Body>
            </Link>
          ))}
      </SimpleGrid>

      {hasOverflow && !showAll && (
        <LinkButton
          color="dawn"
          mt="1.25rem"
          px={0}
          minWidth={0}
          alignSelf="baseline"
          onClick={() => setOverflow(true)}
        >
          <span>Show {links.length - INITIAL_COUNT} More</span>
        </LinkButton>
      )}
      {hasOverflow && showAll && (
        <LinkButton
          color="dawn"
          mt="1.25rem"
          px={0}
          minWidth={0}
          alignSelf="baseline"
          onClick={() => setOverflow(false)}
        >
          <span>Show less</span>
        </LinkButton>
      )}
    </Box>
  )
}
