import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { MotionBox } from "../../../utils/FramerMotion"
import { HEADER_HEIGHT_REM } from "../../../constants/measurements"

type NavSectionProps = BoxProps & {
  show?: boolean
}

export default React.forwardRef(function NavSection(
  { show, children, ...props }: NavSectionProps,
  ref
) {
  return (
    <Box
      pos="absolute"
      top={0}
      left={0}
      w="100%"
      p="4.8rem 0"
      maxH={`calc(90vh - ${HEADER_HEIGHT_REM}rem)`}
      overflowY="auto"
      {...props}
      ref={ref}
      pointerEvents={show ? "initial" : "none"}
      zIndex={show ? props.zIndex || 1 : 0}
    >
      <MotionBox
        w="100%"
        initial={{ opacity: 0 }}
        animate={{ opacity: show ? 1 : 0 }}
      >
        {children}
      </MotionBox>
    </Box>
  )
})
