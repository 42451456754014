import { Box, Button, Flex } from "@chakra-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import useGoogleAnalytics from "../../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import useLoginDrawer from "../../context/loginDrawer"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { isValidUserLoggedIn } from "../../utils/auth"
import { bp } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import ArrowDownIcon from "./ArrowDownIcon"

type InitialQuizScreenProps = {
  isPopup?: boolean
  quizIntroText?: React.ReactNode
}

const HeaderText: React.FC = ({ children }) => (
  <Body color="#F7F9FB" size="sm" fontWeight="bold">
    {children}
  </Body>
)

const InitialQuizScreen: React.FC<InitialQuizScreenProps> = ({
  isPopup = false,
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { accessToken, tokenExpiration } = useSelector(
    (state: BaseRootState) => ({
      tokenExpiration: state.user.tokenExpiration,
      accessToken: state.user.accessToken,
      quizProgressState: state.quiz.quizProgressState,
      previousQuizAnswers: state.quiz.previousQuizAnswers,
    })
  )

  const { openLoginDrawer } = useLoginDrawer()
  const isLoggedIn = isValidUserLoggedIn({ accessToken, tokenExpiration })
  // Started Quiz Event
  const googleAnalytics = useGoogleAnalytics({
    category: "Body Fit",
    action: "click",
    shouldFireOnFirstRender: false,
  })

  // Sidebar State
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)

  return (
    <Flex
      className=" BodyFitLayoutDesktopHeader"
      bg="primary"
      h={isPopup ? "100%" : "5rem"}
      p={isPopup ? "0" : "0 2rem"}
      alignItems="center"
      justifyContent="space-between"
      alignContent="center"
      flexDirection="column"
      background={"#005f78"}
    >
      <Box as="span">
        <HeaderText>
          <Flex
            className="QuizMobileHeaderText"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Body
              as="h3"
              textAlign="center"
              fontWeight={500}
              fontSize={bp(isPopup ? "36px" : "42px", "64px")}
              lineHeight={bp(isPopup ? "40px" : "48px", "70px")}
            >
              Your{" "}
              <Body
                as="span"
                fontWeight={600}
                fontSize={bp(isPopup ? "36px" : "42px", "64px")}
                lineHeight={bp(isPopup ? "40px" : "48px", "70px")}
              >
                {" "}
                Perfect Bike <br />
              </Body>{" "}
              Starts Here
            </Body>
            <Body
              fontSize={bp("0.9rem", "1rem")}
              textAlign="center"
              color="#eee"
              fontWeight={500}
            >
              97% of customers that use our proprietary fitting{" "}
              {!isPopup && <br />} system love their bike.
            </Body>
          </Flex>
        </HeaderText>
      </Box>

      <ArrowDownIcon
        style={{
          // transform: "rotate(270deg)",
          width: "40px",
          height: "50px",
          filter: "invert(100%)",
          margin: "40px 0",
        }}
      />

      <Button
        bg="white"
        borderRadius="30px"
        color="#005f78"
        p="10px 20px"
        width={"10rem"}
        boxShadow="0px 0.625rem 1.875rem rgba(0, 196, 209, 0.2)"
        fontFamily="din"
        fontWeight="bold"
        fontSize="0.875rem"
        lineHeight="1.0625rem"
        letterSpacing="1px"
        textTransform="uppercase"
        onClick={() => {
          dispatch.quiz.setProgressState("inprogress")
          dispatch.quiz.setQuizStartTime(Date.now().toString())
          googleAnalytics.fireEvent()
          sidebarOpen ? dispatch.sidebar.toggleOpen() : ""
        }}
      >
        Start Fitting
      </Button>

      <Body
        fontSize="1rem"
        textAlign="center"
        color="#ccc"
        fontWeight={500}
        mt="20px"
      >
        Get Fitted In Under 2 Minutes
      </Body>

      {!isLoggedIn && (
        <>
          <Flex w="80%" p="40px 0" maxW="430px">
            <Flex borderTop="0.5px solid #ccc" w="100%" height="10px" />
          </Flex>

          <Button
            bg="#005f78"
            borderRadius="30px"
            color="#fff"
            border="1px solid #fff"
            p="10px 20px"
            width={"10rem"}
            // boxShadow="0px 0.625rem 1.875rem rgba(0, 196, 209, 0.2)"
            fontFamily="din"
            fontWeight="bold"
            fontSize="0.875rem"
            lineHeight="1.0625rem"
            letterSpacing="1px"
            textTransform="uppercase"
            _hover={{ bg: "#fff", color: "#005f78" }}
            mb={"15px"}
            onClick={() => {
              openLoginDrawer()
            }}
          >
            Log In
          </Button>
          <Body
            fontSize="1rem"
            textAlign="center"
            color="#eee"
            fontWeight={500}
          >
            Log in to view your previous quiz results and <br /> save new
            results.
          </Body>
        </>
      )}
    </Flex>
  )
}

export default InitialQuizScreen
