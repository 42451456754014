export const fbq = (...props: any[]) => {
  try {
    //@ts-ignore
    if (typeof window !== "undefined" && window.fbq) {
      //@ts-ignore
      window.fbq(...props)
    }
  } catch (e) {
    console.error(e)
  }
}

export const fbqPageView = () => {
  fbq("track", "PageView")
}

export const fbqProductView = (product: {
  product_name: string
  content_category: string
  content_ids: number[]
  value: number
}) => {
  fbq("track", "ViewContent", {
    ...product,
    content_type: "product",
    currency: "USD",
  })
}

export const fbqAddToCart = (product: {
  product_name: string
  content_category: string
  content_ids: number[]
  value: number
}) => {
  fbq("track", "AddToCart", {
    ...product,
    content_type: "product",
    currency: "USD",
  })
}

export const fbqSignUp = () => {
  fbq("track", "CompleteRegistration")
}

export const fbqStartCheckout = () => {
  fbq("track", "InitiateCheckout")
}

export const fbqSearch = (query: string) => {
  fbq("track", "Search", {
    search_string: query,
  })
}
