import React from "react"
import { Location } from "@gatsbyjs/reach-router"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"

type LocationHandlerProps = {
  children: any
  location: Location
}

export default function LocationHandler({
  children,
  location,
}: LocationHandlerProps) {
  const dispatch = useDispatch<BaseDispatch>()

  React.useEffect(() => {
    const handleFitHash = async () => {
      await dispatch.sidebar.setIsOpen(true)
      await dispatch.sidebar.setSideBarStep("fit")
    }

    if (location.hash === "#fit") {
      handleFitHash()
    }
  }, [location.hash])

  return children
}
