import { ModelConfig } from "@rematch/core"
import { Branding } from "../types/BrandingTypes"

export interface BrandingStateType {
  branding: Branding
}

const initialState: BrandingStateType = {
  branding: Branding.sixthreezero,
}

export const branding: ModelConfig<BrandingStateType> = {
  state: initialState,
  reducers: {
    setBranding: (state, branding: Branding) => {
      return { ...state, branding }
    },
    setSixthreezero: (state) => {
      return { ...state, branding: Branding.sixthreezero }
    },
    setElectrified: (state) => {
      return { ...state, branding: Branding.electrified }
    },
  },
}
