export const breakpointsList = [320, 412, 768, 1024, 1440, 1680, 2560]

export const breakpoints: any = breakpointsList.map((bp) => `${bp}px`)
breakpoints.mobile = breakpointsList[0]
breakpoints.mobileLarge = breakpointsList[1]
breakpoints.tablet = breakpointsList[2]
breakpoints.laptop = breakpointsList[3]
breakpoints.desktop = breakpointsList[4]
breakpoints.maxWidth = breakpointsList[5]
breakpoints.widescreen = breakpointsList[6]

breakpoints.sm = breakpointsList[0]
breakpoints.md = breakpointsList[2]
breakpoints.lg = breakpointsList[4]
breakpoints.xl = breakpointsList[5]

export default {
  mobile: breakpointsList[0],
  mobileLarge: breakpointsList[1],
  tablet: breakpointsList[2],
  laptop: breakpointsList[3],
  desktop: breakpointsList[4],
  maxWidth: breakpointsList[5],
  widescreen: breakpointsList[6],
}
