import gql from "graphql-tag"
import * as Storefront from "shopify-storefront-api-typings"
import { Connection } from "../../typings"

export type ShopifyPricingPercentageFieldsType = Storefront.PricingPercentageValue
export const ShopifyPricingPercentageFields = gql`
  fragment ShopifyPricingPercentageFields on PricingPercentageValue {
    percentage
  }
`

export type ShopifyMoneyV2FieldsType = Storefront.MoneyV2
export const ShopifyMoneyV2Fields = gql`
  fragment ShopifyMoneyV2Fields on MoneyV2 {
    amount
    currencyCode
  }
`

export type ShopifyShippingRateFieldsType = Pick<
  Storefront.ShippingRate,
  "title" | "handle"
> & {
  priceV2: ShopifyMoneyV2FieldsType
}
export const ShopifyShippingRateFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment ShopifyShippingRateFields on ShippingRate {
    title
    handle
    priceV2 {
      ...ShopifyMoneyV2Fields
    }
  }
`

export type ShopifyMailingAddressFieldsType = Storefront.MailingAddress
export const ShopifyMailingAddressFields = gql`
  fragment ShopifyMailingAddressFields on MailingAddress {
    id
    firstName
    lastName
    company
    address1
    address2
    city
    country
    province
    provinceCode
    zip
    phone
    formatted
  }
`

export type CheckoutUserErrorsFieldsType = Storefront.CheckoutUserError
export const CheckoutUserErrorsFields = gql`
  fragment CheckoutUserErrorsFields on CheckoutUserError {
    code
    field
    message
  }
`

export type CheckoutDiscountFieldsType = Storefront.DiscountApplication
export const CheckoutDiscountFields = gql`
  ${ShopifyPricingPercentageFields}
  ${ShopifyMoneyV2Fields}
  fragment CheckoutDiscountFields on DiscountApplication {
    allocationMethod
    targetSelection
    targetType
    value {
      ...ShopifyPricingPercentageFields
      ...ShopifyMoneyV2Fields
    }
  }
`

export type CheckoutLineItemFieldsType = Pick<
  Storefront.CheckoutLineItem,
  "id" | "quantity" | "title" | "customAttributes"
> & {
  variant: Pick<
    Storefront.ProductVariant,
    "id" | "sku" | "title" | "image" | "selectedOptions"
  > & {
    product: Pick<Storefront.Product, "id" | "title">
  }
}
export const CheckoutLineItemFields = gql`
  fragment CheckoutLineItemFields on CheckoutLineItem {
    id
    title
    quantity
    variant {
      id
      sku
      title
      image {
        transformedSrc
      }
      selectedOptions {
        name
        value
      }
      product {
        id
        title
      }
    }
    customAttributes {
      key
      value
    }
  }
`

export type CheckoutFieldsType = Pick<
  Storefront.Checkout,
  "id" | "completedAt" | "note" | "webUrl" | "email"
> & {
  shippingAddress: ShopifyMailingAddressFieldsType
  discountApplications: Connection<CheckoutDiscountFieldsType>
  lineItems: Connection<CheckoutLineItemFieldsType>
  lineItemsSubtotalPrice: ShopifyMoneyV2FieldsType
  subtotalPriceV2: ShopifyMoneyV2FieldsType
  shippingLine: ShopifyShippingRateFieldsType
  totalTaxV2: ShopifyMoneyV2FieldsType
  totalPriceV2: ShopifyMoneyV2FieldsType
}
export const CheckoutFields = gql`
  ${ShopifyMoneyV2Fields}
  ${ShopifyShippingRateFields}
  ${ShopifyMailingAddressFields}
  ${CheckoutDiscountFields}
  ${CheckoutLineItemFields}
  fragment CheckoutFields on Checkout {
    id
    note
    webUrl
    completedAt
    email
    shippingAddress {
      ...ShopifyMailingAddressFields
    }
    discountApplications(first: 100) {
      edges {
        node {
          ...CheckoutDiscountFields
        }
      }
    }
    lineItems(first: 100) {
      edges {
        node {
          ...CheckoutLineItemFields
        }
      }
    }
    lineItemsSubtotalPrice {
      ...ShopifyMoneyV2Fields
    }
    subtotalPriceV2 {
      ...ShopifyMoneyV2Fields
    }
    shippingLine {
      ...ShopifyShippingRateFields
    }
    totalTaxV2 {
      ...ShopifyMoneyV2Fields
    }
    totalPriceV2 {
      ...ShopifyMoneyV2Fields
    }
  }
`

export type ShopifyProductVariantFieldsType = Pick<
  Storefront.ProductVariant,
  "id" | "availableForSale" | "quantityAvailable" | "currentlyNotInStock"
> & {
  product: Pick<Storefront.Product, "id" | "handle">
  compareAtPriceV2: ShopifyMoneyV2FieldsType
  priceV2: ShopifyMoneyV2FieldsType
  image: Pick<Storefront.Image, "transformedSrc">
}
export const ShopifyProductVariantFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment ShopifyProductVariantFields on ProductVariant {
    id
    product {
      id
      handle
    }
    availableForSale
    quantityAvailable
    currentlyNotInStock
    compareAtPriceV2 {
      ...ShopifyMoneyV2Fields
    }
    priceV2 {
      ...ShopifyMoneyV2Fields
    }
    image {
      transformedSrc
    }
  }
`

export type ShopifyProductFieldsType = Pick<
  Storefront.Product,
  "id" | "handle"
> & {
  variants: Connection<ShopifyProductVariantFieldsType>
}
export const ShopifyProductFields = gql`
  ${ShopifyProductVariantFields}
  fragment ShopifyProductFields on Product {
    id
    handle
    variants(first: 100) {
      edges {
        node {
          ...ShopifyProductVariantFields
        }
      }
    }
  }
`

export type OrderLineItemFieldsType = Pick<
  Storefront.OrderLineItem,
  | "title"
  | "quantity"
  | "discountedTotalPrice"
  | "customAttributes"
  | "originalTotalPrice"
> & {
  variant: Pick<Storefront.ProductVariant, "id" | "title" | "sku" | "image"> & {
    product: Pick<Storefront.Product, "id" | "title">
  }
}
export const OrderLineItemFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment OrderLineItemFields on OrderLineItem {
    title
    quantity
    variant {
      id
      sku
      title
      image {
        transformedSrc
      }
      product {
        id
        title
      }
    }
    originalTotalPrice {
      ...ShopifyMoneyV2Fields
    }
    discountedTotalPrice {
      ...ShopifyMoneyV2Fields
    }
    customAttributes {
      key
      value
    }
  }
`

export type CustomerUserErrorsFieldsType = Storefront.CustomerUserError
export const CustomerUserErrorsFields = gql`
  fragment CustomerUserErrorsFields on CustomerUserError {
    code
    field
    message
  }
`

export type CustomerFieldsType = Pick<
  Storefront.Customer,
  "id" | "displayName" | "firstName" | "lastName" | "phone" | "email"
> & {
  defaultAddress: ShopifyMailingAddressFieldsType
  lastIncompleteCheckout: CheckoutFieldsType
  addresses: Connection<ShopifyMailingAddressFieldsType>
  orders: Connection<
    Pick<
      Storefront.Order,
      | "id"
      | "orderNumber"
      | "processedAt"
      | "fulfillmentStatus"
      | "successfulFulfillments"
      | "cancelReason"
      | "canceledAt"
      | "totalRefundedV2"
    > & {
      discountApplications: Connection<CheckoutDiscountFieldsType>
      lineItems: Connection<OrderLineItemFieldsType>
      subtotalPriceV2: ShopifyMoneyV2FieldsType
      totalTaxV2: ShopifyMoneyV2FieldsType
      totalShippingPriceV2: ShopifyMoneyV2FieldsType
      totalPriceV2: ShopifyMoneyV2FieldsType
    }
  >
}
export const CustomerFields = gql`
  ${ShopifyMoneyV2Fields}
  ${ShopifyMailingAddressFields}
  ${CheckoutDiscountFields}
  ${CheckoutFields}
  ${OrderLineItemFields}
  fragment CustomerFields on Customer {
    id
    defaultAddress {
      ...ShopifyMailingAddressFields
    }
    displayName
    firstName
    lastName
    phone
    email
    lastIncompleteCheckout {
      ...CheckoutFields
    }
    addresses(first: 100) {
      edges {
        node {
          ...ShopifyMailingAddressFields
        }
      }
    }
    orders(first: 100, sortKey: PROCESSED_AT, reverse: true) {
      edges {
        node {
          id
          discountApplications(first: 100) {
            edges {
              node {
                ...CheckoutDiscountFields
              }
            }
          }
          lineItems(first: 100) {
            edges {
              node {
                ...OrderLineItemFields
              }
            }
          }
          orderNumber
          processedAt
          fulfillmentStatus
          successfulFulfillments {
            trackingCompany
            trackingInfo {
              number
              url
            }
          }
          canceledAt
          cancelReason
          subtotalPriceV2 {
            ...ShopifyMoneyV2Fields
          }
          totalTaxV2 {
            ...ShopifyMoneyV2Fields
          }
          totalShippingPriceV2 {
            ...ShopifyMoneyV2Fields
          }
          totalPriceV2 {
            ...ShopifyMoneyV2Fields
          }
          totalRefundedV2 {
            ...ShopifyMoneyV2Fields
          }
        }
      }
    }
  }
`

export type CustomerAccessTokenType = Storefront.CustomerAccessToken
export const CustomerAccessToken = gql`
  fragment CustomerAccessToken on CustomerAccessToken {
    accessToken
    expiresAt
  }
`

export type UserErrorsFieldsType = Storefront.UserError
export const UserErrorsFields = gql`
  fragment UserErrorsFields on UserError {
    field
    message
  }
`
