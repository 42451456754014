import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import { isColorWhite } from "../../utils"

type ColorSwatchProps = FlexProps & {
  colorCode: string
}

export default function ColorSwatch({ colorCode, ...props }: ColorSwatchProps) {
  return (
    <Flex
      align="center"
      justify="center"
      w="3.555rem"
      h="3.555rem"
      color="dusk"
      backgroundColor={colorCode}
      borderWidth={isColorWhite(colorCode) ? "1px" : undefined}
      borderColor="noon"
      borderRadius="0.5rem"
      {...props}
    />
  )
}
