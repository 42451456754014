import { Models } from "@rematch/core"
import { checkout } from "./checkout"
import { user } from "./user"
import { quiz } from "./quiz"
import { bikes } from "./bikes"
import { sidebar } from "./sidebar"
import { branding } from "./branding"

export interface BaseSuccessStatusResponse {
  success: boolean
  error: any
}
export interface RootModel extends Models {
  checkout: typeof checkout
  user: typeof user
  quiz: typeof quiz
  bikes: typeof bikes
  sidebar: typeof sidebar
  branding: typeof branding
}

export const models: RootModel = {
  checkout,
  user,
  quiz,
  bikes,
  sidebar,
  branding,
}
