import { FluidObject } from "gatsby-image"
import * as R from "ramda"
import slugify from "slugify"
import { RefObject } from "react"
import { ContentfulAsset, ContentfulFluid } from "../../graphql-types"
import {
  ContentfulVariant,
  ContentfulVariants,
  ShopifyVariant,
  ShopifyVariants,
} from "../typings"
import { gatsbyFixedImage, gatsbyFluidImage } from "./Storybook"

export const isNilOrEmpty = (a: any): any => R.isNil(a) || R.isEmpty(a)

// Safely get nested prop
export const getIn = (o: object, path: string[] | string, defaultValue?: any) =>
  (o &&
    path &&
    R.view(R.lensPath(typeof path === "string" ? path.split(".") : path))(o)) ||
  defaultValue

export const mergeVariantsWithPricing = ({
  staticVariants,
  shopifyVariants,
  shopifyProductId,
  slug,
  contentful_id,
}: {
  staticVariants: ContentfulVariants | ShopifyVariants
  shopifyVariants: any
  shopifyProductId: string
  slug: string
  contentful_id: string | undefined
}) => {
  let mapping = {}
  R.forEachObjIndexed(
    (staticVariant: ContentfulVariant | ShopifyVariant, key: any) => {
      const shopifyVariant: any = getIn(
        R.find(({ node: { id } }) => id === key)(shopifyVariants) as any,
        "node"
      )

      const liveShopifyData = shopifyVariant
        ? {
            price: getIn(shopifyVariant, "priceV2.amount"),
            currencyCode: getIn(shopifyVariant, "priceV2.currencyCode"),
            compareAtPrice: getIn(shopifyVariant, "compareAtPriceV2.amount"),
            availableForSale: getIn(shopifyVariant, "availableForSale"),
            imageSrc: getIn(shopifyVariant, "image.transformedSrc"),
          }
        : {
            isInvalid: true,
          }

      const stuff = {
        ...staticVariant,
        variantId: key,
        productId: shopifyProductId,
        productSlug: slug,
        ...liveShopifyData,
      }
      mapping[key] = contentful_id
        ? {
            ...stuff,
            contentfulVariantId: (staticVariant as ContentfulVariant)
              .contentful_id,
            contentfulProductId: contentful_id,
          }
        : stuff
    }
  )(staticVariants)
  return mapping
}

export const getSpacers = (
  numOfSpacers: number,
  spacer: (key: number | string) => JSX.Element
) => {
  const spacers: Array<JSX.Element> = []
  for (let i = numOfSpacers; i > 0; --i) {
    spacers.push(spacer(`spacer ${i}`))
  }
  return spacers
}

export const parseQueryParams = (url: string): { [key: string]: string } => {
  // Note: this will not handle 'array' values
  const URL = decodeURIComponent(url)
  if (URL.indexOf("?") > -1) {
    const queryString = URL.split("?")[1]
    if (queryString.indexOf("&") > -1) {
      return queryString.split("&").reduce(
        (acc, cur) => ({
          ...acc,
          [cur.split("=")[0]]: cur.split("=")[0],
        }),
        {}
      )
    } else if (queryString.indexOf("=") > -1) {
      const [key, value] = queryString.split("=")
      return { [key]: value }
    }
  }
  return {}
}

export const hasWindow = typeof window !== "undefined"

export const rows = (r: number) => `((100vh/9) * ${r})`

export type ContentfulImageMap = Record<string, FluidObject>
export const createContentfulImageMap = (images: {
  edges: {
    node: Pick<ContentfulAsset, "contentful_id"> & {
      fluid?: FluidObject | ContentfulFluid | null
      raw?: FluidObject | ContentfulFluid | null
    }
  }[]
}) => {
  return images.edges.reduce((acc, next) => {
    acc[next.node.contentful_id!] = next.node.fluid
    if (acc[next.node.contentful_id!] && next.node.raw) {
      acc[next.node.contentful_id!].raw = next.node.raw
    }
    return acc
  }, {}) as ContentfulImageMap
}

export type ContentfulEntryMap<T = { contentful_id: string }> = Record<
  string,
  T
>
export const createContentfulEntryMap = <
  T = { contentful_id: string }
>(entries: {
  edges: {
    node: {
      contentful_id: string
    }
  }[]
}) => {
  return entries.edges.reduce((acc, next) => {
    acc[next.node.contentful_id] = next.node
    return acc
  }, {}) as Record<string, T>
}

export function isColorWhite(color?: string) {
  switch ((color || "").toLowerCase().replace(/ /g, "")) {
    case "#fff":
    case "#ffffff":
    case "rgb(255,255,255)":
    case "white":
      return true
    default:
      return false
  }
}

export function rem(px: number) {
  return px / 16
}

export const isSSR: boolean = !(
  typeof window !== "undefined" && window.document?.createElement
)

export const getElement = <T = undefined>(
  element?: RefObject<Element> | Element | null | T
): Element | null | undefined | T =>
  element && "current" in element ? element.current : element

export const placeholderImage = gatsbyFluidImage(
  require("../assets/images/placeholder.png"),
  2049 / 1438
)
export const placeholderImageFixed = gatsbyFixedImage(
  require("../assets/images/placeholder.png"),
  512,
  512
)

/**
 * Gets the ID number from a shopifyId
 * @param id Base64 encoded shopifyId
 */
export const decodeShopifyId = (id?: string | null) => {
  if (!id) return ""
  const decoded = isSSR ? Buffer.from(id, "base64").toString() : atob(id || "")
  return decoded.match(/\d+$/)?.[0]
}

export const toSlug = (value = "") =>
  slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g })
