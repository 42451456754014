import React from "react"
import loadable from "@loadable/component"
import PointsModalButton from "../PedalPoints/PointsModalButton"
import useLoadOnInteraction from "../../hooks/useLoadOnInteraction"
import ClientOnly from "../ClientOnly"

const Scripts = loadable(() => import("./"))

export default function DeferredScripts({ location }) {
  const loadScripts = useLoadOnInteraction()

  return loadScripts ? (
    <>
      <ClientOnly>
        <Scripts location={location} />
      </ClientOnly>
      {/* <PointsModalButton /> */}
    </>
  ) : (
    <></>
  )
}
