export const convertHeightAnswerToInches = (heightAnswer) => {
  return (typeof heightAnswer === "string" ? [] : heightAnswer)?.reduce(
    (acc, cur, index) => {
      let x = parseInt(cur, 10)
      if (index === 0) {
        x *= 12
      }
      return acc + x
    },
    0
  )
}
