import React from "react"
import {
  PopoverCloseButton as ChakraCloseButton,
  PopoverCloseButtonProps as CharakCloseButtonProps,
} from "@chakra-ui/core"

type PopoverCloseButtonProps = CharakCloseButtonProps & {}

export default function PopoverCloseButton({
  ...props
}: PopoverCloseButtonProps) {
  return (
    <ChakraCloseButton
      color="dawn"
      borderRadius="0.5rem"
      width="2.75rem"
      height="2.75rem"
      fontSize="0.5rem"
      _active={{
        outline: "none",
      }}
      {...props}
    />
  )
}
