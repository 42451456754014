import { Flex, FlexProps } from "@chakra-ui/core"
import React from "react"
import { bp } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import QuizButton from "./QuizButton"

type QuizResultsProps = FlexProps & {
  isLoggedIn: boolean
  isSubmittingResults: boolean
  onSeeResults: VoidFunction
  onLogin: VoidFunction
  isPopup?: boolean
}

export default function QuizResults({
  isLoggedIn,
  isSubmittingResults,
  onSeeResults,
  onLogin,
  isPopup = false,
  ...props
}: QuizResultsProps) {
  return (
    <Flex
      h={bp("auto", "auto")}
      direction="column"
      alignItems="center"
      justify="center"
      mt={bp(isPopup ? "1rem" : "5.5rem", "5.5rem")}
      mx={bp("1.25rem", "0")}
      {...props}
    >
      {isLoggedIn ? (
        <>
          <Body
            fontWeight="bold"
            fontSize={bp("1.5rem", "2rem")}
            mb="1.5131rem"
            color="#fff"
          >
            You&apos;re all set!
          </Body>
          <QuizButton
            w="100%"
            maxW={bp("200px", "300px")}
            theme="secondary"
            borderRadius="100px"
            color="#005f78"
            onClick={onSeeResults}
            isLoading={isSubmittingResults}
            h={"3rem"}
            mb={bp(isPopup ? "20px" : "0", "0")}
          >
            See Results
          </QuizButton>
        </>
      ) : (
        <>
          <Body
            fontWeight="bold"
            fontSize={bp("1.2rem", "1.5rem")}
            mb="0"
            color="#fff"
            textAlign="center"
          >
            One last thing -
          </Body>
          <Body
            as="h4"
            fontWeight="bold"
            fontSize={bp("1.6rem", "2.4rem")}
            mb="1.5131rem"
            color="#fff"
            textAlign="center"
          >
            Want to save your results?
          </Body>
          <Body
            textAlign="center"
            fontSize={bp("1rem", "1.3rem")}
            color="#eee"
            mb="2.25rem"
          >
            Log in or create an account to save your results.
          </Body>

          <Flex
            className="BodyFitLayoutSeeResultFlex"
            flexDirection={bp("column", "column")}
            justify="space-between"
            alignItems="center"
            flexWrap="nowrap"
            style={{
              gap: "10px",
            }}
            w={bp(isPopup ? "100%" : "70%", "70%")}
          >
            <QuizButton
              className="QuizButtonWhite"
              w={bp("100%", "300px")}
              h="3rem"
              borderRadius="100px"
              theme="primary"
              onClick={onSeeResults}
              isLoading={isSubmittingResults}
              mb="1rem"
            >
              No, See Results
            </QuizButton>
            <QuizButton
              w={bp("100%", "300px")}
              theme="secondary"
              onClick={onLogin}
              className="QuizOutlineBtn"
              h="3rem"
              borderRadius="100px"
            >
              Log in
            </QuizButton>
          </Flex>
        </>
      )}
    </Flex>
  )
}
