import React from "react"
import { SimpleGrid, SimpleGridProps, BoxProps } from "@chakra-ui/core"
import Maybe from "graphql/tsutils/Maybe"
import GridSelectOption, { ItemSize, Option } from "../GridSelectOption"

type GridSelectPickerProps = Omit<SimpleGridProps, "onChange"> & {
  options: Option[]
  value: Maybe<string>
  onChange: (value: Option) => void
  itemSize?: ItemSize
  swatchProps?: BoxProps
  columns?: number
}

export default function GridSelectPicker({
  options,
  value,
  onChange,
  itemSize = "small",
  swatchProps = {},
  columns = 3,
  ...props
}: GridSelectPickerProps) {
  return (
    <SimpleGrid columns={columns} spacing="1.2131rem" {...props}>
      {options.map((option) => (
        <GridSelectOption
          key={option.value}
          option={option}
          active={option.value === value}
          onClick={() => onChange(option)}
          itemSize={itemSize}
          swatchProps={swatchProps}
        />
      ))}
    </SimpleGrid>
  )
}
