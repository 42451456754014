import React from "react"

import {
  BikeListingType,
  getVariantsFromSpeed,
} from "../../utils/withTransformData"
import { mapURL } from "../../utils/url"
import { useSelector } from "react-redux"
import { matchSorter } from "match-sorter"
import { BaseRootState } from "../../redux/store"
import { stripHTML } from "../../utils/text"

export interface SearchWrapperProps {
  searchText: string
  shouldFetchSuggestion?: boolean
  suggestionLimit?: number
  shouldFetchResults?: boolean
  resultLimit?: number
  children: (data: {
    isFetching: boolean
    suggestions: Array<{ title: string; slug: string }>
    results: Array<BikeListingType>
  }) => JSX.Element
}

const SearchWrapper: React.FC<SearchWrapperProps> = ({
  searchText,
  shouldFetchResults = true,
  shouldFetchSuggestion = true,
  suggestionLimit = 8,
  resultLimit = 48,
  children,
}) => {
  const { bikes: allBikes, collections: allCollections } = useSelector(
    (state: BaseRootState) => state.bikes
  )
  const [isFetchingResults, setIsFetchingResults] =
    React.useState<boolean>(true)
  const [bikes, setBikes] = React.useState<Array<BikeListingType>>([])
  const [collections, setCollections] = React.useState<
    Array<{ title: string; slug: string }>
  >([])
  const [isFetchingCollections, setIsFetchingCollections] =
    React.useState<boolean>(shouldFetchSuggestion)

  const searchBikes = (searchText: string) => {
    return matchSorter(allBikes, searchText, {
      keys: [
        "title",
        "formattedTitle",
        "internalTitle",
        "style.*",
        "type.*",
        "terrain.*",
        "gender",
        "speeds.*.variants.*.color.name",
      ],
    })
  }
  const searchCollections = (searchText: string) => {
    return matchSorter(allCollections, searchText, { keys: ["node.title"] })
  }

  React.useEffect(() => {
    if (!isFetchingResults && shouldFetchResults) setIsFetchingResults(true)
    if (!isFetchingCollections && shouldFetchSuggestion)
      setIsFetchingCollections(true)
    if (searchText) {
      if (shouldFetchResults) {
        const results = searchBikes(searchText).slice(0, resultLimit)
        const transformedResults =
          results?.reduce((acc, bike) => {
            return [
              ...acc,
              {
                title: bike.formattedTitle || "",
                internalTitle: bike.internalTitle || "",
                slug: mapURL("bike-page", bike.internalTitle),
                gender: bike.gender || "",
                styles: (bike.style || []) as string[],
                terrains: (bike.terrain || []) as string[],
                speeds: (bike.speeds || []) as any[],
                types: (bike.type || []) as string[],
                variants: getVariantsFromSpeed(bike.speeds || []),
                bodyFit: {
                  minHeight: bike.bodyFitMinHeight || undefined,
                  maxHeight: bike.bodyFitMaxHeight || undefined,
                  maxWeight: bike.bodyFitMaxWeight || undefined,
                },
              } as BikeListingType,
            ]
          }, [] as BikeListingType[]) ?? []
        setBikes(transformedResults)
        setIsFetchingResults(false)
      } else if (bikes.length !== 0) setBikes([])
      if (shouldFetchSuggestion) {
        const results = searchCollections(searchText).slice(0, 5)
        setCollections(
          results?.map((collection) => ({
            title: stripHTML(collection.node.title!),
            slug: mapURL("collection", collection.node.internalTitle),
          })) ?? []
        )
        setIsFetchingCollections(false)
      } else if (collections.length !== 0) setCollections([])
    }
  }, [searchText])

  return children({
    isFetching: isFetchingCollections || isFetchingResults,
    suggestions: collections,
    results: bikes,
  })
}
export default SearchWrapper
