import React from "react"
import { Provider as ReduxProvider } from "react-redux"
import { ApolloProvider } from "@apollo/react-hooks"
import createClient from "../graphql/createClient"
import store from "../../redux/store"

const ShopifyProvider = ({ children, ...rest }: any) => {
  const WrappedComponent = React.Children.only(children)

  return (
    <ApolloProvider client={createClient()}>
      {React.cloneElement(WrappedComponent, rest)}
    </ApolloProvider>
  )
}

export default function ShopifyProviderWrapper({ children, ...rest }: any) {
  return (
    <ReduxProvider store={store}>
      <ShopifyProvider>{children}</ShopifyProvider>
    </ReduxProvider>
  )
}
