import React from "react"
import { Box, BoxProps, Button, ButtonProps, Flex, Icon } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import {
  Maybe,
  ContentfulProductVariant,
  ContentfulBicycleModelSharedCustomizerOptionsJsonNodeAccessoryCategories as SharedCustomizerAccessoryCategories,
} from "../../../graphql-types"
import SelectListItem from "./SelectListItem"
import CustomizerDrawer from "./CustomizerDrawer"
import ColorDot from "../ColorDot"
import Body from "../typography/Body"
import SelectedIcon from "../icons/SelectedIcon"
import { isColorWhite } from "../../utils"
import { getFixedPrice } from "../Cart/utils"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { getPreorderLabel, PreorderInfoResponse } from "../../utils/preorder"
import Notice from "../Notice"

export type AccessoryCategoryListProps = BoxProps & {
  categories?: Maybe<SharedCustomizerAccessoryCategories>[]
  selectedCategories?: { [category: string]: ContentfulProductVariant }
  onAccessoryClick: (
    category: string,
    accessory: ContentfulProductVariant | null
  ) => void
  pricingMap: Record<string, ShopifyVariantPricing>
  preorderData: PreorderInfoResponse["data"]
}

const AccessoryCategoryList = ({
  categories,
  selectedCategories,
  onAccessoryClick,
  pricingMap,
  preorderData,
  ...props
}: AccessoryCategoryListProps) => {
  const [category, setCategory] = React.useState<string | null>(null)

  const categoryData = category
    ? categories?.find((data) => data?.name === category)
    : null
  const accessories = categoryData
    ? (categoryData.accessories
        ?.map((accessory) => (accessory?.enabled ? accessory.variant : null))
        .filter((x) => !!x) as ContentfulProductVariant[])
    : []

  const onClose = () => {
    setCategory(null)
  }

  return (
    <>
      <Box {...props}>
        {(!categories || categories.length === 0) && (
          <Body color="dusk" pt="1rem">
            No accessory options are available
          </Body>
        )}
        {categories
          ?.filter(
            (category) => category?.enabled && category?.accessories?.length
          )
          ?.map((category) => {
            const variant = selectedCategories?.[category?.name!]
            const pricing = pricingMap?.[variant?.shopifyId || ""]
            const isPreorder =
              pricing?.availableForSale && pricing?.currentlyNotInStock
            const productShopifyId = pricing?.product.id
            return (
              <SelectListItem
                key={category?.name || ""}
                preorderLabel={
                  isPreorder
                    ? getPreorderLabel(
                        preorderData,
                        variant?.shopifyId,
                        productShopifyId
                      )
                    : undefined
                }
                onClick={() => setCategory(category?.name || null)}
              >
                <Body>{category?.name}</Body>
                {variant ? (
                  <Flex maxW="50%" align="flex-end" direction="column">
                    <Flex width="100%" align="center">
                      <ColorDot
                        colorCode={variant.color?.hexCode!}
                        mr="0.5rem"
                        flexGrow={0}
                        flexShrink={0}
                      />
                      <Body
                        whiteSpace="nowrap"
                        overflow="hidden"
                        width="100%"
                        maxWidth="100px"
                        style={{
                          textOverflow: "ellipsis",
                        }}
                        title="variant.title"
                        flexGrow={1}
                      >
                        {variant.title}
                      </Body>
                    </Flex>
                    <Body size="xs" color="dawn">
                      {getFixedPrice(pricing?.priceV2?.amount)}
                    </Body>
                  </Flex>
                ) : (
                  <Body color="dusk">None</Body>
                )}
              </SelectListItem>
            )
          })}
      </Box>
      <CustomizerDrawer
        // @ts-ignore
        size="sidebar"
        isOpen={category !== null}
        title={category || ""}
        buttonLabel="Done"
        onButtonClick={onClose}
        onClose={onClose}
        initialFocusRef={undefined}
      >
        <BaseAccessoryOption
          active={!!(category && !selectedCategories?.[category])}
          onClick={() => onAccessoryClick(category || "", null)}
        >
          <Flex
            h="4.9494rem"
            w="4.9494rem"
            align="center"
            justify="center"
            mr="1.5rem"
            className="BodyFitDrawerInnerOption"
          >
            <Icon w="2.625rem" h="2.625rem" name="crossedCircle" color="noon" />
          </Flex>
          <Body>No {category}</Body>
        </BaseAccessoryOption>
        {accessories?.map((accessory) => (
          <AccessoryOption
            className="BodyFitDrawerInnerOption"
            key={accessory.contentful_id || ""}
            accessory={accessory}
            pricingMap={pricingMap}
            preorderData={preorderData}
            active={
              selectedCategories &&
              Object.values(selectedCategories).includes(accessory)
            }
            onClick={() => onAccessoryClick(category || "", accessory)}
          />
        ))}
      </CustomizerDrawer>
    </>
  )
}

type BaseAccessoryOptionProps = ButtonProps & {
  active?: boolean
}
const boxShadow = "0px 0.25rem 3.125rem rgba(18, 25, 35, 0.1)"
const BaseAccessoryOption = ({
  active,
  children,
  ...props
}: BaseAccessoryOptionProps) => (
  <Button
    className="BodyFitDrawerInnerOption"
    color="dusk"
    pos="relative"
    w="100%"
    h="auto"
    variant="ghost"
    variantColor="none"
    justifyContent="flex-start"
    textAlign="left"
    p="1rem"
    boxShadow={active ? boxShadow : "none"}
    _focus={{
      boxShadow,
    }}
    {...props}
  >
    {active && (
      <SelectedIcon
        pos="absolute"
        top="-0.3125rem"
        right="-0.3125rem"
        zIndex={1}
      />
    )}
    {children}
  </Button>
)

type AccessoryOptionProps = Omit<BaseAccessoryOptionProps, "children"> & {
  accessory: ContentfulProductVariant
  pricingMap: Record<string, ShopifyVariantPricing>
  preorderData: PreorderInfoResponse["data"]
}

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  object-fit: contain;
  object-position: center;
`

const AccessoryOption = ({
  accessory,
  pricingMap,
  preorderData,
  ...props
}: AccessoryOptionProps) => {
  const image = accessory.productListingImage?.fluid
  const bgColor = !image ? accessory.color?.hexCode || "noon" : undefined
  const pricing = pricingMap[accessory.shopifyId!]
  const availableForSale = pricing?.availableForSale
  const isPreorder = availableForSale && pricing?.currentlyNotInStock
  return (
    <BaseAccessoryOption
      p="0"
      flexDirection="column"
      isDisabled={!availableForSale}
      {...props}
    >
      <Flex w="100%" direction="column" p="1rem">
        <Flex>
          <Box
            h="4.9494rem"
            w="4.9494rem"
            flexShrink={0}
            mr="1.5rem"
            borderRadius="0.5rem"
            backgroundColor={bgColor}
            borderWidth={isColorWhite(bgColor || "") ? "1px" : undefined}
            borderColor="noon"
          >
            {image && <StyledImage fluid={image as FluidObject} />}
          </Box>
          <Flex direction="column">
            <Body whiteSpace="normal" mb="0.375rem">
              {accessory.title || accessory.color?.name}
            </Body>
            <Body size="xs" color="dawn">
              {getFixedPrice(pricing?.priceV2.amount)}
            </Body>
            {!availableForSale && (
              <Body size="xs" color="dawn">
                Sold out
              </Body>
            )}
          </Flex>
        </Flex>
        {isPreorder && (
          <Notice size="sm" whiteSpace="normal" mt="1rem">
            {getPreorderLabel(preorderData, accessory.shopifyId)}
          </Notice>
        )}
      </Flex>
    </BaseAccessoryOption>
  )
}

export default AccessoryCategoryList
