import { Box, Flex, Icon } from "@chakra-ui/core"
import React from "react"
import SmallCaps from "../../components/typography/SmallCaps"
import { bp } from "../../utils/MediaQueries"
import { Button } from "../Buttons"
import Heading from "../typography/Heading"

const ThoughtBubble: React.FC<{ tags: Array<string> }> = ({ tags }) => (
  <>
    <Box
      bg="white"
      borderTop="1px solid"
      borderLeft="1px solid"
      borderColor="dividerLine"
      h="9px"
      w="9px"
      mt="1.5rem"
      mb="-4.5px"
      transform="rotate(45deg)"
    />
    <Flex
      mb="1.5rem"
      borderRadius="0.5rem"
      border="1px solid"
      borderColor="#fff"
      w="100%"
      p="0 1.25rem"
      alignItems="center"
      h="3rem"
      justifyContent="space-between"
    >
      {tags.map((text) => (
        <SmallCaps key={text} fontWeight="bold" size="sm" color="#fff">
          {text}
        </SmallCaps>
      ))}
      {/* 
        This is hidden for now until we get to a place to lead to the body fit explanation
      <Icon
        name="chevron"
        size="1.25rem"
        color="dawn"
        transform="rotate(180deg)"
      /> */}
    </Flex>
  </>
)

interface PreviousResultsProps {
  riderStyle: "casual" | "dedicated" | "fanatical" | null
  tags: Array<string>
  nextText: string
  header?: JSX.Element
  onNewQuiz?: VoidFunction
  onEditAnswers?: VoidFunction
  onNext?: VoidFunction
  className?: string
  style?: any
  isPopup?: boolean
}

const PreviousResults: React.FC<PreviousResultsProps> = ({
  riderStyle,
  tags,
  nextText,
  header,
  onNewQuiz,
  onEditAnswers,
  onNext,
  className,
  style,
  isPopup = false,
}) => (
  <Flex
    flexDir="column"
    h={"auto"}
    maxW="400px"
    style={style}
    className={className}
    marginX="auto"
    marginTop={bp(isPopup ? "0" : "50px", "50px")}
  >
    {header}
    <Flex
      flexDir="column"
      // flex="1"
      justifyContent="center"
      alignItems="center"
      w="100%"
      p={["0 1.25rem", null, null, null, "0"]}
    >
      {riderStyle && (
        <>
          <Icon
            name={
              riderStyle === "casual"
                ? "casualRider"
                : riderStyle === "dedicated"
                ? "dedicatedRider"
                : "fanaticalRider"
            }
            size="2.5rem"
          />
          <Heading
            as="span"
            size="5"
            fontWeight="700"
            w="100%"
            px="1.75rem"
            textAlign="center"
            color="#fff"
          >
            {riderStyle} riding style
          </Heading>
        </>
      )}
      <ThoughtBubble tags={tags} />
      <Button w="100%" className="QuizButtonWhite" onClick={onNext}>
        {nextText}
      </Button>
    </Flex>
    <Flex
      flexDir="column"
      p={[
        isPopup ? "0 1.25rem 0.7rem 1.25rem" : "0 1.25rem 0.7rem 1.25rem",
        null,
        null,
        null,
        "0",
      ]}
      marginTop="30px"
    >
      <Button
        theme="secondary"
        mt="1rem"
        onClick={onEditAnswers}
        className="QuizOutlineBtn"
        color="#fff"
      >
        Edit Answers
      </Button>
      <Button
        theme="secondary"
        mt="1rem"
        onClick={onNewQuiz}
        className="QuizOutlineBtn"
        color="#fff"
      >
        Start New Quiz
      </Button>
    </Flex>
  </Flex>
)

export default PreviousResults
