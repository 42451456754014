import React from "react"
import { Box, BoxProps, Link, List, ListItem, Text, Code } from "@chakra-ui/core"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import Image, { FluidObject } from "gatsby-image"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
} from "gatsby-source-contentful/rich-text"
import get from "lodash/get"
import Paragraph from "../typography/Paragraph"
import Body from "../typography/Body"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface ExtendedContentfulRichTextGatsbyReference extends ContentfulRichTextGatsbyReference {
  fluid?: any; // Adjust 'any' to the correct type if available
}


type ContentfulRichTextProps = BoxProps & {
  richText?: {
    raw?: string | null;
    references?: ExtendedContentfulRichTextGatsbyReference[];
  } | null;
};

const createOptions = (references: ExtendedContentfulRichTextGatsbyReference[]) => ({
  renderMark: {
    [MARKS.BOLD]: (text: any) => {
      return (
        <Text as="span" fontWeight="bold">
          {text}
        </Text>
      )
    },
    [MARKS.ITALIC]: (text: any) => {
      return (
        <Text as="span" fontStyle="italics">
          {text}
        </Text>
      )
    },
    [MARKS.UNDERLINE]: (text: any) => {
      return (
        <Text as="span" textDecoration="underline">
          {text}
        </Text>
      )
    },
    [MARKS.CODE]: (text: any) => {
      return <Code>{text}</Code>
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return <Paragraph mb="20">{children}</Paragraph>
    },
    [BLOCKS.HEADING_1]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h1" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.HEADING_2]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h2" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h3" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.HEADING_4]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h4" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.HEADING_5]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h5" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.HEADING_6]: (node: any, children: any) => {
      return (
        <Body fontWeight="bold" as="h6" size="md" mt="80" mb="20">
          {children}
        </Body>
      )
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => {
      return (
        <List as="ol" pl="1rem" styleType="disc" my="20">
          {children}
        </List>
      )
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <List as="ul" pl="1rem" styleType="decimal" my="20">
          {children}
        </List>
      )
    },
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return (
        <ListItem>
          <Box d="inline-block">{children}</Box>
        </ListItem>
      )
    },
    [BLOCKS.QUOTE]: (node: any, children: any) => {
      return (
        <Box borderLeft="2px solid" borderColor="dawn" pl="1rem" my="20">
          {children}
        </Box>
      )
    },
    [INLINES.HYPERLINK]: (node: any, children: any) => {
      const uri = get(node, "data.uri")
      return (
        <Link href={uri} color="primary">
          {children}
        </Link>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      const assetId = node.data.target.sys.id;
      const reference = references.find(ref => ref.contentful_id === assetId);
      if (!reference || !reference.fluid) return null;
      
      const { fluid } = reference;
      
      return <Image
        imgStyle={{ objectFit: "contain" }}
        fluid={fluid}
      />
    },
  },
})

const ContentfulRichText: React.FC<ContentfulRichTextProps> = ({ richText, ...props }) => {
  if (!richText) return null;
  const { raw, references = [] } = richText;

  // Parse raw JSON string to object
  const content = raw ? JSON.parse(raw) : {};

  // Create options with references
  const options = createOptions(references);

  return (
    <Box {...props}>
      {documentToReactComponents(content, options)}
    </Box>
  );
}

export default ContentfulRichText;
