import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { useForm } from "react-hook-form"
import Heading from "../typography/Heading"
import Body from "../typography/Body"
import { Button } from "../Buttons"
import LinkButton from "../LinkButton"
import TextField from "../FormFields/TextField"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"

export type ForgotPasswordValues = {
  email: string
}

type ForgotPasswordFormProps = Omit<BoxProps, "onSubmit"> & {
  defaultEmail?: string
  onSubmit?: (values: ForgotPasswordValues) => void
  onSucess?: VoidFunction
  onBackToLogin?: VoidFunction
}

export default function ForgotPasswordForm({
  defaultEmail,
  onSubmit,
  onSucess,
  onBackToLogin,
  ...props
}: ForgotPasswordFormProps) {
  const [state, setState] = React.useState<
    "initial" | "loading" | "success" | "error"
  >("initial")
  const dispatch = useDispatch<BaseDispatch>()
  const { register, handleSubmit } = useForm<ForgotPasswordValues>()
  const handleFormSubmit = async (values: ForgotPasswordValues) => {
    setState("loading")
    onSubmit?.(values)
    // @ts-ignore
    const response: {
      success: boolean
      error?: string
    } = await dispatch.user.sendForgotPasswordEmail(values.email)
    console.log({ response })
    if (response.success) {
      onSucess?.()
      setState("success")
    } else {
      setState("error")
    }
  }

  return (
    <Box w="100%" {...props}>
      <Box
        as="form"
        textAlign="center"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Heading
          as="h5"
          size="5"
          color="night"
          fontWeight="bold"
          mb={["3.875rem", null, null, null, "3rem"]}
        >
          Forgot Password
        </Heading>
        {(state === "initial" || state === "loading") && (
          <Body size="md" color="dawn" mb="3.0625rem">
            We’ll email you a temporary password that you can use to log in and
            enter a new password.
          </Body>
        )}
        {state === "success" && (
          <Body size="md" color="primary" mb="3.0625rem">
            The reset email has been sent!
          </Body>
        )}
        {state === "error" && (
          <Body size="md" color="sunrise" mb="3.0625rem">
            Unable to send reset email. The email may not be in our system.
          </Body>
        )}
        <TextField
          ref={register({ required: true })}
          name="email"
          type="email"
          placeholder="Email"
          defaultValue={defaultEmail}
          h="3rem"
          mb="20"
        />
        <Button type="submit" w="100%" mb="40" isLoading={state === "loading"}>
          Reset
        </Button>
        <LinkButton
          color="dawn"
          type="reset"
          fontWeight="100"
          onClick={onBackToLogin}
        >
          Back to Login
        </LinkButton>
      </Box>
    </Box>
  )
}
