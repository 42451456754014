import React from "react"
import { Flex, FlexProps, Icon } from "@chakra-ui/core"
import SmallCaps from "../typography/SmallCaps"

type FitButtonProps = FlexProps & {}

export default function FitButton({ ...props }: FitButtonProps) {
  return (
    <Flex
      w="3.75rem"
      h="100vh"
      justify="center"
      align="center"
      flexDir="column"
      borderLeft="1px"
      borderColor="dividerLine"
      backgroundColor="white"
      zIndex={1}
      {...props}
      className="BodyFitFitButtonStrip"
    >
      <Icon name="ruler" size="21px" color="#fff" cursor="pointer" />
      <SmallCaps
        size="lg"
        color="#fff"
        fontWeight="medium"
        lineHeight="1.0625rem"
        cursor="pointer"
      >
        Fit
      </SmallCaps>
    </Flex>
  )
}
