import React from "react"

interface UseGoogleAnalyticsProps {
  category: string
  action: string
  shouldFireOnFirstRender?: boolean
}

interface UseGoogleAnalyticsReturnType {
  fireEvent: VoidFunction
}

const useGoogleAnalytics = ({
  shouldFireOnFirstRender = true,
  category,
  action,
}: UseGoogleAnalyticsProps): UseGoogleAnalyticsReturnType => {
  const [isGtagAvailable, setIsGtagAvailable] = React.useState<boolean>(false)
  const intervalRef = React.useRef<number | undefined>()
  const numberOfEventsToSend = React.useRef<number>(
    shouldFireOnFirstRender ? 1 : 0
  )

  // Fire event is should fire event
  const fireEvent = () => {
    if (isGtagAvailable) {
      // @ts-ignore
      window.gtag("event", action, {
        event_category: category,
        // @ts-ignore
        dimension2: window.branch,
      })
    } else {
      numberOfEventsToSend.current = numberOfEventsToSend.current + 1
    }
  }

  // Check if Gtag is Available
  React.useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      if (
        !isGtagAvailable &&
        typeof window !== undefined &&
        // @ts-ignore
        window.gtag !== undefined
      ) {
        setIsGtagAvailable(true)
        if (intervalRef.current) {
          window.clearInterval(intervalRef.current)
        }
        new Array(numberOfEventsToSend.current)
          .fill(undefined)
          .forEach(fireEvent)
      }
    }, 500)

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [])

  return {
    fireEvent,
  }
}

export default useGoogleAnalytics
