import format from "date-fns/format"
import isValid from "date-fns/isValid"
import { getFixedPrice } from "../Cart/utils"
import { ContentfulProductVariant } from "../../../graphql-types"
import { ShopifyProductVariantFieldsType } from "../../shopify/graphql/fragments"

export const dateToPretty = (date: Date, year = true, time = false) =>
  date && isValid(date) ? format(date, `MMM d${year ? ", yyyy" : ""}${time ? " p" : ""}`) : "N/A"

export const getPriceDiff = (
  current: ContentfulProductVariant,
  next: ContentfulProductVariant,
  pricingMap: Record<string, ShopifyProductVariantFieldsType>
) => {
  const currentPrice = Number(
    pricingMap[current?.shopifyId || ""]?.priceV2?.amount || "0"
  )
  const variantPrice = Number(
    pricingMap[next?.shopifyId || ""]?.priceV2?.amount || "0"
  )
  const diff = variantPrice - currentPrice
  return diff !== 0
    ? `(${diff < 0 ? "-" : "+"} ${getFixedPrice(Math.abs(diff).toString())})`
    : ""
}
