import { theme } from "@chakra-ui/core"

import colors from "./colors"
import { breakpoints } from "./breakpoints"
import { CustomChakraIcons } from "../components/icons"
import { SIDEBAR_WIDTH_CLOSED, SIDEBAR_WIDTH_OPEN } from "./measurements"
import { rem } from "../utils"

type ThemeType = typeof theme

// @ts-ignore
export default {
  ...theme,
  colors,
  breakpoints,
  icons: {
    ...theme.icons,
    ...CustomChakraIcons,
  },
  fontSizes: {
    ...theme.fontSizes,
    // Heading
    h1: "3.125rem",
    h2: "2.5rem",
    h3: "2rem",
    h4: "1.5625rem",
    h5: "1.25rem",
    h6: "1rem",
    // Paragraph
    parXl: "1.25rem",
    parLg: "1.125rem",
    parMd: "1rem",
    // Body
    bodyLg: "1.1rem",
    body: "1rem", // Normal in style guide
    bodySm: "0.94rem",
    bodyXs: "0.875rem",
    // Small caps
    smallCapsLg: "0.875rem",
    smallCapsSm: "0.75rem",
  },
  fontWeights: {
    light: 400,
    normal: "normal",
    medium: 500,
    semibold: 500,
    bold: 600,
  },
  letterSpacings: {
    ...theme.letterSpacings,
    wide300: "0.125rem",
    wide200: "0.0938rem",
    wide100: "0.0625rem",
    thin100: "-0.0094rem",
    thin200: "-0.0156rem",
  },
  fonts: {
    heading: "DIN, sans-serif",
    body: "DIN, sans-serif",
    avenirNext: "DIN, sans-serif",
    avenirNextDemi: "DIN, sans-serif",
    avenirNextUltra: "DIN, sans-serif",
    din: "DIN, sans-serif",
  },
  space: {
    ...theme.space,
    8: "0.5rem",
    20: "1.25rem",
    40: "2.5rem",
    80: "5rem",
    200: "12.5rem",
  },
  sizes: {
    ...theme.sizes,
    sidebar: `${rem(SIDEBAR_WIDTH_OPEN)}rem`,
    sidebarClosed: `${rem(SIDEBAR_WIDTH_CLOSED)}rem`,
  },
  shadows: {
    ...theme.shadows,
    outline: "0 0 0 0.1875rem rgb(0, 196, 209, 0.6)",
    // outline: "0 0.25rem 3.125rem rgba(18, 25, 35, 0.1)",
    smallDarker: "0px 0.25rem 3.125rem rgba(18, 25, 35, 0.1)",
    big: "0px 1.5625rem 6.25rem rgba(18, 25, 35, 0.05)",
  },
  zIndices: {
    ...theme.zIndices,
    dropdown: 2,
    // To trump greedy zendesk button
    modal: 999999,
  },
} as ThemeType
