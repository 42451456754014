import { useRef, useEffect } from "react"
import Maybe from "graphql/tsutils/Maybe"

export default function useInterval(
  callback: () => void,
  delay?: Maybe<number>
) {
  const savedCallback = useRef<() => void>()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback?.current?.()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return () => {}
  }, [delay])
}
