import React from "react"
import { useDispatch, useSelector } from "react-redux"

import Header, { ConnectedHeaderProps } from "../"
import { BaseDispatch, BaseRootState } from "../../../redux/store"
import { BaseSuccessStatusResponse } from "../../../redux/models"
import { LoginFormValues } from "../../Account/LoginForm"
import { SignUpFormValues } from "../../Account/SignUpForm"

const ConnectedHeader: React.FC<ConnectedHeaderProps> = (props) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { email, isSideBarOpen, branding } = useSelector(
    ({ user, sidebar, branding }: BaseRootState) => ({
      email: user.email ?? "",
      isSideBarOpen: sidebar.isOpen,
      branding: branding.branding,
    })
  )

  const onLogin = async (
    values: LoginFormValues
  ): Promise<BaseSuccessStatusResponse> =>
    // @ts-ignore
    await dispatch.user.login(values)

  const logout = async () => await dispatch.user.logout()
  const onSignup = async (
    values: SignUpFormValues
  ): Promise<BaseSuccessStatusResponse> =>
    // @ts-ignore
    await dispatch.user.signup(values)

  const connectedProps = {
    email,
    logout,
    onLogin,
    onSignup,
    branding,
    ...props,
  }
  return <Header isSideBarOpen={isSideBarOpen} {...connectedProps} />
}

export default ConnectedHeader
