import React from "react"
import { Flex, FlexProps, Icon } from "@chakra-ui/core"
import { bp } from "../../utils/MediaQueries"

type StepHeaderProps = FlexProps & {
  onClose: VoidFunction
}

export default function StepHeader({
  children,
  onClose,
  ...props
}: StepHeaderProps) {
  return (
    <Flex
      pos="relative"
      minHeight="6rem"
      align="center"
      justify={bp("center", "flex-start")}
      px="2.25rem"
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
      {...props}
    >
      <Flex
        className="QuizEditAnswerSectionNav"
        role="button"
        cursor="pointer"
        pos={bp("absolute", "static")}
        left={bp("2.25rem", "auto")}
        top={bp("0", "auto")}
        bottom={bp("0", "auto")}
        marginY="auto"
        bg={bp("dividerLine", "white")}
        w={bp("2.75rem", "")}
        h={bp("2.75rem", "")}
        borderRadius="0.5rem"
        align="center"
        justify="center"
        onClick={onClose}
      >
        <Icon name="chevron" size="1.25rem" color="dusk" />
      </Flex>
      {children}
    </Flex>
  )
}
