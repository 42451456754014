import { ModelConfig } from "@rematch/core"
import { BaseDispatch, BaseRootState } from "../store"
import * as QuizTypes from "../types/QuizTypes"
import { getNextQuestion, getPrevQuestion } from "../utils/quiz"

import {contentfulGlobalsettings} from "../../contentful/queries"

export interface AnswersType {
  date: string
  riderType: "" | QuizTypes.RiderStyleType
  [questionId: string]: QuizTypes.AnswerIdType | QuizTypes.AnswerIdType[]
  sentToDB: "true" | "false"
}

export interface QuizStateType {
  quizProgressState: QuizTypes.QuizProgressStateType
  questions: Array<QuizTypes.Question>
  currentQuestionIndex: number
  previousQuizAnswers: Array<AnswersType>
  answers: AnswersType
  currentPreviousAnswersDate: string | null
  discount:number
  error?: any
}

const initialState: QuizStateType = {
  quizProgressState: "initial",
  questions: [],
  currentQuestionIndex: 0,
  answers: { date: "", riderType: "", sentToDB: "false" },
  previousQuizAnswers: [], // This is sorted (descending) everytime it is set
  currentPreviousAnswersDate: null,
  discount: 0,
  error: null,
}

export const quiz: ModelConfig<QuizStateType> = {
  state: initialState,
  reducers: {
    setQuestionIndex(state, currentQuestionIndex: number) {
      if (currentQuestionIndex <= state.questions.length)
        return {
          ...state,
          currentQuestionIndex,
        }
      return { ...state }
    },
    setQuestions(state, questions: QuizTypes.Question[]) {
      return { ...state, questions }
    },
    setError(state, error: any) {
      return { ...state, error }
    },
    setAnswer(state, questionAnswerPair: QuizTypes.QuestionAnswerPair) {
      return {
        ...state,
        answers: {
          ...state.answers,
          [questionAnswerPair.questionId]: questionAnswerPair.answerId,
        },
      }
    },
    setAnswers(state: QuizStateType, answers: AnswersType) {
      return { ...state, answers }
    },
    setDiscount(state: QuizStateType, discount: number) {
      return { ...state, discount };
    },    
    setProgressState(
      state,
      quizProgressState: QuizTypes.QuizProgressStateType
    ) {
      return { ...state, quizProgressState }
    },
    setQuizStartTime(state: QuizStateType, timestamp: string) {
      return { ...state, answers: { ...state.answers, date: timestamp } }
    },
    setPreviousAnswers(
      state: QuizStateType,
      previousQuizAnswers: Array<AnswersType>
    ) {
      const answers = previousQuizAnswers.sort((a, b) => {
        const dateA = new Date(parseInt(a.date))
        const dateB = new Date(parseInt(b.date))
        return dateA > dateB ? -1 : dateB > dateA ? 1 : 0
      })
      return { ...state, previousQuizAnswers: answers }
    },
    setCurrentPreviousAnswersDate(
      state: QuizStateType,
      currentPreviousAnswersDate: string
    ) {
      return { ...state, currentPreviousAnswersDate }
    },
    resetQuizState(state: QuizStateType, _) {
      return { ...initialState, questions: state.questions }
    },
    setEmail(state: QuizStateType, email: string | null) {
      return { ...state, email }
    },
    setPhone(state: QuizStateType, phone: string | null) {
      return { ...state, phone }
    },
    setPhoneOptIn(state: QuizStateType, phoneOptIn: boolean) {
      return { ...state, phoneOptIn }
    },
  },
  effects: (dispatch: any) => ({
    answerQuestion(
      payload: QuizTypes.QuestionAnswerPair,
      state: BaseRootState
    ) {
      dispatch.quiz.setAnswer(payload)
    },
    nextQuestion(_, state: BaseRootState) {
      const { currentQuestionIndex, answers, questions } = state.quiz
      if (currentQuestionIndex === questions.length - 1) {
        dispatch.quiz.setQuestionIndex(questions.length)
        dispatch.quiz.setProgressState("finished")
      } else {
        const nextQuestion = getNextQuestion({
          currentQuestionIndex,
          questions,
          answers,
        })
        if (nextQuestion) {
          dispatch.quiz.setQuestionIndex(nextQuestion.index)
        }
      }
    },
    prevQuestion(_, state: BaseRootState) {
      const { currentQuestionIndex, answers, questions } = state.quiz
      if (currentQuestionIndex > 0) {
        const prevQuestion = getPrevQuestion({
          currentQuestionIndex,
          questions,
          answers,
        })
        if (prevQuestion) {
          dispatch.quiz.setQuestionIndex(prevQuestion.index)
        }
      }
    },
    resetQuiz(_, state: BaseRootState) {
      dispatch.quiz.setQuestionIndex(0)
      dispatch.quiz.setAnswers({ date: "", riderType: "" })
      if (
        state.quiz.quizProgressState === "inprogress" ||
        state.quiz.quizProgressState === "paused"
      ) {
        dispatch.quiz.setProgressState(
          state.quiz.previousQuizAnswers.length > 0 ? "previous" : "initial"
        )
      }
    },
    onQuizExit(_, state: BaseRootState) {
      dispatch.quiz.setProgressState("previous")
      dispatch.quiz.setCurrentPreviousAnswersDate(state.quiz.answers.date)
      dispatch.quiz.setPreviousAnswers([
        state.quiz.answers,
        ...state.quiz.previousQuizAnswers,
      ])
      dispatch.quiz.resetQuiz()
    },
    loadPrevious(_, state: BaseRootState) {
      if (
        state.quiz.currentPreviousAnswersDate !== null &&
        state.quiz.previousQuizAnswers.length > 0
      ) {
        const results = state.quiz.previousQuizAnswers.find(
          (answerSet) =>
            answerSet.date === state.quiz.currentPreviousAnswersDate
        )
        if (results !== undefined) {
          dispatch.quiz.setAnswers(results)
        }
      }
    },
    setInitialDiscount(_, state: BaseRootState) {
      try {
        contentfulGlobalsettings('Main Configuration').then((response) =>{
          if(!state.quiz?.discount) {
            //@ts-ignore
            dispatch.quiz.setDiscount(response[0]?.fields?.bodyFitQuizDiscount)
          }
        })
      } catch (error) {
        // Handle the error if needed
        console.error("Error fetching initial discount:", error);
      }
    },
    
  }),
}
