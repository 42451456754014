import React from "react"
import { Flex, FlexProps, Box } from "@chakra-ui/core"
import { useForm } from "react-hook-form"

import Heading from "../typography/Heading"
import { Button } from "../Buttons"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"
import { BaseSuccessStatusResponse } from "../../redux/models"
import Body from "../typography/Body"
import SmallCaps from "../typography/SmallCaps"
import { LoginFormValues } from "./LoginForm"
import LinkButton from "../LinkButton"
import TextField from "../FormFields/TextField"

export interface SignUpFormValues {
  email: string
  password: string
  firstName: string
  lastName: string
}

export type SignUpFormProps = FlexProps & {
  onSignUpSuccess?: VoidFunction
  onLogin: VoidFunction
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  onLogin,
  onSignUpSuccess = () => {},
  ...props
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const [signUpSuccess, setSignUpSuccess] = React.useState<
    "success" | "failed" | null
  >(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { handleSubmit, register, errors, setError } = useForm<
    LoginFormValues
  >()
  const onSubmit = async (values: LoginFormValues) => {
    setIsLoading(true)
    // @ts-ignore
    const response: BaseSuccessStatusResponse = await dispatch.user.signup(
      values
    )
    if (response.success) {
      onSignUpSuccess()
    } else {
      setSignUpSuccess("failed")
    }
    setIsLoading(false)
  }
  return (
    <Flex direction="column" alignItems="center" w="100%" {...props}>
      <Heading
        as="h5"
        size="5"
        color="night"
        fontWeight="bold"
        mb={["3rem", null, null, null, "3.25rem"]}
      >
        Create Account
      </Heading>
      <Box as="form" w="100%" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="firstName"
          placeholder="First Name"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        <TextField
          name="lastName"
          placeholder="Last Name"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        <TextField
          name="email"
          placeholder="Email"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        <TextField
          name="password"
          type="password"
          placeholder="Password"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        {signUpSuccess === "failed" && (
          <SmallCaps size="sm" color="red">
            This account already exists
          </SmallCaps>
        )}
        <Button type="submit" w="100%" mt="2rem" isLoading={isLoading}>
          Create Account
        </Button>
      </Box>
      <Flex w="100%" justifyContent="center" mt="2rem" fontWeight="100">
        <Body color="dusk">Back to &nbsp;</Body>
        <LinkButton onClick={onLogin}>Log In</LinkButton>
      </Flex>
    </Flex>
  )
}

export default SignUpForm
