/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "sanitize.css"
import "focus-visible/dist/focus-visible"
import "./src/styles/global.css"
import "./src/assets/fonts/stylesheet.css"
import "./src/styles/loyalty-lion.css"

import React from "react"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider as ChakraThemeProvider, CSSReset } from "@chakra-ui/core"
import { Location } from "@gatsbyjs/reach-router"

import { persistor } from "./src/redux/store"
import ShopifyProvider from "./src/shopify/providers/ShopifyProvider"
import { ScrollbarWidthProvider } from "./src/context/scrollbarWidth"
import { LoginDrawerProvider } from "./src/context/loginDrawer"
import ChakraTheme from "./src/constants/theme"
import LocationHandler from "./src/components/LocationHandler"
import DeferredScripts from "./src/components/Scripts/DeferredScripts"

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraThemeProvider theme={ChakraTheme}>
      <PersistGate persistor={persistor}>
        <ShopifyProvider>
          <Location>
            {({ location }) => (
              <LocationHandler location={location}>
                <DeferredScripts location={location} />
                <ScrollbarWidthProvider>
                  <CSSReset />
                  <LoginDrawerProvider>{element}</LoginDrawerProvider>
                </ScrollbarWidthProvider>
              </LocationHandler>
            )}
          </Location>
        </ShopifyProvider>
      </PersistGate>
    </ChakraThemeProvider>
  )
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
