import React from "react"
import {
  Flex,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  LinkProps,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Link,
  Icon,
  SimpleGrid,
} from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"
import loadable from "@loadable/component"
import { bp, LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import ExternalLink from "../ExternalLink"
import MaxWidthGrid from "../Layout/MaxWidthGrid"
import LinkButton from "../LinkButton"
import Body from "../typography/Body"
import IconButton from "../Buttons/IconButton"
import SmallCaps, { SmallCapsProps } from "../typography/SmallCaps"
import useLoginDrawer from "../../context/loginDrawer"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"
import useLoadOnInteraction from "../../hooks/useLoadOnInteraction"

const Trustpilot = loadable(() => import("../Trustpilot"))

const YEAR = new Date().getFullYear()

type FooterProps = BoxProps & {
  facebookURL?: string
  twitterURL?: string
  tiktokURL?: string
  instagramURL?: string
  youtubeURL?: string
  forumURL?: string
  terms?: string
  privacyPolicy?: string
  isLoggedIn: boolean
}

const Column = (props: BoxProps) => (
  <Box gridColumn="auto/span 3" gridRow={1} {...props} />
)

const ColumnTitle = (props: SmallCapsProps) => (
  <SmallCaps
    whiteSpace="pre"
    fontWeight="bold"
    px="0.725rem"
    mb="1.6563rem"
    {...props}
  />
)

const FooterLink = ({
  to = "/",
  isExternal,
  ...props
}: ButtonProps & { to?: string; isExternal?: boolean }) => (
  <Button
    as={isExternal ? ExternalLink : GatsbyLink}
    // @ts-ignore
    to={to}
    w="100%"
    height="auto"
    justifyContent="left"
    variant="ghost"
    fontSize="0.9375rem"
    px="0.725rem"
    py="0.5rem"
    borderRadius="0.5rem"
    whiteSpace="normal"
    textDecoration="none"
    mb={["0.375rem", null, null, null, null, "0.125rem"]}
    color="dawn"
    _hover={{
      backgroundColor: "dividerLine",
      textDecoration: "none",
    }}
    _active={{
      backgroundColor: "noon",
      color: "dusk",
    }}
    {...props}
  />
)

const SocialLink = ({
  "aria-label": ariaLabel,
  icon,
  ...props
}: LinkProps & { icon: string }) => (
  <ExternalLink color="dusk" {...props}>
    <IconButton
      icon={icon}
      aria-label={ariaLabel || ""}
      mr="1px"
      variant="ghost"
      color="dusk"
    />
  </ExternalLink>
)

const FooterAccordionSection: React.FC<{ header: string }> = ({
  header,
  children,
}) => (
  <AccordionItem>
    <AccordionHeader
      display="flex"
      justifyContent="space-between"
      borderBottomColor="dividerLine"
      py="1.25rem"
    >
      <Body size="sm" color="dusk">
        {header}
      </Body>
      <AccordionIcon color="dawn" />
    </AccordionHeader>
    <AccordionPanel p="0">{children}</AccordionPanel>
  </AccordionItem>
)

const AccordionLink = ({
  icon,
  children,
  isExternal,
  to = "/",
  ...props
}: LinkProps & { to?: string; isExternal?: boolean; icon?: string }) => (
  <Link
    // @ts-ignore
    as={isExternal ? ExternalLink : GatsbyLink}
    to={to}
    display="block"
    w="100%"
    pt="1.4275rem"
    pb="1.3225rem"
    px="1.2325rem"
    borderBottom="1px solid"
    borderBottomColor="dividerLine"
    color="dusk"
    _last={{
      borderBottom: "0",
    }}
    {...props}
  >
    {icon && (
      <Icon
        name={icon}
        verticalAlign="middle"
        color="dusk"
        mr="0.5rem"
        display="inline-block"
      />
    )}
    <Body as="span" size="sm">
      {children}
    </Body>
  </Link>
)

const Footer: React.FC<FooterProps> = ({
  facebookURL = "https://www.facebook.com/sixthreezero.co",
  instagramURL = "https://www.instagram.com/_sixthreezero_",
  twitterURL = "https://twitter.com/sixthreezero_",
  tiktokURL = "https://www.tiktok.com/@sixthreezerobicycles",
  youtubeURL = "https://www.youtube.com/channel/UC4BwfiXeSdq3dm_fBa61s2Q",
  forumURL = "https://sixthreezero.zendesk.com/hc/en-us",
  privacyPolicy = "/pages/privacy-policy",
  terms = "/pages/terms-and-condition",
  isLoggedIn,
  ...props
}) => {
  const { openLoginDrawer } = useLoginDrawer()
  const dispatch = useDispatch<BaseDispatch>()
  const loadDeferred = useLoadOnInteraction()

  return (
    <Box pb="4rem" {...props}>
      {/* Desktop */}
      <LaptopUpOnly>
        <MaxWidthGrid py="4rem" px="1.25rem" gridColumnGap="2rem">
          <Column>
            <ColumnTitle>Shop</ColumnTitle>
            <FooterLink to="/collections/all-standard-bikes">Bikes</FooterLink>
            <FooterLink to="/collections/all-electric-bikes">
              Electric Bikes
            </FooterLink>
            <FooterLink to="/accessories/collections/all">
              Accessories
            </FooterLink>
            <FooterLink to="/accessories/gift-cards">Gift Cards</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Explore</ColumnTitle>
            <FooterLink to="/blogs/bike-advice">Bike Advice</FooterLink>
            <FooterLink to="/pages/get-fitted">Get Fitted</FooterLink>
            <FooterLink to="/pages/journey-club">Journey Club</FooterLink>
            <FooterLink to="/pages/our-story">Our Story</FooterLink>
            <FooterLink to="/pages/rider-styles">Rider Styles</FooterLink>
            <FooterLink
              to="https://www.sixthreezero.com/pages/affiliate-program"
              isExternal
            >
              Affialiate Program
            </FooterLink>
            <FooterLink to="/pages/become-a-brand-ambassador">
              Become a Brand Ambassador
            </FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Info</ColumnTitle>
            <FooterLink to="/pages/30-day-test-ride">Test Ride</FooterLink>
            <FooterLink to="/pages/available-financing">Financing</FooterLink>
            {/* <FooterLink to="/pages/pedal-points">Pedal Points</FooterLink> */}
            <FooterLink to="/pages/sixthreezero-ultimate-bike-buyers-guide">
              Buyer&apos;s Guide
            </FooterLink>
            <FooterLink to="/pages/find-a-retailer">Find a Retailer</FooterLink>
            <FooterLink to="/pages/corporate-bulk-orders">
              Bulk Order
            </FooterLink>
            <FooterLink to="/blogs/bike-advice/discount-codes">
              Discount Codes
            </FooterLink>
            <FooterLink to="/pages/visit-our-store">Visit Us</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Support</ColumnTitle>
            <FooterLink to="/pages/warranty">Warranty</FooterLink>
            <FooterLink to="/pages/shipping">Shipping</FooterLink>
            <FooterLink to="https://sixthreezero.returnscenter.com/" isExternal>
              Returns
            </FooterLink>
            <FooterLink to="/apps/product-registration">
              Register Bike
            </FooterLink>
            <FooterLink to="/blogs/assembly-instructions">Assembly</FooterLink>
            <FooterLink to="/pages/contact-us">Contact Us</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Account</ColumnTitle>
            {isLoggedIn ? (
              <>
                <FooterLink to="/account/orders">Orders</FooterLink>
                <FooterLink to="/account/addresses">Addresses</FooterLink>
                {/* <FooterLink to="/account/pedal-points">Pedal Points</FooterLink> */}
              </>
            ) : (
              <FooterLink onClick={() => openLoginDrawer()}>Log In</FooterLink>
            )}
            <FooterLink to={forumURL} isExternal>
              Forum
            </FooterLink>
          </Column>
        </MaxWidthGrid>
      </LaptopUpOnly>
      {/* Mobile */}
      <LaptopDownOnly>
        <Accordion mt="2rem">
          <FooterAccordionSection header="Shop">
            <AccordionLink to="/collections/all-standard-bikes">
              Bikes
            </AccordionLink>
            <AccordionLink to="/collections/all-electric-bikes">
              Electric Bikes
            </AccordionLink>
            <AccordionLink to="/accessories/collections/all">
              Accessories
            </AccordionLink>
            <AccordionLink to="/accessories/gift-cards">
              Gift Cards
            </AccordionLink>
          </FooterAccordionSection>
          <FooterAccordionSection header="Explore">
            <AccordionLink to="/blogs/bike-advice">Bike Advice</AccordionLink>
            <AccordionLink to="/pages/get-fitted">Get Fitted</AccordionLink>
            <AccordionLink to="/pages/journey-club">Journey Club</AccordionLink>
            <AccordionLink to="/pages/our-story">Our Story</AccordionLink>
            <AccordionLink to="/pages/rider-styles">Rider Styles</AccordionLink>
            <AccordionLink
              to="https://www.sixthreezero.com/pages/affiliate-program"
              isExternal
            >
              Affiliate Program
            </AccordionLink>
            <AccordionLink to="/pages/become-a-brand-ambassador">
              Become a Brand Ambassador
            </AccordionLink>
          </FooterAccordionSection>
          <FooterAccordionSection header="Info">
            <AccordionLink to="/pages/30-day-test-ride">
              Test Ride
            </AccordionLink>
            <AccordionLink to="/pages/available-financing">
              Financing
            </AccordionLink>
            {/* <AccordionLink to="/pages/pedal-points">Pedal Points</AccordionLink> */}
            <AccordionLink to="/pages/sixthreezero-ultimate-bike-buyers-guide">
              Buyer&apos;s Guide
            </AccordionLink>
            <AccordionLink to="/pages/find-a-retailer">
              Find a Retailer
            </AccordionLink>
            <AccordionLink to="/pages/corporate-bulk-orders">
              Bulk Order
            </AccordionLink>
            <AccordionLink to="/blogs/bike-advice/discount-codes">
              Discount Codes
            </AccordionLink>
            <AccordionLink to="/pages/visit-our-store">Visit Us</AccordionLink>
          </FooterAccordionSection>
          <FooterAccordionSection header="Support">
            <AccordionLink to="/pages/warranty">Warranty</AccordionLink>
            <AccordionLink to="/pages/shipping">Shipping</AccordionLink>
            <AccordionLink
              to="https://sixthreezero.returnscenter.com/"
              isExternal
            >
              Returns
            </AccordionLink>
            <AccordionLink to="/apps/product-registration">
              Register Bike
            </AccordionLink>
            <AccordionLink to="/blogs/assembly-instructions">
              Assembly
            </AccordionLink>
            <AccordionLink to="/pages/contact-us">Contact Us</AccordionLink>
          </FooterAccordionSection>
          <FooterAccordionSection header="Social">
            <AccordionLink icon="instagram" to={instagramURL} isExternal>
              Instagram
            </AccordionLink>
            <AccordionLink icon="tiktok" to={tiktokURL} isExternal>
              TikTok
            </AccordionLink>
            <AccordionLink icon="facebook" to={facebookURL} isExternal>
              Facebook
            </AccordionLink>
            <AccordionLink icon="youtube" to={youtubeURL} isExternal>
              YouTube
            </AccordionLink>
          </FooterAccordionSection>
          <FooterAccordionSection header="Account">
            {isLoggedIn ? (
              <>
                <AccordionLink to="/account/orders">Orders</AccordionLink>
                <AccordionLink to="/account/addresses">Addresses</AccordionLink>
                {/* <AccordionLink to="/account/pedal-points">
                  Pedal Points
                </AccordionLink> */}
                <AccordionLink onClick={() => dispatch.user.logout()}>
                  Log Out
                </AccordionLink>
              </>
            ) : (
              <AccordionLink onClick={() => openLoginDrawer()}>
                Log In
              </AccordionLink>
            )}
            <AccordionLink to={forumURL} isExternal>
              Forum
            </AccordionLink>
          </FooterAccordionSection>
        </Accordion>
      </LaptopDownOnly>
      <SimpleGrid
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="dividerLine"
        py={["1.5625rem", null, null, null, "2.4238rem"]}
        alignItems="center"
      >
        <Flex
          gridColumn={["1/3", null, null, null, "2/auto"]}
          gridRow={["2", null, null, null, "auto"]}
          align="center"
          justify="center"
        >
          <Body size="small" color="dawn" whiteSpace="nowrap" mr="1rem">
            &copy; {YEAR} sixthreezero
          </Body>
          <GatsbyLink to="/pages/privacy-policy">
            <LinkButton fontSize="0.875rem" fontWeight="normal" color="dawn">
              Privacy Policy
            </LinkButton>
          </GatsbyLink>
        </Flex>
        <Box
          gridColumn={["1/3", null, null, null, "5/10"]}
          mb={["0.75rem", null, null, null, "0"]}
          textAlign="center"
        >
          <SmallCaps color="dawn" fontWeight="semibold">
            Designed in Los Angeles, California
          </SmallCaps>
        </Box>
        <Flex display={["none", null, null, null, "flex"]} gridColumn="auto/14">
          <Flex align="center" justify="center">
            <SocialLink
              href={instagramURL}
              aria-label="Instagram"
              icon="instagram"
            />
            <SocialLink href={tiktokURL} aria-label="TikTok" icon="tiktok" />
            <SocialLink
              href={facebookURL}
              aria-label="Facebook"
              icon="facebook"
            />
            <SocialLink href={youtubeURL} aria-label="Youtube" icon="youtube" />
          </Flex>
        </Flex>
      </SimpleGrid>
      {loadDeferred && (
        <Flex
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          w="100%"
          p={["2.4238rem", null, null, null, "4rem"]}
        >
          <Trustpilot />
        </Flex>
      )}
      <script src="/jquery-min.js"></script>
      <script src="/dom-functions.js" defer></script>
      <script src="/LinkTree.js" defer></script>

      {/* Bing Ads */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
    (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"97040973", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})
    (window,document,"script","//bat.bing.com/bat.js","uetq");
  `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
    window.uetq = window.uetq || [];
    window.uetq.push('event', '', {'revenue_value': [Subtotal], 'currency': 'USD'});
  `,
        }}
      />

    </Box>
  )
}

export default Footer
