import React from "react"
import { Alert, AlertIcon, AlertProps } from "@chakra-ui/core"
import Body from "../typography/Body"

type NoticeProps = Omit<AlertProps, "size"> & {
  size?: "sm" | "md" | "lg"
}

export default function Notice({
  children,
  size = "md",
  ...props
}: NoticeProps) {
  return (
    <Alert
      status="info"
      borderRadius="0.5rem"
      backgroundColor="primaryLight10"
      {...props}
    >
      <AlertIcon size="1.25rem" color="primary" />
      <Body fontWeight="semibold" size={size} color="primary">
        {children}
      </Body>
    </Alert>
  )
}
