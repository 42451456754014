import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import styled from "@emotion/styled"

type FormattedTitleProps = BoxProps & {
  raw?: string | null
}

const StyledContainer = styled(Box)`
  p {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
  }

  strong {
    line-height: 100%;
  }
`

export default function FormattedTitle({ raw, ...props }: FormattedTitleProps) {
  return (
    <StyledContainer
      as="span"
      display="inline-flex"
      flexWrap="wrap"
      whiteSpace="break-spaces"
      alignItems="baseline"
      {...props}
      dangerouslySetInnerHTML={{ __html: raw || "" }}
    />
  )
}
