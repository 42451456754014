import {
  Box,
  BoxProps,
  Flex,
  Input,
  PseudoBox,
  useDisclosure,
} from "@chakra-ui/core"
import { isValidPhoneNumber } from "libphonenumber-js"
import React, { useRef, useState } from "react"
import NumberFormat from "react-number-format"
import { useDispatch, useSelector } from "react-redux"

import Body from "../typography/Body"
import { Button } from "./Buttons"
import { DesktopQuestionStepper } from "./QuestionStepper"

import { BaseDispatch, BaseRootState } from "../../redux/store"
import * as QuizTypes from "../../redux/types/QuizTypes"
import { bp } from "../../utils/MediaQueries"
import { unformatPhoneNumber } from "../../utils/text"

const QuestionText: React.FC = ({ children }) => (
  <Body
    fontSize={bp("1.5rem", "2rem")}
    fontWeight="bold"
    color="#fff"
    mb={["2.25rem", null, null, null, "2.5rem"]}
    className="QuizQuestionText"
    textAlign="center"
  >
    {children}
  </Body>
)

export interface QuestionProps {
  questionText: string
  currentQuestion: boolean
  options?: Array<QuizTypes.OptionProps>
  onAnswer: (answerId: string | Array<string>) => void
  answerId?: string
  currentQuestionId?: any
  isPopup?: boolean
}

export const Question: React.FC<QuestionProps> = ({
  questionText,
  currentQuestion,
  options,
  onAnswer,
  answerId,
  currentQuestionId,
  isPopup = false,
}) => {
  let flexDirection: any = "column"
  let optionWidth: any = "300px"
  let containerWidth: any = "100%"

  switch (currentQuestionId) {
    case "owned-time":
      flexDirection = bp("column", "row")
      optionWidth = bp("100%", "40%")
      containerWidth = bp("100%", "80%")
      break

    case "how-did-you-hear-about-us":
      flexDirection = bp("row", "row")
      optionWidth = bp("100%", "31%")
      containerWidth = bp("100%", "100%")
      break

    default:
      flexDirection = "column"
      optionWidth = "300px"
      containerWidth = bp("100%", "100%")
      break
  }

  return currentQuestion ? (
    // Question & Options
    <Flex
      flexDir="column"
      mt={bp(0, 0)}
      w="100%"
      className="buttonFlex"
      justifyContent="center"
      alignItems="center"
    >
      <QuestionText>{questionText}</QuestionText>
      <Flex
        className="QuizQuestionOptions"
        w="100%"
        // @ts-ignore
        flexDirection={flexDirection}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexWrap="wrap"
        maxW={containerWidth}
        style={{ gap: "1.5rem" }}
      >
        {options?.map((opt) => (
          <Button
            className="QuizQuestionAnswerBtn"
            key={`${questionText}-question-opt-${opt.answerId}`}
            mb="0"
            w={optionWidth}
            h={bp(isPopup ? "2.5rem" : "3rem", "3rem")}
            fontSize={bp("0.8rem", "0.9rem")}
            onClick={() => onAnswer(opt.answerId)}
            bg={answerId === opt.answerId ? "seafoam" : undefined}
          >
            {opt.answerText}
          </Button>
        ))}
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export const NumberQuestion: React.FC<
  QuestionProps & { isEditing?: boolean }
> = ({ questionText, currentQuestion, options, onAnswer, isEditing }) => {
  const [answers, setAnswers] = React.useState<Array<string>>(
    options?.map((_) => "") || []
  )

  const haveAllQuestionsBeenAnswered = () =>
    answers.reduce((acc, cur) => {
      if (cur.length < 1) return false
      return acc
    }, true)
  return currentQuestion ? (
    <Flex flexDir="column" mt={bp("1.5rem", 0)} alignItems="center">
      <QuestionText>{questionText}</QuestionText>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="ok"
      >
        <Flex style={{ gap: "20px" }}>
          {options?.map((opt, index) => (
            <Input
              className="QuizQuestionInput"
              key={`question-opt${opt.answerId}`}
              placeholder={opt.answerText}
              type="number"
              h="3.5rem"
              width="3.5rem"
              p="0"
              borderRadius="0.5rem"
              borderColor="dividerLine"
              color="dawn"
              fontSize="body15"
              lineHeight="1.25rem"
              letterSpacing="-0.15px"
              textAlign="center"
              // mr={["0.75rem", null, null, null, "1rem"]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAnswers(
                  answers.map((a, i) => {
                    if (i === index) {
                      const value = parseInt(event.target.value)
                      // @ts-ignore
                      if (value !== NaN) {
                        return `${value}`
                      }
                    }
                    return a
                  })
                )
              }
            />
          ))}
        </Flex>
        <Button
          className="QuizQuestionButton extraControls"
          isDisabled={!haveAllQuestionsBeenAnswered()}
          theme={!haveAllQuestionsBeenAnswered() ? "secondary" : "primary"}
          // ml={["1.25rem", null, null, null, "0"]}
          mt={"2rem"}
          w="100%"
          onClick={() => onAnswer(answers)}
          h="2.5rem"
        >
          {isEditing !== undefined ? "save" : "next"}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export const GenderQuestion = (props: QuestionProps) => (
  <Question
    {...props}
    options={[
      {
        answerText: "Womens",
        answerId: "Womens",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
      {
        answerText: "Mens",
        answerId: "Mens",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
      {
        answerText: "Either",
        answerId: "Either",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
    ]}
  />
)

export const EmailQuestion: React.FC<
  Omit<QuestionProps, "options"> & {
    isEditing?: boolean
    defaultValue?: string | null
  }
> = ({ currentQuestion, questionText, onAnswer, isEditing, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "")
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isValid = inputRef.current
    ? !!inputRef.current.validity.valid
    : !!defaultValue
  return currentQuestion ? (
    <Flex flexDir="column" mt={bp(0, 0)} alignItems="center">
      <QuestionText>{questionText}</QuestionText>
      <Flex flexDir="column" alignItems="center" width={"100%"}>
        <Flex width={"100%"} alignItems="center" justifyContent={"center"}>
          <Input
            ref={inputRef}
            placeholder="Email"
            type="email"
            value={value}
            h="3.5rem"
            w="100%"
            maxW={bp("90%", "500px")}
            borderRadius="0.5rem"
            borderColor="dividerLine"
            color="night"
            fontSize="body15"
            lineHeight="1.25rem"
            letterSpacing="-0.15px"
            isRequired
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
          />
        </Flex>
        <Button
          className="QuizButtonWhite"
          isDisabled={!isValid}
          theme={!isValid ? "secondary" : "primary"}
          mt="1.5rem"
          w="100%"
          maxW={bp("90%", "230px")}
          borderRadius="100px"
          onClick={() => onAnswer(value)}
          h="3rem"
        >
          {isEditing !== undefined ? "Save" : "Next"}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export const PhoneQuestion: React.FC<
  Omit<QuestionProps, "options"> & { isEditing?: boolean }
> = ({ currentQuestion, questionText, onAnswer, isEditing }) => {
  const [value, setValue] = useState("")
  const unformatted = unformatPhoneNumber(value)
  const isValid = isValidPhoneNumber(`+1${unformatted}`, "US")

  return currentQuestion ? (
    <Flex flexDir="column" mt={bp(0, 0)} alignItems="center">
      <QuestionText>{questionText}</QuestionText>
      <Flex flexDir="column" alignItems="center">
        <Flex>
          <Input
            as={NumberFormat}
            // @ts-ignore
            name={name}
            type="tel"
            h="3.5rem"
            // @ts-ignore
            format="(###) ###-####"
            allowEmptyFormatting
            removeFormatting={unformatPhoneNumber}
            mask="_"
            value={value}
            customInput={Input}
            onValueChange={(values) => {
              setValue(values.value)
            }}
          />
        </Flex>
        <Button
          isDisabled={!isValid}
          className="QuizButtonWhite"
          theme={!isValid ? "secondary" : "primary"}
          mt="1rem"
          w="100%"
          onClick={() => onAnswer(value)}
          h="3.5rem"
        >
          {isEditing !== undefined ? "Save" : "Next"}
        </Button>
        {!isValid && unformatted.length === 10 && (
          <Body color="sunrise" mt="0.5rem">
            This phone number is not valid in the US
          </Body>
        )}
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

type QuizQuestionsProps = {
  defaultEmail?: string
  className?: string
  onExit?: VoidFunction
  isPopup?: boolean
}

const QuizQuestions: React.FC<QuizQuestionsProps> = ({
  defaultEmail = null,
  className,
  onExit,
  isPopup = false,
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { questions, quizProgressState, currentQuestionIndex, answers } =
    useSelector(({ quiz }: BaseRootState) => quiz)
  const confirmExit = useDisclosure()

  const onStepNext = () => {
    dispatch.quiz.nextQuestion()
  }
  const onStepPrev = () => {
    dispatch.quiz.prevQuestion()
  }

  const interactiveProps: BoxProps = {
    opacity: confirmExit.isOpen ? 0.2 : 1,
    transition: "opacity 300ms ease",
    pointerEvents: confirmExit.isOpen ? "none" : "auto",
  }

  return (
    <Flex
      className={className}
      position="relative"
      flexDir="column"
      height="100%"
      pt={[null, null, null, null, "1rem"]}
      pb={["2.5rem", null, null, null, "0.5rem"]}
      justifyContent={"center"}
      alignItems="center"
      mx={bp(isPopup ? "0" : "1.25rem", 0)}
    >
      <DesktopQuestionStepper
        className="BodyQuizQuestionPagination"
        color="#fff"
        currentQuestionNumber={currentQuestionIndex + 1}
        totalQuestionNumber={questions.length}
        canGoToNextQuestion={
          answers[questions[currentQuestionIndex]?.questionId] !== undefined
        }
        quizProgressState={quizProgressState}
        onNext={onStepNext}
        onPrev={onStepPrev}
        {...interactiveProps}
        mt={bp(0, 0)}
        mb={bp("10px", 0)}
      />

      <PseudoBox
        {...interactiveProps}
        mt={bp(0, "1rem")}
        w="100%"
        justifyContent="center"
        flex="1"
      >
        {questions.map((question, index) => {
          const questionProps = {
            ...question,
            currentQuestionId: question.questionId,
            currentQuestion: currentQuestionIndex === index,
            onAnswer: (answerId: string | Array<string>) => {
              dispatch.quiz.answerQuestion({
                questionId: question.questionId,
                answerId,
              })
              dispatch.quiz.nextQuestion()
              question.onAnswer?.(answerId)
            },
          }

          if (question.questionId === "email") {
            return (
              <EmailQuestion
                key={`question-${question.questionId}`}
                defaultValue={defaultEmail}
                {...questionProps}
              />
            )
          }
          if (question.questionId === "phone") {
            return (
              <PhoneQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          if (
            question.questionId === "height" ||
            question.questionId === "weight"
          ) {
            return (
              <NumberQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          if (question.questionId === "gender") {
            return (
              <GenderQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          return (
            <Question
              isPopup={isPopup}
              key={`question-${question.questionId}`}
              {...questionProps}
            />
          )
        })}
      </PseudoBox>
      <Box mt="0.75rem" w="100%" maxW="600px">
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt="1rem"
        >
          {confirmExit.isOpen && (
            <>
              <Body
                mb="1rem"
                color="#fff"
                width="100%"
                textAlign="center"
                fontSize="1.3rem"
              >
                Are you sure you want to exit?
              </Body>
              <Flex
                flexDir={bp("column", "row")}
                width="100%"
                maxW={bp("60%", "70%")}
              >
                <Button
                  className="QuizOutlineBtn"
                  flexGrow={1}
                  onClick={() => {
                    confirmExit.onClose()
                    onExit?.()
                  }}
                  boxShadow="unset"
                  mr={bp(0, "0.5rem")}
                >
                  Yes
                </Button>
                <Button
                  flexGrow={1}
                  theme="secondary"
                  onClick={confirmExit.onClose}
                  mt={bp("15px", 0)}
                >
                  No
                </Button>
              </Flex>
            </>
          )}
          {!confirmExit.isOpen && (
            <Button
              className="BodyFitQuizExitBtn"
              w="100%"
              maxW={isPopup ? "150px" : "200px"}
              theme="tertiary"
              color="#fff"
              mt="0.75rem"
              mx="auto"
              onClick={confirmExit.onOpen}
            >
              Exit
            </Button>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default QuizQuestions
