import useFetch from "use-http"
import { decodeShopifyId } from "."

export type PreorderInfoSettings = {
  config?: {
    buttonMessage?: string
    buttonText?: string
    closedPreOrderMessage?: string
    preOrderLabel?: string
    quantityLimit?: number
  }
}

export type PreorderInfoResponse = {
  success?: boolean
  data?: {
    config?: {
      checkoutWarning?: string
      partiallyPreOrderWarning?: string
      preOrderLimitWarning?: string
    }
    defaultSetting?: PreorderInfoSettings
    products?: Record<string, PreorderInfoSettings>
    variants?: Record<string, PreorderInfoSettings>
  }
}

export const useFetchPreorderInfo = (dependencies: string[] = []) =>
  useFetch<PreorderInfoResponse>(
    "/.netlify/functions/preorderInfo",
    dependencies
  )

export function getVariantPreorderInfo(
  response?: PreorderInfoResponse,
  shopifyId?: string | null
) {
  return (shopifyId
    ? response?.data?.variants?.[decodeShopifyId(shopifyId)!]
    : null) as PreorderInfoSettings | null
}

export function getPreorderLabel(
  data?: PreorderInfoResponse["data"],
  shopifyId?: string | null,
  productShopifyId?: string | null,
  decodeId = true
) {
  const id = (decodeId ? decodeShopifyId(shopifyId) : shopifyId) || ""
  const productId = decodeShopifyId(productShopifyId) || ""
  return (
    data?.variants?.[id]?.config?.preOrderLabel ||
    data?.products?.[productId]?.config?.preOrderLabel ||
    data?.defaultSetting?.config?.preOrderLabel ||
    "Pre-orders will ship at a later date"
  )
}
