import { useEffect, useState } from "react"

export default function useLoadOnInteraction() {
  const [load, setLoad] = useState(false)

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setLoad(true)
      },
      { once: true, passive: true }
    )
    window.addEventListener(
      "click",
      () => {
        setLoad(true)
      },
      { once: true, passive: true }
    )
  }, [])

  return load
}
