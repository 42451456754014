import React from "react"
import { Box, BoxProps, Button, Icon } from "@chakra-ui/core"

type FacebookSignInButtonProps = BoxProps & {
  onSuccess?: (token: string) => void
}

export default function FacebookSignInButton({
  onSuccess,
  ...props
}: FacebookSignInButtonProps) {
  const onClick = () => {
    // @ts-ignore
    window.FB.login(
      function (response) {
        onSuccess?.(response?.authResponse?.accessToken)
      },
      { scope: "email" }
    )
  }
  return (
    <Box {...props}>
      <Button
        variant="ghost"
        onClick={onClick}
        width="auto"
        height="auto"
        p="0.5rem"
        borderRadius="1000px"
      >
        <Icon
          name="facebookCircle"
          color="dusk"
          width="2.0875rem"
          height="2.0875rem"
        />
      </Button>
    </Box>
  )
}
