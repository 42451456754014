import { Box, Flex, useDisclosure } from "@chakra-ui/core"
import { graphql, navigate, StaticQuery } from "gatsby"
import React, { useRef, useState, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import QuizHeader from "../../components/Header/QuizHeader"
import Footer from "../Footer"
import {
  ConnectedHeaderProps,
  DropDownColumnProps,
  DropDownLinksProps,
  LinkProps,
} from "../Header"
import Header from "../Header/connected"
import Sidebar from "../SideBar"

import { LayoutQuery } from "../../../graphql-types"
import useGoogleAnalytics from "../../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import { HEADER_HEIGHT_REM } from "../../constants/measurements"
import useLayoutMeasurements from "../../hooks/useLayoutMeasurements"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { BikeType } from "../../redux/types/BikeTypes"
import { Branding } from "../../redux/types/BrandingTypes"
import {
  IncludeByAnswer,
  Question,
  RiderStyleType,
} from "../../redux/types/QuizTypes"
import "../../styles/css/layout.css"
import { isValidUserLoggedIn } from "../../utils/auth"
import { bp, LaptopDownOnly } from "../../utils/MediaQueries"
import { getDynamicRedirect } from "../../utils/redirects"
import ContentfulRichText from "../ContentfulRichText"
import InfoBanner from "../InfoBanner"
import BodyFitQuizFullScreen from "../BodyFitQuizFullScreen"

const ContentContainer: React.FC<{
  animate?: boolean
  hasBodyFitFullScreenLayout?: boolean
}> = ({ children, animate = false, hasBodyFitFullScreenLayout = false }) => {
  // const isLaptopUp = useLaptopUpQuery()
  const {
    contentWidthDesktop,
    // contentWidthMobile,
    contentWidthResponsive,
  } = useLayoutMeasurements()

  return (
    <Box
      position="relative"
      backgroundColor="background"
      width={hasBodyFitFullScreenLayout ? "100vw" : contentWidthResponsive}
    >
      {children}
    </Box>
  )
  // return animate ? (
  //   <MotionBox
  //     position="relative"
  //     backgroundColor="background"
  //     width={!animate ? contentWidthResponsive : undefined}
  //     animate={{
  //       width: isLaptopUp ? contentWidthDesktop : contentWidthMobile,
  //     }}
  //     transition={{
  //       ease: animations.bezierFramer,
  //     }}
  //   >
  //     {children}
  //   </MotionBox>
  // ) : (
  //   <Box
  //     position="relative"
  //     backgroundColor="background"
  //     width={contentWidthResponsive}
  //   >
  //     {children}
  //   </Box>
  // )
}

const Layout = ({ children, pageContext, location, path, ...props }) => {
  // Dynamic Redirects
  const dynamicRedirect = getDynamicRedirect(location)
  if (dynamicRedirect.isRedirect) {
    // Redirect for shopify orders page
    window.location.href = dynamicRedirect.href
    return null
  }

  // Mobile Quiz drawer
  const { isOpen, onClose, onOpen } = useDisclosure()
  // Redux
  const dispatch = useDispatch<BaseDispatch>()
  const {
    quiz: {
      currentQuestionIndex,
      questions,
      quizProgressState,
      previousQuizAnswers,
      answers,
      currentPreviousAnswersDate,
    },
    bikes: { bikeResults, customizerBike },
    sidebar: { isOpen: sidebarOpen, animate: animateContent },
    user: { accessToken, tokenExpiration },
    branding: { branding },
  } = useSelector((state: BaseRootState) => state)

  React.useEffect(() => {
    if (currentPreviousAnswersDate === null && previousQuizAnswers.length > 0) {
      dispatch.quiz.setCurrentPreviousAnswersDate(previousQuizAnswers[0].date)
    }
  }, [])

  React.useEffect(() => {
    if (previousQuizAnswers.length > 0) {
      dispatch.quiz.setProgressState("previous")
    }
  }, [])

  // Get header height
  const headerRef: any = useRef(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useLayoutEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.getBoundingClientRect().height
      setHeaderHeight(height)
    }
  }, [headerRef])

  return (
    <StaticQuery
      query={graphql`
        query Layout {
          bicycles: allContentfulBicycleModel(
            filter: { hidden: { ne: true } }
          ) {
            edges {
              node {
                id: contentful_id
                title
                type
                formattedTitle
                internalTitle
                gender
                style
                terrain
                bodyFitScoring {
                  answerId: contentfulid
                  answer
                  question: body_fit_quiz_question {
                    questionId
                  }
                }
                bodyFitMinHeight
                bodyFitMaxWeight
                bodyFitMaxHeight
                enableFreeShippingMessage
                enable365DayTestRideMessage
                enable90DayTestRideMessage
                enableNationwideServiceMessage
                enableWarrantyMessage
                disableWarranty
                speeds {
                  contentful_id
                  internalTitle
                  speed
                  armLength
                  legLength
                  primaryUse
                  rideFrequency
                  ridePreference
                  ridingPace
                  ridingSurface
                  seatPreference
                  uphillFrequency
                  bodyPain
                  maxDistance
                  minDistance
                  variants {
                    contentful_id
                    shopifyId
                    product {
                      internalTitle
                    }
                    productListingImage {
                      fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                    color {
                      internalTitle
                      name
                      hexCode
                    }
                    isNew
                    forSale
                  }
                }
              }
            }
          }
          accessories: allContentfulAccessoryModel(
            filter: { hidden: { ne: true } }
          ) {
            edges {
              node {
                internalTitle
                title
                type
                variants {
                  contentful_id
                  shopifyId
                  productListingImage {
                    fluid(maxWidth: 1000, quality: 85) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
                  color {
                    name
                    hexCode
                  }
                }
              }
            }
          }
          collections: allContentfulCollection(
            filter: { hidden: { ne: true } }
          ) {
            edges {
              node {
                title
                internalTitle
              }
            }
          }
          globalConfig: contentfulGlobalConfiguration {
            enableInfoBanner
            infoBannerSlideDuration
            infoBannerBackgroundColor
            infoBannerTextColor
            infoSlides {
              content {
                raw
              }
            }
            bodyFitQuizQuestions {
              answers {
                answerText: answer
                answerId: contentfulid
                contentful_id: contentful_id
                riderType
                filterBikes
              }
              contentful_id
              includeByAnswer {
                ... on ContentfulBodyFitQuizAnswer {
                  type: __typename
                  answerId: contentful_id
                  answer
                }
                ... on ContentfulBodyFitQuizHeightRange {
                  type: __typename
                  answerId: contentful_id
                  minHeight
                  maxHeight
                }
                ... on ContentfulBodyFitQuizWeightRange {
                  type: __typename
                  answerId: contentful_id
                  minWeight
                  maxWeight
                }
              }
              questionId
              shortTitle
              question
              weight
            }
            navigationBarAccessoryAddOn {
              accessoryTypes
              internalTitle
              title
            }
            navigationBarAccessoryParts {
              accessoryTypes
              internalTitle
              title
            }
            navigationBarAccessoryOther {
              accessoryTypes
              internalTitle
              title
            }
            electrifiedBrandingKeywords
            electrifiedQuizText {
              electrifiedQuizText
            }
          }
          navigation: contentfulNavigationMenu {
            title
            navigationHeader {
              accessories {
                title
                navButtonLink
                columns {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
              }
              bikeAdvice {
                title
                navButtonLink
                columns {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
              }
              bikes {
                title
                navButtonLink
                genderList {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
                categoryList {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
              }
              electricBikes {
                title
                navButtonLink
                genderList {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
                categoryList {
                  title
                  titleLink
                  links {
                    name
                    slug
                  }
                }
              }
            }
          }
          warranty: contentfulAccessoryModel(
            internalTitle: { eq: "forever-warranty" }
          ) {
            variants {
              contentful_id
              title
              sku
              shopifyId
              accessory_model {
                contentful_id
                internalTitle
              }
            }
          }
        }
      `}
      render={({
        bicycles,
        accessories,
        collections,
        globalConfig,
        warranty,
        navigation,
      }: LayoutQuery) => {
        const navData: ConnectedHeaderProps["data"] = [
          {
            title:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikes?.title) ||
              "bikes",
            text: "bikes",
            to:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikes?.navButtonLink) ||
              undefined,
            allSlug:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikes?.navButtonLink) ||
              "/collections/all-standard-bikes",
            dropDownLinks: {
              gender:
                (navigation?.navigationHeader &&
                  (navigation?.navigationHeader[0]?.bikes?.genderList
                    ?.links as LinkProps[])) ||
                ([] as LinkProps[]),
              styles:
                (navigation?.navigationHeader &&
                  (navigation?.navigationHeader[0]?.bikes?.categoryList
                    ?.links as LinkProps[])) ||
                ([] as LinkProps[]),
              lifeStyle: [],
              speed: [],
              color: [],
            } as DropDownLinksProps,
          },
          {
            title:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.electricBikes?.title) ||
              "electric bikes",
            text: "electric bikes",
            to:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.electricBikes
                  ?.navButtonLink) ||
              undefined,
            allSlug:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.electricBikes
                  ?.navButtonLink) ||
              "/collections/all-electric-bikes",
            dropDownLinks: {
              gender:
                (navigation?.navigationHeader &&
                  (navigation?.navigationHeader[0]?.electricBikes?.genderList
                    ?.links as LinkProps[])) ||
                ([] as LinkProps[]),
              styles:
                (navigation?.navigationHeader &&
                  (navigation?.navigationHeader[0]?.electricBikes?.categoryList
                    ?.links as LinkProps[])) ||
                ([] as LinkProps[]),
              lifeStyle: [],
              speed: [],
              color: [],
            } as DropDownLinksProps,
          },
          {
            title:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.accessories?.title) ||
              "accessories",
            text: "accessories",
            to:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.accessories?.navButtonLink) ||
              undefined,
            dropDownColumns:
              ((navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.accessories
                  ?.columns) as Array<DropDownColumnProps>) ||
              ([] as Array<DropDownColumnProps>),
          },
          {
            title:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikeAdvice?.title) ||
              "bike advice",
            text: "bike advice",
            to:
              (navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikeAdvice?.navButtonLink) ||
              undefined,
            dropDownColumns:
              ((navigation?.navigationHeader &&
                navigation?.navigationHeader[0]?.bikeAdvice
                  ?.columns) as Array<DropDownColumnProps>) ||
              ([] as Array<DropDownColumnProps>),
          },
        ]

        const quizIntroText =
          branding === Branding.electrified
            ? globalConfig?.electrifiedQuizText?.electrifiedQuizText ??
              undefined
            : undefined

        React.useEffect(() => {
          dispatch.bikes.setBikes(
            bicycles.edges.map<BikeType>(({ node }) => ({
              ...node,
              quizTags:
                node?.bodyFitScoring?.map((pair) => {
                  return {
                    answerId: pair?.answerId || "",
                    questionId: pair?.question?.[0]?.questionId || "",
                  }
                }) ?? [],
              quizFilters: {
                maxHeight: node.bodyFitMaxHeight || undefined,
                minHeight: node.bodyFitMinHeight || undefined,
                maxWeight: node.bodyFitMaxWeight || undefined,
              },
            }))
          )
          dispatch.bikes.setAccessories(accessories.edges)
          dispatch.bikes.setCollections(collections.edges)

          dispatch.bikes.setWarranty(
            warranty?.variants?.find(
              (variant) => variant?.title === "sixthreezero"
            )
          )
        }, [])

        // Initialize Quiz Questions
        React.useEffect(() => {
          const preliminaryQuestions: Question[] = [
            {
              questionId: "email",
              questionText: "Enter your email so we can send you your results",
            },
            {
              questionId: "phone",
              questionText: "Enter your contact number",
            },
          ]
          let contentfulAnswers = []
          const transformedQuestions: Question[] =
            globalConfig?.bodyFitQuizQuestions?.map((q) => {
              return {
                questionText: q?.question ?? "",
                questionId: q?.questionId ?? "",
                options: (q?.answers ?? []).map((ans) => {
                  if(ans?.contentful_id){
                    contentfulAnswers[ans.contentful_id] = ans?.answerId
                  }
                  return ({
                    ...ans,
                    riderType: (ans?.riderType ?? []) as Array<RiderStyleType>,
                    answerId: ans?.answerId ?? "",
                    answerText: ans?.answerText ?? "",
                    filterBikes: !!ans?.filterBikes,
                    type: "option",
                  })
                }),
                weight: q?.weight ?? 0,
                includeByAnswer:
                  q?.includeByAnswer?.reduce((acc, answer) => {
                    const id = answer?.answerId
                    if (id) {
                      let range: { min: number; max: number } | null = null
                      switch (answer?.type) {
                        case "ContentfulBodyFitQuizHeightRange":
                          range = {
                            min: answer?.minHeight ?? 0,
                            max: answer?.maxHeight ?? Infinity,
                          }
                          break
                        case "ContentfulBodyFitQuizWeightRange":
                          range = {
                            min: answer?.minWeight ?? 0,
                            max: answer?.maxWeight ?? Infinity,
                          }
                          break
                      }
                      const contentfulID = answer?.answerId && contentfulAnswers[answer?.answerId] ? contentfulAnswers[answer?.answerId] : answer?.answerId
                      return {
                        ...acc,
                        [id]: {
                          answerType: answer?.type!,
                          answerId: contentfulID,
                          range,
                        } as IncludeByAnswer,
                      }
                    }
                    return acc
                  }, {}) ?? {},
                shortTitle: q?.shortTitle ?? "",
              }
            }) || []
          dispatch.quiz.setQuestions([
            ...transformedQuestions,
            ...preliminaryQuestions,
          ])
        }, [])

        const isQuizInProgress =
          quizProgressState === "inprogress" ||
          quizProgressState === "paused" ||
          answers.date !== ""

        const googleAnalytics = useGoogleAnalytics({
          category: "Body Fit",
          action: "click",
          shouldFireOnFirstRender: false,
        })

        // Handle branding
        React.useEffect(() => {
          if (
            globalConfig?.electrifiedBrandingKeywords?.some((keyword) =>
              location.pathname.includes(keyword)
            )
          ) {
            dispatch.branding.setElectrified()
          } else {
            dispatch.branding.setSixthreezero()
          }
        }, [location.pathname])

        const layout = useLayoutMeasurements()

        var desktopBannerMarginTop = "unset"
        var mobileBannerMarginTop = "3.5rem"
        var desktopBannerPosition = "fixed"

        if (path?.split("/")[1] == "products") {
          desktopBannerMarginTop = "4rem"
          mobileBannerMarginTop = "6.5rem"
          desktopBannerPosition = "unset"
        }

        let hasBodyFitFullScreenLayout = false

        if (pageContext && pageContext.layoutData) {
          if (
            Object.keys(pageContext.layoutData).length &&
            pageContext.layoutData.layout?.some(
              (layout) => layout?.layoutId === "bodyfit-fullpage"
            )
          ) {
            hasBodyFitFullScreenLayout = true
          } else {
            hasBodyFitFullScreenLayout = false
          }
        }

        // console.log({ hasBodyFitFullScreenLayout })

        return (
          <Flex overflow="hidden" flexDirection="column">
            {/* Global Overlay */}
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100vw"
              height="100vh"
              backgroundColor="globalOverlay"
              pointerEvents="none"
              zIndex={999999999}
            />
            <ContentContainer
              animate={animateContent}
              hasBodyFitFullScreenLayout={hasBodyFitFullScreenLayout}
            >
              {!pageContext.hideLayout && (
                <Header
                  isConnected
                  data={navData}
                  path={path}
                  hasBodyFitFullScreenLayout={hasBodyFitFullScreenLayout}
                  ref={headerRef}
                />
              )}
              {!pageContext.hideLayout &&
                !hasBodyFitFullScreenLayout &&
                path?.split("/")[1] !== "products" && (
                  <LaptopDownOnly>
                    <Box h="3.5rem" width="100%" />
                    <QuizHeader
                      onGetFitted={() => {
                        onOpen()
                        googleAnalytics.fireEvent()
                        if (answers.date === "") {
                          dispatch.quiz.setProgressState("initial")
                        } else {
                          dispatch.quiz.setProgressState("inprogress")
                        }
                      }}
                      onViewResults={() => {
                        onOpen()
                        dispatch.quiz.setProgressState("previous")
                      }}
                      onContinue={() => {
                        if (
                          quizProgressState === "finished" ||
                          quizProgressState === "previous"
                        ) {
                          if (customizerBike !== null) {
                            navigate(
                              `/customizer/${customizerBike?.bike?.internalTitle}`
                            )
                          } else {
                            if (quizProgressState === "previous") {
                              dispatch.quiz.loadPrevious()
                            }
                            if (bikeResults === null) {
                              dispatch.bikes.calculateResults()
                            }
                            dispatch.bikes.setProgressState("initial")
                            navigate("/quiz-results", {
                              state: { previousPath: path },
                            })
                          }
                        } else if (isQuizInProgress) {
                          onOpen()
                        }
                      }}
                      isQuizInProgress={isQuizInProgress}
                      isHomeScreen={location.pathname === "/"}
                      hasPreviousResults={previousQuizAnswers.length > 0}
                      position={bp("fixed", "unset")}
                      top={bp("3.5rem", `${HEADER_HEIGHT_REM}rem`)}
                      zIndex={1003}
                      w="100%"
                    />
                  </LaptopDownOnly>
                )}
              <Box pt={bp(0, `${HEADER_HEIGHT_REM}rem`)}>
                {globalConfig?.enableInfoBanner && !hasBodyFitFullScreenLayout && (
                  <InfoBanner
                    slides={(globalConfig?.infoSlides ?? []).map((slide) => ({
                      content: slide?.content
                        ? ContentfulRichText({ richText: slide.content }) || ""
                        : "",
                    }))}
                    slideDuration={
                      (globalConfig?.infoBannerSlideDuration || 5) * 1000
                    }
                    textColor={globalConfig?.infoBannerTextColor || "white"}
                    backgroundColor={
                      globalConfig?.infoBannerBackgroundColor || "primary"
                    }
                    className="InfoBannerSlider"
                    w={bp(
                      "unset",
                      hasBodyFitFullScreenLayout
                        ? "100vw"
                        : layout.contentWidthDesktop
                    )}
                    position={bp("unset", desktopBannerPosition)}
                    top="3.5rem"
                    zIndex={988}
                    mt={bp("80px", "unset")}
                  />
                )}

                {hasBodyFitFullScreenLayout ? (
                  <BodyFitQuizFullScreen
                    locationState={location?.state}
                    currentPagePath={path}
                    isSidebarOpen={sidebarOpen}
                    toggleSidebar={async () => {
                      await dispatch.sidebar.setAnimate(true)
                      dispatch.sidebar.toggleOpen()
                    }}
                    isMobileQuizDrawerOpen={isOpen}
                    onClose={() => {
                      if (quizProgressState === "inprogress") {
                        dispatch.quiz.setProgressState("paused")
                      }
                      onClose()
                    }}
                    onOpen={onOpen}
                    currentQuestionNumber={currentQuestionIndex + 1}
                    totalQuestionNumber={questions.length}
                    canGoToNextQuestion={
                      answers[questions[currentQuestionIndex]?.questionId] !==
                      undefined
                    }
                    onNextQuestion={() => {
                      if (
                        answers[questions[currentQuestionIndex].questionId] !==
                        undefined
                      ) {
                        dispatch.quiz.nextQuestion()
                      }
                    }}
                    onPrevQuestion={dispatch.quiz.prevQuestion}
                    quizIntroText={quizIntroText}
                  />
                ) : (
                  // Render other layouts
                  children
                )}
              </Box>
              {!pageContext.hideLayout && (
                <Footer
                  isLoggedIn={isValidUserLoggedIn({
                    accessToken,
                    tokenExpiration,
                  })}
                />
              )}
            </ContentContainer>

            {!hasBodyFitFullScreenLayout && (
              <Sidebar
                locationState={location?.state}
                currentPagePath={path}
                isSidebarOpen={sidebarOpen}
                toggleSidebar={async () => {
                  await dispatch.sidebar.setAnimate(true)
                  dispatch.sidebar.toggleOpen()
                }}
                isMobileQuizDrawerOpen={isOpen}
                onClose={() => {
                  if (quizProgressState === "inprogress") {
                    dispatch.quiz.setProgressState("paused")
                  }
                  onClose()
                }}
                onOpen={onOpen}
                currentQuestionNumber={currentQuestionIndex + 1}
                totalQuestionNumber={questions.length}
                canGoToNextQuestion={
                  answers[questions[currentQuestionIndex]?.questionId] !==
                  undefined
                }
                onNextQuestion={() => {
                  if (
                    answers[questions[currentQuestionIndex].questionId] !==
                    undefined
                  ) {
                    dispatch.quiz.nextQuestion()
                  }
                }}
                onPrevQuestion={dispatch.quiz.prevQuestion}
                quizIntroText={quizIntroText}
              />
            )}
          </Flex>
        )
      }}
    />
  )
}

export default Layout
