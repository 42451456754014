import React, { useState } from "react"
import {
  Flex,
  Text,
  Drawer,
  DrawerContent,
  Accordion,
  DrawerOverlay,
} from "@chakra-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import omit from "lodash/omit"
import isNil from "lodash/isNil"
import axios from "axios"

import MobileHeader from "./MobileHeader"
import QuizQuestions from "../Quiz/QuizQuestions"
import SidebarContainer from "./SidebarContainer"
import { BaseRootState, BaseDispatch } from "../../redux/store"
import InitialQuizScreen from "../Quiz/InitialQuizScreen"
import { MobileQuestionStepper } from "../Quiz/QuestionStepper"
import PreviousResults from "../../components/Quiz/PreviousResults"
import EditAnswers from "../../components/Quiz/EditAnswers"
import Body from "../../components/typography/Body"
import DropDownSelector from "../../components/DropDownSelector"
import CustomizerOptions from "./CustomizerOptions"
import FitButton from "./FitButton"
import SidebarToggle from "./SidebarToggle"
import CustomizerSelectedBike from "./CustomizerSelectedBike"
import QuizResults from "../Quiz/QuizResults"
import CustomizerSidebar from "./CustomizerSidebar"
import ClientOnly from "../ClientOnly"
// Styles
import "../SideBar/styles/css/BodyFitSidebarDesktop.css"

import * as utils from "./utils"
import { LaptopDownOnly } from "../../utils/MediaQueries"
import { SIDEBAR_WIDTH_OPEN_REM } from "../../constants/measurements"
import useLoginDrawer from "../../context/loginDrawer"
import { isValidUserLoggedIn } from "../../utils/auth"
import config from "../../config"
import { subscribeNewsletter } from "../../api/subscribeNewsletter"
import { prepareKlaviyoResults } from "../../utils/bodyFit"

interface MobileQuizDrawerProps {
  isMobileQuizDrawerOpen: boolean
  currentQuestionNumber: number
  totalQuestionNumber: number
  canGoToNextQuestion: boolean
  onOpen: VoidFunction
  onClose: VoidFunction
  onNextQuestion: VoidFunction
  onPrevQuestion: VoidFunction
  locationState?: {
    fromQuizResults: {}
  }
  quizIntroText?: string
}

const SideBar: React.FC<
  {
    isSidebarOpen: boolean
    toggleSidebar: VoidFunction
    currentPagePath: string
  } & MobileQuizDrawerProps
> = ({
  isSidebarOpen,
  toggleSidebar,
  isMobileQuizDrawerOpen,
  onClose,
  onOpen,
  onNextQuestion,
  onPrevQuestion,
  currentQuestionNumber,
  totalQuestionNumber,
  canGoToNextQuestion,
  currentPagePath,
  locationState,
  quizIntroText,
}) => {
  const {
    quiz: {
      quizProgressState,
      previousQuizAnswers,
      answers,
      questions,
      currentPreviousAnswersDate,
      discount,
    },
    bikes: { selectedBike, bikeResults },
    sidebar: { sideBarStep },
    user: { email, accessToken, tokenExpiration, ...user },
  } = useSelector((state: BaseRootState) => state)
  const dispatch = useDispatch<BaseDispatch>()
  const [quizDropDownOptions, setQuizDropDownOptions] = React.useState<
    Array<{ title: string; name: string; selected: boolean }>
  >([])
  const [isSubmittingResults, setSubmittingResults] = useState(false)
  const { openLoginDrawer } = useLoginDrawer()
  const isBikeSelectPage = currentPagePath === "/quiz-results"
  const isCustomizerPage = currentPagePath?.startsWith("/customizer/")

  const isLoggedIn = isValidUserLoggedIn({
    accessToken,
    tokenExpiration,
  })

  const [editingQuestion, setEditingQuestions] = React.useState<boolean>(false)
  const [editedQuestions, setEditedQuestions] = React.useState({})
  const initializedPrevQuestions = React.useRef(false)

  // Sync quiz results dropdown
  React.useEffect(() => {
    setQuizDropDownOptions(
      previousQuizAnswers.map((a) => {
        const d = new Date(parseInt(a.date, 10))
        const formatedDate = utils.dateToPretty(d)
        return {
          title: formatedDate,
          name: a.date,
          selected: a.date === currentPreviousAnswersDate,
        }
      })
    )
  }, [previousQuizAnswers, currentPreviousAnswersDate])

  const [tags, setTags] = React.useState<Array<string>>(new Array(3))
  const getAnswers = (date) => {
    return (
      previousQuizAnswers.find((a) => a.date === date) ?? {
        date: "",
        riderType: "",
      }
    )
  }

  React.useEffect(() => {
    if (currentPreviousAnswersDate !== null) {
      const currentAnswers = getAnswers(currentPreviousAnswersDate)
      if (currentAnswers.date !== "") {
        const weightValue = currentAnswers?.["weight"]?.[0]
        const heightFtValue = currentAnswers?.["height"]?.[0]
        const heightInValue = currentAnswers?.["height"]?.[1]
        const weight = weightValue ? `${weightValue}lbs` : ""
        const height =
          !isNil(heightFtValue) && !isNil(heightInValue)
            ? `${heightFtValue}ft ${heightInValue}in`
            : ""
        let bikeType = ""
        switch (currentAnswers["standard-or-electric"]) {
          case "standard-or-electric-standard-bicycle":
            bikeType = "Standard"
            break
          case "standard-or-electric-electric-bicycle":
            bikeType = "Electric"
            break
          case "standard-or-electric-both":
            bikeType = "Stand/Elec"
            break
        }
        setTags([height, weight, bikeType])
      }
    }
  }, [currentPreviousAnswersDate, previousQuizAnswers])

  React.useEffect(() => {
    dispatch.quiz.setInitialDiscount()
    if (!initializedPrevQuestions.current) {
      const loadDefaultPreviousQuestions = async () => {
        initializedPrevQuestions.current = true
        if (previousQuizAnswers.length > 0) {
          if (answers.date === "") {
            const quizAnswers = currentPreviousAnswersDate
              ? previousQuizAnswers.find(
                  (answers) => answers.date === currentPreviousAnswersDate
                ) || previousQuizAnswers[0]
              : previousQuizAnswers[0]
            await dispatch.quiz.setAnswers(quizAnswers)
            dispatch.bikes.calculateResults()
          } else if (answers.riderType === "") {
            dispatch.bikes.calculateResults()
          }
        }
      }
      loadDefaultPreviousQuestions()
    }
  }, [answers, previousQuizAnswers])

  React.useEffect(() => {
    if (isBikeSelectPage) {
      dispatch.sidebar.setSideBarStep("select-bike")
    } else if (isCustomizerPage || selectedBike) {
      dispatch.sidebar.setSideBarStep("customize")
    } else {
      dispatch.sidebar.setSideBarStep("fit")
    }
  }, [isBikeSelectPage, isCustomizerPage, selectedBike])

  const startNewQuiz = () => {
    dispatch.quiz.resetQuiz()
    dispatch.quiz.setProgressState("initial")
    dispatch.quiz.setAnswers({ date: Date.now().toString(), riderType: "" })
  }
  const onExitQuiz = () => {
    initializedPrevQuestions.current = false
    dispatch.quiz.resetQuiz()
    dispatch.quiz.setProgressState("previous")
  }

  const onEditAnswers = () => {
    setEditingQuestions(true)
  }
  const onExitEditingAnswers = () => {
    setEditingQuestions(false)
  }
  const onSaveEditedAnswers = async () => {
    const newPreviousAnswers = previousQuizAnswers.map((answer) =>
      answer.date === currentPreviousAnswersDate ? answers : answer
    )
    await dispatch.quiz.setPreviousAnswers(newPreviousAnswers)
    await dispatch.quiz.setAnswers(
      newPreviousAnswers.find(
        ({ date }) => currentPreviousAnswersDate === date
      ) ?? { date: "", riderType: "" }
    )
    const updatedBikeResults: any = await dispatch.bikes.calculateResults()

    const isPreviousQuestionsLoaded =
      (currentPreviousAnswersDate && currentPreviousAnswersDate !== "") ||
      answers.date !== ""
    if (isLoggedIn && isPreviousQuestionsLoaded) {
      axios.put(`${config.FIREBASE_FUNCTIONS_URL}/quizResults`, {
        timestamp: new Date(
          currentPreviousAnswersDate && currentPreviousAnswersDate !== ""
            ? parseInt(currentPreviousAnswersDate, 10)
            : answers.date
        ).toISOString(),
        questionAnswerPairs: editedQuestions,
        shopifyToken: accessToken,
        bikeResults:updatedBikeResults && updatedBikeResults.length ? updatedBikeResults.slice(0, 15).map((bike) => bike.title).join('||') : '', // Add the bikeResults array to the request
      })
    }
  }

  const onAnsweredEdited = (
    questionId: string,
    answerId: string | Array<string>
  ) => {
    dispatch.quiz.answerQuestion({ questionId, answerId })
    setEditedQuestions({ ...editedQuestions, ...{ [questionId]: answerId } })
  }

  const onMyMatches = async () => {
    dispatch.sidebar.setSideBarStep("select-bike")
    await dispatch.quiz.setAnswers(getAnswers(currentPreviousAnswersDate))
    dispatch.bikes.calculateResults()
    dispatch.bikes.setProgressState("editing")
    onClose()
    navigate("/quiz-results")
  }

  const submitQuizResults = async () => {
    setSubmittingResults(true)
    const timestamp = new Date(
      answers.date !== "" ? parseInt(answers.date, 10) : Date.now()
    ).toISOString()
    try {
      const newAnswers = {
        ...omit(answers, ["date", "sms-consent"]),
        sentToDB: "true",
      }
      dispatch.checkout.removeCurrentDiscount()

      const updatedBikeResults: any = await dispatch.bikes.calculateResults()
      console.log(updatedBikeResults && updatedBikeResults.length
            ? updatedBikeResults?.slice(0, 15)?.map((bike) => ({
                id: bike.id,
                title: bike.title,
                type: bike.type,
                internalTitle: bike.internalTitle,
                gender: bike.gender,
                score: bike.score,
              }))
            : '');
      await axios.post(`${config.FIREBASE_FUNCTIONS_URL}/quizResults`, {
        questionAnswerPairs: newAnswers,
        shopifyToken: accessToken,
        timestamp,
        bikeResults: updatedBikeResults && updatedBikeResults.length ? updatedBikeResults.slice(0, 15).map((bike) => bike.title).join('||') : '', // Add the bikeResults array to the request
      })
      await dispatch.quiz.setAnswers({ ...answers, sentToDB: "true" })
    } catch (error) {
      await dispatch.quiz.setAnswers({ ...answers, sentToDB: "false" })
    } finally {
      await dispatch.quiz.setCurrentPreviousAnswersDate(answers.date)
      await dispatch.quiz.setPreviousAnswers([
        ...(previousQuizAnswers || []),
        answers,
      ])
      onClose()

      // Klaviyo Quiz submit
      const bikeResults = await dispatch.bikes.calculateResults()
      try {
        const email = answers["email"] as string
        const phoneOptIn = answers["sms-consent"] === "yes"
        const phone = answers["phone"] as string
        if (email) {
          const data = {
            listId: config.GATSBY_KLAVIYO_QUIZ_LIST_ID,
            email: email,
            ...(await prepareKlaviyoResults({
              bikes: bikeResults as any,
              name: user.name || undefined,
              phone: phone || undefined,
            })),
            sms_consent: phoneOptIn,
          }
          await subscribeNewsletter(data)
        }
      } catch (e) {
        console.error(e.message)
      }
    }

    try {
      const unixTimestamp = new Date(timestamp).getTime()

      const result = await axios.post(`${config.FIREBASE_FUNCTIONS_URL}/createDiscount`, {
        code: "QUIZ" + unixTimestamp,
        discount: discount,
        startDate: timestamp,
        endDate: new Date(unixTimestamp + 24 * 60 * 60 * 1000)
      })
    } catch (e) {
      console.log(e)
    }
    setSubmittingResults(false)
  }

  const onPreviousQuizOptionSelected = async (date: string) => {
    await dispatch.quiz.setCurrentPreviousAnswersDate(date)
    await dispatch.quiz.setAnswers(getAnswers(date))
    dispatch.bikes.calculateResults()
  }

  const onSeeResults = () =>
    submitQuizResults().then(() => {
      dispatch.sidebar.setSideBarStep("select-bike")
      navigate("/quiz-results", {
        state: { previousPath: currentPagePath },
      })
    })

  const showPreviousResults =
    quizProgressState === "previous" && previousQuizAnswers.length > 0
  const showQuizQuestions =
    quizProgressState !== "initial" &&
    quizProgressState !== "previous" &&
    quizProgressState !== "finished"
  const showResults = quizProgressState === "finished"
  const showInitialQuiz =
    quizProgressState === "initial" ||
    (!showPreviousResults && !showQuizQuestions && !showResults) // show something if nothing is available

  return (
    <ClientOnly>
      <Flex
        className="BodyFitQuizWrapper"
        d={["none", null, null, null, "flex"]}
        pos="fixed"
        top={0}
        right={0}
        zIndex={1001}
        cursor={!isSidebarOpen ? "pointer" : "default"}
        onClick={() => {
          if (!isSidebarOpen) {
            toggleSidebar()
          }
        }}
      >
        {(!isCustomizerPage || !isSidebarOpen) && (
          <SidebarToggle
            className="bodyFitToggleBtnDesktop"
            isOpen={isSidebarOpen}
            onClick={isSidebarOpen ? toggleSidebar : undefined}
          />
        )}
        <FitButton d={isSidebarOpen ? "none" : "flex"} />
        <Flex
          className="BodyFitQuizSidebar"
          flexDir="column"
          height="100vh"
          display={isSidebarOpen ? "flex" : "none"}
          width={`${SIDEBAR_WIDTH_OPEN_REM}rem`}
          justify="flex-start"
          transformOrigin="right"
          overflow="hidden"
          borderLeftWidth="1px"
          borderColor="dividerLine"
          zIndex={1}
        >
          {isCustomizerPage && !locationState?.fromQuizResults ? (
            <CustomizerSidebar />
          ) : (
            <Accordion
              display="flex"
              flexDirection="column"
              h="100vh"
              overflowY="auto"
            >
              <SidebarContainer
                position={1}
                title="Fit"
                active={sideBarStep === "fit"}
                onHeaderClick={() => {
                  dispatch.sidebar.setSideBarStep("fit")
                  if (quizProgressState === "finished") {
                    dispatch.quiz.setProgressState("previous")
                  }
                }}
                rightComponent={
                  sideBarStep === "fit" && showPreviousResults ? (
                    <DropDownSelector
                      options={quizDropDownOptions}
                      dropDownType="single_top"
                      color="#fff"
                      textTransform="uppercase"
                      itemProps={{
                        textTransform: "uppercase",
                      }}
                      showCheckMarks={false}
                      onOptionSelected={onPreviousQuizOptionSelected}
                    />
                  ) : undefined
                }
              >
                <Drawer
                  isOpen={editingQuestion}
                  placement="right"
                  size="md"
                  onClose={onExitEditingAnswers}
                >
                  <DrawerOverlay />
                  <DrawerContent bg="none" boxShadow="none" zIndex={1000001}>
                    <Flex
                      className="QuizEditAnswerSection"
                      h="100%"
                      borderLeft="1px solid"
                      borderColor="dividerLine"
                    >
                      <EditAnswers
                        onExit={onExitEditingAnswers}
                        onSave={onSaveEditedAnswers}
                        onAnswerEdit={onAnsweredEdited}
                        formatedDate={
                          currentPreviousAnswersDate
                            ? utils.dateToPretty(
                                new Date(
                                  parseInt(currentPreviousAnswersDate ?? "", 10)
                                ),
                                false,
                                true
                              )
                            : ""
                        }
                        questions={questions.map((q) => {
                          let answer = answers[q.questionId]
                          return {
                            ...q,
                            answer,
                          }
                        })}
                        answers={answers}
                      />
                    </Flex>
                  </DrawerContent>
                </Drawer>
                {showPreviousResults && (
                  <PreviousResults
                    riderStyle={
                      answers.riderType !== "" ? answers.riderType : null
                    }
                    tags={tags}
                    nextText="My Matches"
                    onNext={onMyMatches}
                    onNewQuiz={startNewQuiz}
                    onEditAnswers={onEditAnswers}
                  />
                )}
                {showInitialQuiz && (
                  <InitialQuizScreen quizIntroText={quizIntroText} />
                )}
                {showQuizQuestions && (
                  <QuizQuestions
                    defaultEmail={email || undefined}
                    onExit={onExitQuiz}
                  />
                )}
                {showResults && (
                  <QuizResults
                    isLoggedIn={isLoggedIn}
                    isSubmittingResults={isSubmittingResults}
                    onLogin={openLoginDrawer}
                    onSeeResults={onSeeResults}
                  />
                )}
              </SidebarContainer>
              <SidebarContainer
                position={2}
                title="Select Bike"
                active={sideBarStep === "select-bike"}
                disabled={
                  (quizProgressState !== "previous" ||
                    previousQuizAnswers.length < 1) &&
                  !bikeResults?.length
                }
                onHeaderClick={() => {
                  dispatch.sidebar.setSideBarStep("select-bike")
                  navigate("/quiz-results")
                }}
              >
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                >
                  <Body color="#fff" fontWeight="bold" mb="1rem">
                    Choose Your Bike
                  </Body>
                  <Body color="#fff">
                    Our algorithm has selected your top matches based on your body and riding habits
                  </Body>
                </Flex>
              </SidebarContainer>
              <SidebarContainer
                position={3}
                title="Customizer"
                active={sideBarStep === "customize"}
                disabled={!selectedBike}
                onHeaderClick={() => {
                  if (selectedBike) {
                    dispatch.sidebar.setSideBarStep("customize")
                  }
                }}
              >
                {isCustomizerPage ? (
                  <CustomizerOptions />
                ) : (
                  <CustomizerSelectedBike
                    bike={selectedBike!}
                    onStartOver={() => dispatch.sidebar.setSideBarStep("fit")}
                  />
                )}
              </SidebarContainer>
            </Accordion>
          )}
        </Flex>
      </Flex>
      {/* Mobile Drawer */}
      <LaptopDownOnly>
        <Drawer
          isOpen={isMobileQuizDrawerOpen}
          onClose={onClose}
          placement="right"
          size="full"
        >
          <DrawerContent
            display={[null, null, null, null, "none"]}
            zIndex={1000000}
            className="QuizMobileInitScreen"
          >
            <MobileHeader state="quiz" onExit={onClose} />
            {quizProgressState !== "previous" && (
              <div className="QuizMobileStepper">
                <MobileQuestionStepper
                  currentQuestionNumber={
                    quizProgressState === "initial"
                      ? 0
                      : currentQuestionNumber > totalQuestionNumber
                      ? totalQuestionNumber
                      : currentQuestionNumber
                  }
                  totalQuestionNumber={totalQuestionNumber}
                  canGoToNextQuestion={canGoToNextQuestion}
                  onNext={onNextQuestion}
                  onPrev={() => {
                    if (
                      quizProgressState === "finished" &&
                      currentQuestionNumber > totalQuestionNumber
                    ) {
                      dispatch.quiz.setProgressState("inprogress")
                      dispatch.bikes.setProgressState("quiz")
                    }
                    onPrevQuestion()
                  }}
                />
              </div>
            )}
            {showPreviousResults && (
              <PreviousResults
                riderStyle={answers.riderType !== "" ? answers.riderType : null}
                tags={tags}
                nextText="My Matches"
                onNext={onMyMatches}
                onNewQuiz={startNewQuiz}
                onEditAnswers={onEditAnswers}
                header={
                  quizProgressState === "previous" &&
                  previousQuizAnswers.length > 0 ? (
                    <DropDownSelector
                      options={quizDropDownOptions}
                      dropDownType="single_top"
                      color="primary"
                      showCheckMarks={false}
                      onOptionSelected={onPreviousQuizOptionSelected}
                    />
                  ) : undefined
                }
              />
            )}
            {showInitialQuiz && (
              <Flex
                position="absolute"
                bottom={0}
                left={0}
                zIndex={1000}
                width="100%"
                height={["55%", null, null, null, "calc(100vh - 6.75rem)"]}
                transform={["translateY(-50%)", null, null, null, "none"]}
              >
                <InitialQuizScreen quizIntroText={quizIntroText} />
              </Flex>
            )}
            {showQuizQuestions && <QuizQuestions />}
            {showResults && (
              <QuizResults
                isLoggedIn={isLoggedIn}
                isSubmittingResults={isSubmittingResults}
                onLogin={openLoginDrawer}
                onSeeResults={onSeeResults}
              />
            )}
          </DrawerContent>
        </Drawer>
      </LaptopDownOnly>
    </ClientOnly>
  )
}

export default SideBar
