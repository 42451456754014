import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"

import { BaseDispatch, BaseRootState } from "../../redux/store"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import useLoginDrawer from "../../context/loginDrawer"
import { isValidUserLoggedIn } from "../../utils/auth"
import QuizButton from "./QuizButton"
import useGoogleAnalytics from "../../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import ArrowDownIcon from "./ArrowDownIcon"

type InitialQuizScreenProps = {
  quizIntroText?: React.ReactNode
}

const InitialQuizScreen: React.FC<InitialQuizScreenProps> = ({
  quizIntroText = (
    <>
      97% of customers that use our proprietary fitting system love their bike.
    </>
  ),
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { accessToken, tokenExpiration } = useSelector(
    (state: BaseRootState) => ({
      tokenExpiration: state.user.tokenExpiration,
      accessToken: state.user.accessToken,
      quizProgressState: state.quiz.quizProgressState,
      previousQuizAnswers: state.quiz.previousQuizAnswers,
    })
  )
  const { openLoginDrawer } = useLoginDrawer()
  const isLoggedIn = isValidUserLoggedIn({ accessToken, tokenExpiration })
  // Started Quiz Event
  const googleAnalytics = useGoogleAnalytics({
    category: "Body Fit",
    action: "click",
    shouldFireOnFirstRender: false,
  })
  return (
    <>
      <LaptopDownOnly>
        <Flex
          flexDir="column"
          h="auto"
          className="BodyFitQuizWrapper QuizMobileInitScreenContent"
        >
          <Flex
            className="BodyFitBtnContent"
            color="night"
            flexDir="column"
            justify="center"
            flexGrow={1}
            alignItems="center"
          >
            <Flex
              flexDir="column"
              justify="center"
              className="your-perfect-fit"
              color="#fff"
              fontSize="32px"
              textAlign="center"
              fontWeight={800}
            >
              <span className="text-your">Your</span>
              <span className="text-perfect" style={{ letterSpacing: "5px" }}>
                <span className="text-underline">PE</span>RFE
                <span className="text-underline">CT</span>
              </span>
              <span className="text-fit" style={{ letterSpacing: "5px" }}>
                BIKE
              </span>
              <span className="text-starts">Starts Here</span>
            </Flex>

            <Body
              lineHeight="1.375rem"
              color="#fff"
              textAlign="center"
              fontWeight={500}
              mt="15px"
              w="80%"
            >
              {quizIntroText}
            </Body>

            <ArrowDownIcon
              style={{ marginTop: "20px" }}
              className="text-down-arrow"
            />

            <QuizButton
              mt="20px"
              className="startFittingSidebarBtn"
              onClick={() => {
                dispatch.quiz.setProgressState("inprogress")
                dispatch.quiz.setQuizStartTime(Date.now().toString())
                googleAnalytics.fireEvent()
              }}
            >
              Start Fitting
            </QuizButton>

            <Body
              size="lg"
              lineHeight="1.4375rem"
              fontWeight="500"
              mb="1rem"
              color="#97B0BC"
              textAlign="center"
              fontSize="1rem"
              mt="10px"
            >
              Get Fitted In Under 2 Minutes
            </Body>
          </Flex>
          <Box display={isLoggedIn ? "none" : "block"} pt="1rem" pb="5px">
            <Flex
              className="BodyFitLoginBtnContent"
              color="night"
              flexDir="column"
              justify="center"
              flexGrow={1}
              alignItems="center"
            >
              <QuizButton
                className="startFittingSidebarBtn"
                w="100%"
                onClick={() => {
                  openLoginDrawer()
                }}
                theme="secondary"
                mb="1.375rem"
              >
                Log In
              </QuizButton>
              <Body
                lineHeight="1.375rem"
                color="#fff"
                textAlign="center"
                fontWeight={600}
                w="75%"
              >
                Log in to view your previous quiz results and save new results.
              </Body>
            </Flex>
          </Box>
        </Flex>
      </LaptopDownOnly>
      <LaptopUpOnly>
        <Flex flexDir="column" h="auto">
          <Flex
            className="BodyFitBtnContent"
            color="night"
            flexDir="column"
            justify="center"
            flexGrow={1}
            alignItems="center"
          >
            <Flex
              flexDir="column"
              justify="center"
              className="your-perfect-fit"
              color="#fff"
              fontSize="32px"
              textAlign="center"
              fontWeight={800}
            >
              <span className="text-your">Your</span>
              <span className="text-perfect">
                <span className="text-underline">PE</span>RFE
                <span className="text-underline">CT</span>
              </span>
              <span className="text-fit">BIKE</span>
              <span className="text-starts">Starts Here</span>
            </Flex>

            <Body
              lineHeight="1.375rem"
              color="#fff"
              textAlign="center"
              fontWeight={500}
              mt="15px"
            >
              {quizIntroText}
            </Body>

            <ArrowDownIcon
              style={{ marginTop: "20px" }}
              className="text-down-arrow"
            />

            <QuizButton
              mt="20px"
              className="startFittingSidebarBtn"
              onClick={() => {
                dispatch.quiz.setProgressState("inprogress")
                dispatch.quiz.setQuizStartTime(Date.now().toString())
                googleAnalytics.fireEvent()
              }}
            >
              Start Fitting
            </QuizButton>

            <Body
              size="lg"
              lineHeight="1.4375rem"
              fontWeight="500"
              mb="1rem"
              color="#97B0BC"
              textAlign="center"
              fontSize="1rem"
              mt="10px"
            >
              Get Fitted In Under 2 Minutes
            </Body>
          </Flex>
          <Box display={isLoggedIn ? "none" : "block"} pt="1rem" pb="5px">
            <Flex
              className="BodyFitLoginBtnContent"
              color="night"
              flexDir="column"
              justify="center"
              flexGrow={1}
              alignItems="center"
            >
              <QuizButton
                className="startFittingSidebarBtn"
                w="100%"
                onClick={() => {
                  openLoginDrawer()
                }}
                theme="secondary"
                mb="1.375rem"
              >
                Log In
              </QuizButton>
              <Body
                lineHeight="1.375rem"
                color="#fff"
                textAlign="center"
                fontWeight={600}
              >
                Log in to view your previous quiz results and save new results.
              </Body>
            </Flex>
          </Box>
        </Flex>
      </LaptopUpOnly>
    </>
  )
}

export default InitialQuizScreen
