import React from "react"
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  IconButtonProps as ChakraIconButtonProps,
} from "@chakra-ui/core"

type IconButtonProps = Omit<ChakraIconButtonProps, "size" | "icon"> & {
  icon: string
  size?: "sm" | "md" | "lg" | "xl" | string
}

export const IconButton = React.forwardRef(function IconButton(
  { size = "md", variant = "ghost", ...props }: IconButtonProps,
  ref
) {
  let width, height
  switch (size) {
    case "xl":
      width = "1.25rem"
      height = "1.25rem"
      break
    case "lg":
      width = "1rem"
      height = "1rem"
      break
    case "md":
      width = "1rem"
      height = "1rem"
      break
    case "sm":
      width = "0.5rem"
      height = "0.5rem"
      break
    default:
      width = size
      height = size
      break
  }
  return (
    <Button
      fontSize="1.25rem"
      borderRadius="0.5rem"
      color="dusk"
      _hover={{
        backgroundColor: "dividerLine",
      }}
      _active={{
        backgroundColor: "noon",
        color: "night",
        outline: "none",
      }}
      variant={variant}
      {...props}
      ref={ref}
    >
      <Icon width={width} height={height} name={props.icon} />
    </Button>
  )
})

type IconButtonWithBadgeProps = BoxProps & {
  countBadge?: number
  iconButtonProps: IconButtonProps
}

export const IconButtonWithBadge = React.forwardRef(
  function IconButtonWithBadge(
    { countBadge, iconButtonProps, ...props }: IconButtonWithBadgeProps,
    ref
  ) {
    return (
      <Box {...props} pos="relative">
        {countBadge !== undefined && (
          <Flex
            pos="absolute"
            top="calc(50% - 1rem)"
            right="calc(50% - 1.1875rem)"
            zIndex={1}
            fontFamily="DIN"
            fontWeight="bold"
            fontSize="0.625rem"
            lineHeight="0.25rem"
            color="white"
            backgroundColor="primary"
            borderRadius="1000px"
            w="1rem"
            h="1rem"
            align="center"
            justify="center"
          >
            <Box w="auto" h="0.25rem" textAlign="center" whiteSpace="nowrap">
              {countBadge < 10 ? countBadge : "9+"}
            </Box>
          </Flex>
        )}
        <IconButton {...iconButtonProps} ref={ref} />
      </Box>
    )
  }
)

export default IconButton
