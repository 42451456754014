import React, { Fragment } from "react"
import { Flex, FlexProps, Box } from "@chakra-ui/core"
import { LinkProps } from ".."
import NavColumn from "../NavColumn"

export type MultiColumnNavProps = FlexProps & {
  columns: {
    title: string
    titleLink?: string
    links: Array<LinkProps>
  }[]
  onLinkClick?: VoidFunction
  onShowChange?: (showAll: boolean) => void
}

export default function MultiColumnNav({
  columns,
  onLinkClick,
  onShowChange,
  ...props
}: MultiColumnNavProps) {
  return (
    <Flex {...props}>
      {columns.map((column, index) => (
        <Fragment key={column.title}>
          <NavColumn
            key={column.title}
            title={column.title}
            titleLink={column.titleLink}
            links={column.links}
            onLinkClick={onLinkClick}
            onShowChange={onShowChange}
            borderRight={index !== columns.length - 1 ? "1px solid" : undefined}
            borderColor="dividerLine"
            px="4rem"
          />
        </Fragment>
      ))}
    </Flex>
  )
}
