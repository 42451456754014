import { useState, useEffect } from "react"
import debounce from "lodash/debounce"
import { breakpointsList } from "../constants/breakpoints"
import { isSSR } from "../utils"

function getValue<T>(width: Number, values: T[]) {
  let lastNonNullValue
  const breakpointIndex = values.findIndex((value, index) => {
    if (value !== null) {
      lastNonNullValue = value
    }
    if (width <= breakpointsList[index]) {
      return true
    }
    return false
  })
  if (breakpointIndex === -1) {
    return values[values.length - 1]
  }

  const value = values[breakpointIndex]
  if (value === null) {
    return lastNonNullValue
  } else {
    return value
  }
}

export default function useBreakpointValue<T>(values: T[]) {
  const innerWidth = !isSSR ? window.innerWidth : 0
  const [breakpointValue, setBreakpointValue] = useState(() =>
    getValue<T>(innerWidth, values)
  )

  useEffect(() => {
    const calcValue = debounce(function () {
      const width = !isSSR ? window.innerWidth : 0
      setBreakpointValue(getValue(width, values))
    }, 50)
    if (!isSSR) window.addEventListener("resize", calcValue)
    return () => {
      if (!isSSR) window.removeEventListener("resize", calcValue)
    }
  }, [values])

  return breakpointValue
}
