import React from "react"
import { Text, BoxProps } from "@chakra-ui/core"
import styled from "@emotion/styled"

type ParagraphProps = Omit<BoxProps, "size"> & {
  size?: "xlarge" | "xl" | "large" | "lg" | "medium" | "md"
}

const StyledText = styled(Text)`
  ul,
  ol {
    padding-left: 1.25rem;
    line-height: 1.5rem;

    li {
      padding-left: 0.5rem;
    }
  }

  b,
  strong {
    font-weight: 600;
  }
`

const Paragraph = ({ size = "medium", ...props }: ParagraphProps) => {
  let fontSize
  switch (size) {
    case "xlarge":
    case "xl":
      fontSize = "parXl"
      break
    case "large":
    case "lg":
      fontSize = "parLg"
      break
    case "medium":
    case "md":
      fontSize = "parMd"
      break
  }

  return (
    <StyledText
      fontFamily="avenirNext"
      fontSize={fontSize}
      lineHeight="1.5rem"
      {...props}
    ></StyledText>
  )
}

export default Paragraph
