import React from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  PseudoBox,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Spinner,
  Drawer,
  DrawerContent,
  useDisclosure,
  PopoverArrow,
  IconButtonProps,
} from "@chakra-ui/core"
import { navigate } from "gatsby"
import FocusLock from "react-focus-lock"
import { FluidObject } from "gatsby-image"

import Heading from "../typography/Heading"
import Body from "../typography/Body"
import SmallCaps from "../typography/SmallCaps"
import { Button as CustomButton } from "../Buttons"
import SearchWrapper from "./SearchWrapper"
import IconButton from "../Buttons/IconButton"
import PopoverCloseButton from "../Buttons/PopoverCloseButton"
import FormattedTitle from "../FormattedTitle"
import BackgroundImage from "../BackgroundImage"

import { LaptopUpOnly, LaptopDownOnly, bp } from "../../utils/MediaQueries"
import { BikeListingType } from "../../utils/withTransformData"
import { placeholderImage } from "../../utils"
import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { getFixedPrice } from "../Cart/utils"
import { trackSearch } from "../Scripts/EventTracker"

export interface BikeResultType {
  title: string
  image?: FluidObject | null
  shopifyId?: string
  onClick?: VoidFunction
}

const BikeResult: React.FC<BikeResultType> = ({
  title,
  shopifyId,
  image,
  onClick,
}) => {
  const { pricingMap } = useShopifyVariantPricing([shopifyId])
  return (
    <PseudoBox
      cursor="pointer"
      _hover={{ bg: "dividerLine" }}
      _active={{ transform: "scale(0.95)" }}
      onClick={onClick}
    >
      <Flex
        p="0.875rem 0.75rem"
        border="1px solid"
        borderRadius="0.5rem"
        borderColor="dividerLine"
        m="0.375rem 0"
      >
        <Box h="4.5775rem" w="5.875rem" overflow="hidden" borderRadius="0.5rem">
          <BackgroundImage
            fluid={image || placeholderImage}
            backgroundSize="contain"
            w="100%"
            h="100%"
          />
        </Box>
        <Flex flexDirection="column" ml="1rem">
          <Heading as="span" d="block" size="6" textTransform="none">
            <FormattedTitle raw={title} />
          </Heading>
          <Body color="dawn" mt="0.375rem">
            {getFixedPrice(pricingMap[shopifyId || ""]?.priceV2?.amount)}
          </Body>
        </Flex>
      </Flex>
    </PseudoBox>
  )
}

interface MasterSearchResults {
  searchText: string
  isFetching: boolean
  suggestions: Array<{ title: string; slug: string }>
  results: Array<BikeListingType>
  onCollectionSelect: (slug: string) => void
  onBikeSelect: (slug: string) => void
  onViewAllResults: VoidFunction
}
const MasterSearchResults: React.FC<MasterSearchResults> = ({
  searchText,
  isFetching,
  suggestions,
  results,
  onCollectionSelect,
  onBikeSelect,
  onViewAllResults,
}) => {
  if (searchText.length === 0) {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        h={bp("33.333vh", "23rem")}
        w="100%"
      >
        <Icon
          display={bp("block", "none")}
          name="search"
          color="dawn"
          size="1.5rem"
          mb="0.8231rem"
        />
        <Body w="50%" color="dawn" textAlign="center">
          Search by product or keyword
        </Body>
      </Flex>
    )
  } else if (isFetching) {
    return (
      <Flex
        h={bp("33.333vh", "25rem")}
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color="primary" />
      </Flex>
    )
  }
  let contents: Array<JSX.Element> = []
  if (suggestions.length > 0) {
    contents.push(
      <Box key={`search-suggestions-box`}>
        <SmallCaps color="primary" size="sm" fontWeight="bold">
          Suggestions
        </SmallCaps>
        {suggestions.map(({ title, slug }) => {
          return (
            <Body
              color="night"
              m={["1rem 0", null, null, null, "0.5rem 0"]}
              cursor="pointer"
              onClick={() => {
                onCollectionSelect(slug)
              }}
              key={`suggestion-${title}`}
            >
              {title}
            </Body>
          )
        })}
      </Box>
    )
  }
  if (results.length > 0) {
    contents.push(
      <Box key={`search-results-box`}>
        <SmallCaps color="primary" size="sm" fontWeight="bold" mb="1rem">
          Top Results
        </SmallCaps>
        {results.map((bike: BikeListingType) => {
          const variant = bike.variants.find((variant) => !!variant.image)

          return (
            <BikeResult
              key={bike.slug}
              title={bike.title}
              image={variant?.image || placeholderImage}
              shopifyId={variant?.uniqueId}
              onClick={() => {
                onBikeSelect(bike.slug)
              }}
            />
          )
        })}
        <CustomButton
          theme="primary"
          mt="1.875rem"
          w="100%"
          onClick={onViewAllResults}
        >
          View All Results
        </CustomButton>
      </Box>
    )
  }
  return (
    <Flex flexDir="column" justifyContent="space-between" p="2.25rem 0">
      {contents.length > 0 ? (
        contents
      ) : (
        <Flex justifyContent="center" w="100%" mt="2rem">
          <SmallCaps color="dawn">Sorry, no results found</SmallCaps>
        </Flex>
      )}
    </Flex>
  )
}

export type SearchProps = Omit<IconButtonProps, "aria-label"> & {
  isSideBarOpen: boolean
}

const Search = ({ isSideBarOpen, ...props }) => {
  const [searchText, setSearchText] = React.useState<string>("")

  const onCollectionSelect = (slug: string) => {
    navigate(slug)
  }
  const onBikeSelect = (slug: string) => {
    navigate(slug)
  }
  const onViewAllResults = () => {
    trackSearch(searchText)
    navigate(`/search?searchText=${searchText}`)
  }
  const textInputRef = React.createRef<HTMLInputElement>()
  const [isFocused, setIsFocused] = React.useState<boolean>(false)

  // Mobile Only
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <SearchWrapper searchText={searchText} resultLimit={2} suggestionLimit={8}>
      {({ isFetching, results, suggestions }) => (
        <>
          <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
            <IconButton
              {...props}
              size="xl"
              icon="search"
              aria-label="Search"
              onClick={() => {
                props.onClick?.()
                onOpen()
              }}
            />
            <Drawer
              placement="top"
              size="full"
              isOpen={isOpen}
              onClose={() => setSearchText("")}
              scrollBehavior="inside"
            >
              <DrawerContent h="100vh">
                <Flex flexDir="column" h="100vh" p="0 1rem">
                  <Flex mt="2rem" align="center" justify="center">
                    <InputGroup w="100%">
                      <InputLeftElement
                        h="100%"
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        pl="0.125rem"
                      >
                        <Icon name="search" size="1.25rem" color="dusk" />
                      </InputLeftElement>
                      <Input
                        type="search"
                        borderRadius="0.5rem"
                        color="dusk"
                        fontFamily="avenirNext"
                        fontSize="1rem"
                        lineHeight="1.375rem"
                        h="3rem"
                        backgroundColor="noon"
                        border="0"
                        ref={textInputRef}
                        value={searchText}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setSearchText(event.target.value)
                        }}
                        onKeyPress={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === "Enter") {
                            textInputRef?.current?.blur()
                          }
                        }}
                        onBlur={() => {
                          if (isFocused) {
                            textInputRef?.current?.focus()
                          }
                        }}
                      />
                    </InputGroup>
                    <IconButton
                      aria-label="close search"
                      icon="close"
                      size="0.5994rem"
                      h="3rem"
                      w="3rem"
                      ml="0.125rem"
                      color="dusk"
                      onClick={onClose}
                    />
                  </Flex>
                  <MasterSearchResults
                    searchText={searchText}
                    isFetching={isFetching}
                    results={results}
                    suggestions={suggestions}
                    onBikeSelect={(slug) => {
                      onClose()
                      onBikeSelect(slug)
                    }}
                    onCollectionSelect={(slug) => {
                      onClose()
                      onCollectionSelect(slug)
                    }}
                    onViewAllResults={() => {
                      onClose()
                      onViewAllResults()
                    }}
                  />
                </Flex>
              </DrawerContent>
            </Drawer>
          </LaptopDownOnly>
          <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
            <Popover
              initialFocusRef={textInputRef}
              onClose={() => {
                setIsFocused(false)
                setSearchText("")
              }}
              onOpen={() => {
                setIsFocused(true)
                textInputRef?.current?.focus()
              }}
              placement="bottom-end"
            >
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Search"
                      icon="search"
                      size="xl"
                      {...props}
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    bg="white"
                    borderRadius="0.5rem"
                    minHeight="33.75rem"
                    w="23.5rem"
                    px="1.25rem"
                    pt="3.75rem"
                    zIndex={1050}
                    boxShadow="big"
                    _focus={{ outline: "none" }}
                  >
                    <PopoverArrow />
                    <FocusLock returnFocus persistentFocus={false}>
                      <PopoverCloseButton top="0.5rem" right="0.5rem" />
                      <InputGroup>
                        <InputLeftElement>
                          <Icon name="search" size="1rem" color="dusk" />
                        </InputLeftElement>
                        <Input
                          type="search"
                          placeholder="Search..."
                          bg="noon"
                          borderRadius="0.5rem"
                          border="none"
                          color="dusk"
                          fontFamily="avenirNext"
                          fontSize="1rem"
                          lineHeight="22px"
                          ref={textInputRef}
                          value={searchText}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSearchText(event.target.value)
                          }}
                        />
                      </InputGroup>
                      <MasterSearchResults
                        searchText={searchText}
                        isFetching={isFetching}
                        results={results}
                        suggestions={suggestions}
                        onBikeSelect={(slug) => {
                          if (onClose) onClose()
                          onBikeSelect(slug)
                        }}
                        onCollectionSelect={(slug) => {
                          if (onClose) onClose()
                          onCollectionSelect(slug)
                        }}
                        onViewAllResults={() => {
                          if (onClose) onClose()
                          onViewAllResults()
                        }}
                      />
                    </FocusLock>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </LaptopUpOnly>
        </>
      )}
    </SearchWrapper>
  )
}

export default Search
