import * as R from "ramda"
import { ModelConfig } from "@rematch/core"
import { BaseDispatch, BaseRootState } from "../store"
import {
  BikeType,
  CustomizerBikeType,
  BikeProgressState,
} from "../types/BikeTypes"
import { RiderStyleType } from "../types/QuizTypes"
import { ContentfulProductVariant, LayoutQuery } from "../../../graphql-types"
import { filterQuestions } from "../utils/quiz"
import { convertHeightAnswerToInches } from "../utils/bikes"

interface BikesStateType {
  bikes: Array<BikeType>
  accessories: LayoutQuery["accessories"]["edges"]
  collections: LayoutQuery["collections"]["edges"]
  bikeResults: Array<BikeType> | null
  customizerBike: CustomizerBikeType | null
  warranty: ContentfulProductVariant | null
  error: any
  bikeProgressState: BikeProgressState
  selectedBike: CustomizerBikeType | null
  heightRestriction: number | 0
}

const initialState: BikesStateType = {
  bikes: [],
  accessories: [],
  collections: [],
  bikeResults: null,
  customizerBike: null,
  bikeProgressState: "none",
  warranty: null,
  error: null,
  selectedBike: null,
  heightRestriction: 0,
}

export const bikes: ModelConfig<BikesStateType> = {
  state: initialState,
  reducers: {
    setError(state, error: any) {
      return { ...state, error }
    },
    setBikes(state, bikes: Array<BikeType>) {
      return { ...state, bikes }
    },
    setAccessories(
      state: BikesStateType,
      accessories: BikesStateType["accessories"]
    ) {
      return { ...state, accessories }
    },
    setCollections(
      state: BikesStateType,
      collections: BikesStateType["collections"]
    ) {
      return { ...state, collections }
    },
    setBikeResults(state: BikesStateType, bikeResults: Array<BikeType> | null) {
      return { ...state, bikeResults }
    },
    setInitialCustomizerBike(state, customizerBike: CustomizerBikeType) {
      // Figure out defaults if not provided in customizerBike
      const speed =
        customizerBike.speed ||
        R.sortBy(R.prop("speed"))(customizerBike.bike?.speeds || [])[0]
      const variant = customizerBike.variant
        ? customizerBike.variant
        : speed?.variants?.[0]

      return {
        ...state,
        customizerBike: { ...customizerBike, speed, variant },
      }
    },
    setCustomizerBike(state, customizerBike: CustomizerBikeType | null) {
      return {
        ...state,
        customizerBike,
      }
    },
    setWarranty(state, warranty: ContentfulProductVariant | null) {
      return { ...state, warranty }
    },
    setProgressState(
      state: BikesStateType,
      bikeProgressState: BikeProgressState
    ) {
      return { ...state, bikeProgressState }
    },
    setSelectedBike(state, selectedBike: CustomizerBikeType | null) {
      return { ...state, selectedBike }
    },
    setHeightRestriction(state, heightRestriction: number | 0) {
      return { ...state, heightRestriction }
    },
  },
  effects: (dispatch: any) => ({
    filterBikes(
      { bikes: _bikes }: { bikes: BikeType[] },
      state: BaseRootState
    ) {
      let bikes = _bikes || state.bikes.bikes
      const { answers } = state.quiz
      const usersHeightInInches = convertHeightAnswerToInches(answers["height"])
      const usersWeightInLB = parseInt(answers["weight"]?.[0], 10)

      const gender = answers.gender as string
      if (gender && gender?.toLowerCase() !== "either") {
        bikes = bikes.filter(
          (bike) => bike.gender === gender || bike.gender === "Unisex"
        )
      }

      if (usersHeightInInches !== undefined) {
        bikes = bikes.filter((b) => {
          const maxHeight = b.quizFilters.maxHeight ?? 200
          const minHeight = b.quizFilters.minHeight ?? 0
          const isUserTooTall = maxHeight < usersHeightInInches
          const isUserTooShort = minHeight > usersHeightInInches
          return !isUserTooTall && !isUserTooShort
        })
      }
      if (usersWeightInLB !== undefined) {
        bikes = bikes.filter((b) => {
          const maxBikeWeight = b.quizFilters.maxWeight ?? 1000
          const isUserTooHeavy = maxBikeWeight < usersWeightInLB
          return !isUserTooHeavy
        })
      }
      if(!bikes.length) {
        dispatch.bikes.setHeightRestriction(1)
        return bikes
      } else{
        dispatch.bikes.setHeightRestriction(0)
      }
      const filterAnswers = R.flatten(
        state.quiz.questions.map((q) =>
          (q?.options || [])
            .filter((opt) => opt.filterBikes)
            .map((opt) => ({ ...opt, questionId: q.questionId }))
        )
      )

      let tagsFilteredBikes = bikes
      let userAnswers: string[] = []
      filterAnswers.forEach((option) => {
        if (option.questionId === "gender") return

        const answer = answers[option.questionId]
        if (answer === option.answerId) {
          userAnswers.push(answer)
          if(option.questionId === "standard-or-electric" || option.questionId === "tricycle-preference") {
            tagsFilteredBikes = tagsFilteredBikes.filter((b) => {
              return b.quizTags.find((tag) => tag.answerId === answer)
            }
            )
          }
        }
      })
      if(!tagsFilteredBikes.length) {
        tagsFilteredBikes = bikes
        tagsFilteredBikes = tagsFilteredBikes.filter((b) => {
          let totalMatches = b.quizTags.filter((tag) => userAnswers.includes(tag.answerId))
          return totalMatches.length > 1
        })
      }

      return tagsFilteredBikes
    },
    scoreBikes({ bikes: _bikes }: { bikes: BikeType[] }, state: BaseRootState) {
      const { questions, answers } = state.quiz
      const bikes = _bikes || state.bikes.bikes
      const filteredQuestions = filterQuestions({ questions, answers })
      const scoredBikes = bikes.map((bike) => {
        const score = bike.quizTags?.reduce((acc, cur) => {
          // Don't score if question was filtered
          if (!filteredQuestions.find((q) => q.questionId === cur.questionId))
            return acc

          const answer = state.quiz.answers[cur.questionId]
          // Don't score if question isn't answered
          if (answer == null || answer.length < 1) return acc

          if (answer && answer === cur.answerId) {
            const question = state.quiz.questions.find(
              (q) => q.questionId === cur.questionId
            )
            return acc + (question?.weight ?? 0)
          }
          return acc
        }, 0)
        const highestScorePossible = bike.quizTags
          ?.reduce((acc, { questionId }) => {
            const qId = acc.find((id) => id === questionId)
            return qId === undefined ? [...acc, questionId] : acc
          }, [])
          .reduce((acc, questionId) => {
            const question = state.quiz.questions.find(
              (q) => q.questionId === questionId
            )
            return acc + (question?.weight ?? 0)
          }, 0)
        const percentMatch =
          highestScorePossible === 0
            ? 0
            : Math.round((score / highestScorePossible) * 99)
        const scoredBike = {
          ...bike,
          score: percentMatch,
        }
        return scoredBike
      })

      const topScore = scoredBikes.reduce((acc, scoredBike) => {
        if (scoredBike.score > acc) return scoredBike.score
        return acc
      }, 0)

      if (topScore < 90) {
        const diff = 98 - topScore
        // Pad scores if they're lower than 90 percent
        scoredBikes.forEach((bike) => {
          bike.score = Math.min(diff + bike.score, 98)
        })
      }

      return (
        scoredBikes
          // .filter((bike) => bike.score > 50)
          .sort((a, b) => (a.score < b.score ? 1 : -1))
      )
    },
    riderType(_, state: BaseRootState) {
      const { questions, answers } = state.quiz
      const filteredQuestions = filterQuestions({ questions, answers })
      const riderTypeScoring = filteredQuestions.reduce(
        (acc, q) => {
          const answer = state.quiz.answers[q.questionId]
          if (answer === undefined) return acc // Means question was not answered
          let newScore = { ...acc }
          const answerOptionTypes = q.options?.find(
            ({ answerId }) => answerId === answer
          )?.riderType
          if (answerOptionTypes) {
            answerOptionTypes.forEach((riderType) => (newScore[riderType] += 1))
            return newScore
          }

          return acc
        },
        {
          casual: 0,
          dedicated: 0,
          fanatical: 0,
        }
      )
      let riderTypeAnswer: RiderStyleType = "casual"
      if (riderTypeScoring.dedicated >= riderTypeScoring.casual) {
        riderTypeAnswer = "dedicated"
      }
      if (
        riderTypeScoring.fanatical >= riderTypeScoring.dedicated &&
        riderTypeScoring.fanatical >= riderTypeScoring.casual
      ) {
        riderTypeAnswer = "fanatical"
      }
      dispatch.quiz.setAnswers({
        ...state.quiz.answers,
        riderType: riderTypeAnswer,
      })
    },
    async calculateResults(_, state: BaseRootState): Promise<BikeType[]> {
      let bikes = state.bikes.bikes
      bikes = await dispatch.bikes.filterBikes({ bikes })
      bikes = await dispatch.bikes.scoreBikes({ bikes })
      dispatch.bikes.riderType()
      dispatch.bikes.setBikeResults(bikes)
      return bikes
    },
    reset(_, state: BaseRootState) {
      dispatch.bikes.setBikeResults(null)
      dispatch.bikes.setProgressState("none")
      dispatch.bikes.setCustomizerBike(null)
    },
  }),
}
