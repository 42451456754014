import React from "react"
import { BoxProps, Button, ButtonProps, Flex, Icon } from "@chakra-ui/core"
import Heading, { HeadingProps } from "../typography/Heading"
import SelectedIcon from "../icons/SelectedIcon"
import ColorSwatch from "../ColorSwatch"
import Body from "../typography/Body"

export type ItemSize = "small" | "large" | "wide"

export type Option = {
  value: string
  name: string
  price?: string
  innerText?: string
  backgroundColor?: string
  showBorder?: boolean
  showWarning?: boolean
  isDisabled?: boolean
}

type GridSelectOptionProps = Omit<ButtonProps, "children"> & {
  option: Option
  active: boolean
  itemSize?: ItemSize
  swatchProps?: BoxProps
}

export default function GridSelectOption({
  option,
  active,
  itemSize,
  swatchProps = {},
  ...props
}: GridSelectOptionProps) {
  let containerSizeProps: Omit<ButtonProps, "children">
  let swatchSizeProps: BoxProps
  let innerTextProps: HeadingProps
  switch (itemSize) {
    case "large":
      containerSizeProps = {
        w: "9.5038rem",
      }
      swatchSizeProps = {
        w: "8.0056rem",
        h: "8.0056rem",
        ...swatchProps,
      }
      innerTextProps = {
        size: "6",
        color: "dusk",
      }
      break
    case "wide":
      containerSizeProps = {
        w: "100%",
      }
      swatchSizeProps = {
        w: "100%",
        h: "auto",
        p: "0.875rem 0.125rem",
        ...swatchProps,
      }
      innerTextProps = {
        size: "6",
        color: "dusk",
      }
      break
    case "small":
    default:
      containerSizeProps = {
        w: "5.1325rem",
      }
      swatchSizeProps = swatchProps
      innerTextProps = {
        size: "5",
      }
  }

  return (
    <Button
      {...containerSizeProps}
      px="0.7844rem"
      py="0.8013rem"
      pos="relative"
      h="auto"
      borderRadius="0.75rem"
      variant="ghost"
      variantColor="none"
      alignItems="flex-start"
      boxShadow={active ? "0px 0.25rem 3.125rem rgba(18, 25, 35, 0.1)" : "none"}
      isDisabled={props.isDisabled || option.isDisabled}
      _hover={{ backgroundColor: "dividerLine" }}
      _active={{
        backgroundColor: "noon",
        boxShadow: "0px 0.25rem 3.125rem rgba(18, 25, 35, 0.1)",
      }}
      _focus={{
        boxShadow: "0px 0.25rem 3.125rem rgba(18, 25, 35, 0.1)",
      }}
      {...props}
    >
      {active && (
        <SelectedIcon pos="absolute" top="-0.5rem" right="-0.5rem" zIndex={1} />
      )}
      <Flex direction="column" align="center" w="100%">
        <ColorSwatch
          colorCode={option.backgroundColor || "noon"}
          pos="relative"
          mb="1.0369rem"
          {...swatchSizeProps}
        >
          {!active && option.showWarning && (
            <Icon
              name="warning"
              pos="absolute"
              top="-0.5rem"
              right="-0.5rem"
              color="daylight"
              size="1.0425rem"
              zIndex={1}
            />
          )}
          {option.innerText && (
            <Heading
              as="span"
              fontWeight="bold"
              whiteSpace="normal"
              px="0.5rem"
              {...innerTextProps}
            >
              {option.innerText}
            </Heading>
          )}
        </ColorSwatch>
        <Body color="dawn" size="xs" whiteSpace="normal">
          {option.name}
        </Body>
        {option.price && (
          <Body color="dawn" size="xs" letterSpacing="thin100">
            {option.price}
          </Body>
        )}
      </Flex>
    </Button>
  )
}
