import { rem } from "../utils"

export const SIDEBAR_WIDTH_OPEN = 360
export const SIDEBAR_WIDTH_OPEN_REM = rem(360)
export const SIDEBAR_WIDTH_CLOSED = 60
export const SIDEBAR_WIDTH_CLOSED_REM = rem(60)
export const HEADER_HEIGHT = 64
export const MOBILE_HEADER_HEIGHT = 56
export const HEADER_HEIGHT_REM = rem(64)

export default {
  SIDEBAR_WIDTH_OPEN,
  SIDEBAR_WIDTH_OPEN_REM,
  SIDEBAR_WIDTH_CLOSED,
  SIDEBAR_WIDTH_CLOSED_REM,
  HEADER_HEIGHT,
  HEADER_HEIGHT_REM,
}
