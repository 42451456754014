import { FixedObject, FluidObject } from "gatsby-image"

export const gatsbyFluidImage = (
  url: string,
  aspectRatio?: number
): FluidObject => ({
  src: url,
  aspectRatio: aspectRatio || 1,
  media: "image/png",
  sizes: "",
  srcSet: "",
})

export const gatsbyFixedImage = (
  url: string,
  width: number,
  height: number
): FixedObject => ({
  src: url,
  width,
  height,
  media: "image/png",
  srcSet: "",
})
