import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import Image from "gatsby-background-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { mq } from "../../utils/MediaQueries"
import { FluidObject } from "gatsby-image"

type BackgroundImageProps = BoxProps & {
  fluid: FluidObject | FluidObject[]
}
type StyledImageProps = {
  backgroundPosition: BoxProps["backgroundPosition"]
  backgroundSize: BoxProps["backgroundSize"]
}

const StyledImage = styled(Image)((props: StyledImageProps) =>
  css(
    mq({
      width: "100%",
      height: "100%",
      ":before, :after": {
        backgroundPosition: props.backgroundPosition,
        backgroundSize: props.backgroundSize,
      },
    })
  )
)

export default function BackgroundImage({
  fluid,
  backgroundSize = "cover",
  backgroundPosition = "center",
  ...props
}: BackgroundImageProps) {
  return (
    <Box {...props}>
      <StyledImage
        style={{
          // Remove gatsby-background-image defaults
          backgroundSize: "",
          backgroundPosition: "",
        }}
        fluid={fluid}
        backgroundSize={backgroundSize}
        backgroundPosition={backgroundPosition}
        fadeIn
      />
    </Box>
  )
}
