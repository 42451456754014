import { init, RematchRootState, RematchDispatch } from "@rematch/core"
import createRematchPersist, { getPersistor } from "@rematch/persist"
import add from "date-fns/add"
import { models, RootModel } from "./models"

const persistPlugin = createRematchPersist({
  whitelist: ["checkout", "quiz", "user", "bikes"],
  version: 1,
  stateReconciler: (inboundState, originalState, _) => {
    let { checkout, bikes } = originalState
    let { quiz, user } = inboundState
    if (
      inboundState?.checkout?.lastQueryTimestamp != null &&
      (add(inboundState.checkout.lastQueryTimestamp, { days: 1 }) as any) >
        Date.now()
    ) {
      // persisted inbound state only has checkout default state
      checkout = inboundState.checkout
    }

    if (inboundState?.bikes?.selectedBike) {
      bikes.selectedBike = inboundState.bikes.selectedBike
    }

    return { ...originalState, checkout, quiz, user, bikes }
  },
})
const store = init({
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === "production",
    },
  },
  plugins: [persistPlugin],
  models,
})

export const persistor = getPersistor()

export type BaseStore = typeof store
export type BaseDispatch = RematchDispatch<RootModel>
export type BaseRootState = RematchRootState<RootModel>

export default store
