import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/core"
import { CustomDomComponent, motion } from "framer-motion"

export const MotionBox: CustomDomComponent<Omit<
  BoxProps,
  "transition"
>> = motion.custom(Box)

export const MotionFlex: CustomDomComponent<Omit<
  FlexProps,
  "transition"
>> = motion.custom(Flex)
