import React from "react"
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/core"

import breakpoints from "../../constants/breakpoints"
import { rem } from "../../utils"
import { bpWithSidebar } from "../../utils/MediaQueries"

export type MaxWidthGridProps = SimpleGridProps & {
  isSideBarOpen?: boolean
}

const MAX_WIDTH = `${rem(breakpoints.maxWidth)}rem`

const MaxWidthGrid = React.forwardRef(function MaxWidthGrid(
  { children, isSideBarOpen, ...props }: MaxWidthGridProps,
  ref
) {
  return (
    <SimpleGrid
      columns={bpWithSidebar(2, 14, !!isSideBarOpen)}
      maxWidth={MAX_WIDTH}
      gridColumnGap={bpWithSidebar("1.25rem", "0", !!isSideBarOpen)}
      mx={bpWithSidebar("1.25rem", "auto", !!isSideBarOpen)}
      {...props}
      ref={ref}
    >
      {children}
    </SimpleGrid>
  )
})

export default MaxWidthGrid
