import React from "react"
import { Box, Flex, FlexProps, Icon, Text } from "@chakra-ui/core"
import { Link } from "gatsby"
import { LinkProps } from ".."
import SectionTitle from "../SectionTitle"
import NavColumn from "../NavColumn"

type TwoColumnNavProps = Omit<FlexProps, "children"> & {
  columnOne: Array<LinkProps>
  columnTwo: Array<LinkProps>
  columnOneTitle: string
  columnOneUrl?: string
  columnTwoTitle: string
  onLinkClick: VoidFunction
}

export default function TwoColumnNav({
  columnOneTitle,
  columnTwoTitle,
  columnOneUrl,
  columnOne,
  columnTwo,
  onLinkClick,
  ...props
}: TwoColumnNavProps) {
  return (
    <Flex justify="center" {...props}>
      <Box>
        {columnOneUrl ? (
          <Link to={columnOneUrl} onClick={onLinkClick}>
            <SectionTitle>
              {columnOneTitle}
              <Icon
                name="chevron"
                size="1.25rem"
                transform="rotate(180deg)"
                ml="0.7rem"
              />
            </SectionTitle>
          </Link>
        ) : (
          <SectionTitle>{columnOneTitle}</SectionTitle>
        )}

        {columnOne.map(({ name, slug }) => (
          <Link
            to={slug}
            onClick={onLinkClick}
            key={`${columnOneTitle}-${slug}`}
          >
            <Text
              fontFamily="din"
              fontWeight="bold"
              fontSize="1.25rem"
              lineHeight="1.375rem"
              letterSpacing="2px"
              color="night"
              textTransform="uppercase"
              marginBottom="0.75rem"
            >
              {name}
            </Text>
          </Link>
        ))}
      </Box>
      <Box h="auto" w="1px" bg="dividerLine" m="0 4rem" />
      <NavColumn
        w="calc((100vw /14) * 2)"
        title={columnTwoTitle}
        links={columnTwo}
        wrap
        onLinkClick={onLinkClick}
      />
    </Flex>
  )
}
