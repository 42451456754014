import React from "react"
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  PseudoBox,
  useDisclosure,
} from "@chakra-ui/core"
import Body from "../components/typography/Body"
import { LaptopDownOnly, LaptopUpOnly } from "../utils/MediaQueries"
import AuthContainer from "../components/Account/AuthContainer"
import { trackSignUp } from "../components/Scripts/EventTracker"

type CallbacksType = { onLogin?: VoidFunction; onSignUp?: VoidFunction }
type LoginDrawerContextValue = {
  isLoginDrawerOpen: boolean
  toggleLoginDrawer: (callbacks?: CallbacksType) => void
  openLoginDrawer: (callbacks?: CallbacksType) => void
  closeLoginDrawer: VoidFunction
}

const LoginDrawerContext = React.createContext<
  LoginDrawerContextValue | undefined
>(undefined)

export function LoginDrawerProvider({ children }) {
  const { isOpen: isLoginDrawerOpen, onClose, onOpen } = useDisclosure()
  const [callbacks, setCallbacks] = React.useState<CallbacksType>({})

  const openLoginDrawer = (callbacks: CallbacksType = {}) => {
    setCallbacks(callbacks)
    onOpen()
  }
  const closeLoginDrawer = () => {
    setCallbacks({})
    onClose()
  }
  const toggleLoginDrawer = (callbacks: CallbacksType = {}) => {
    if (isLoginDrawerOpen) {
      closeLoginDrawer()
    } else {
      openLoginDrawer(callbacks)
    }
  }

  const handleLoginSuccess = () => {
    callbacks?.onLogin?.()
    closeLoginDrawer()
  }

  const handleSignUpSuccess = () => {
    callbacks?.onSignUp?.()
    closeLoginDrawer()
    trackSignUp()
  }

  return (
    <LoginDrawerContext.Provider
      value={{
        isLoginDrawerOpen,
        closeLoginDrawer,
        openLoginDrawer,
        toggleLoginDrawer,
      }}
    >
      {children}
      <Drawer
        isOpen={isLoginDrawerOpen}
        onClose={closeLoginDrawer}
        placement="top"
        size="full"
      >
        <LaptopDownOnly>
          <DrawerOverlay />
          <DrawerContent zIndex={1000001}>
            <Flex direction="column" height="100vh">
              <PseudoBox
                role="button"
                d="flex"
                w="100%"
                h="5rem"
                p="0 1.25rem"
                justifyContent="center"
                alignItems="center"
                _hover={{ bg: "dividerLine" }}
                borderBottom="1px solid"
                borderColor="dividerLine"
                onClick={closeLoginDrawer}
                cursor="pointer"
              >
                <Flex align="center" justify="center" color="dusk">
                  <Icon name="x" size="1.375rem" mr="0.3125rem" />
                  <Body size="sm" h="1.1875rem">
                    Close
                  </Body>
                </Flex>
              </PseudoBox>
              <AuthContainer
                w="100%"
                p="0 1.25rem"
                onLoginSuccess={handleLoginSuccess}
                onSignUpSuccess={handleSignUpSuccess}
              />
            </Flex>
          </DrawerContent>
        </LaptopDownOnly>
      </Drawer>
      <Drawer
        isOpen={isLoginDrawerOpen}
        onClose={closeLoginDrawer}
        placement="right"
        size="sm"
      >
        <LaptopUpOnly>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              size="lg"
              color="dusk"
              top="4%"
              right="3.625rem"
              borderRadius="0.5rem"
            />
            <Box h="100%" w="100%">
              <AuthContainer
                onLoginSuccess={handleLoginSuccess}
                onSignUpSuccess={handleSignUpSuccess}
              />
            </Box>
          </DrawerContent>
        </LaptopUpOnly>
      </Drawer>
    </LoginDrawerContext.Provider>
  )
}

export default function useLoginDrawer() {
  const context = React.useContext(LoginDrawerContext)
  if (context === undefined) {
    throw new Error("useLoginDrawer must be used within a LoginDrawerProvider")
  }
  return context
}
