import React from "react"
import { Icon, IconProps } from "@chakra-ui/core"

export default function SelectedIcon(props: IconProps) {
  return (
    <Icon
      className="QuizOptionSelectedIcon"
      name="check-circle"
      color="primary"
      size="1.1513rem"
      transform="rotate(3deg)"
      {...props}
    />
  )
}
