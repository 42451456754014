import { Flex } from "@chakra-ui/core"
import { navigate } from "gatsby"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { bp } from "../../utils/MediaQueries"
import Body from "../typography/Body"

function StepItem({ number, title, quizStep, currentQuizStep }) {
  let isActive =
    quizStep === currentQuizStep ||
    (quizStep === "fit" && currentQuizStep === "select-bike")

  return (
    <>
      <Flex
        className="bodyFitAccordionHeaderNumberIcon"
        h={bp("1rem", "1.5rem")}
        w={bp("1rem", "1.5rem")}
        bg={isActive ? "white" : "#005f78"}
        color={isActive ? "#005f78" : "#cccccc"}
        border={isActive ? "1px solid #ffffff" : "1px solid #cccccc"}
        borderRadius="100px"
        justify="center"
        align="center"
        fontSize="0.75rem"
      >
        {number}
      </Flex>
      <Body
        ml={bp(0, "1rem")}
        textTransform="uppercase"
        color={isActive ? "#ffffff" : "#cccccc"}
        className="bodyFitAccordionHeaderTitle"
        fontSize="0.9rem"
        lineHeight="1.5rem"
        // borderRight={bp("1px solid #ccc", "none")}
        marginLeft="10px"
        paddingRight={bp("3px", 0)}
      >
        {title}
      </Body>
    </>
  )
}

function StepLine({ ...props }) {
  return (
    <Flex {...props} p="10px 0" display={bp("none", "block")}>
      <Flex borderTop="0.5px solid #ccc" w="100%" height="2px" />
    </Flex>
  )
}

export default function QuizStepNav({ currentPagePath, isPopup = false }) {
  const {
    quiz: { quizProgressState },
    bikes: { selectedBike },
    sidebar: { sideBarStep },
    user: { email, accessToken, tokenExpiration, ...user },
  } = useSelector((state: BaseRootState) => state)
  const dispatch = useDispatch<BaseDispatch>()

  return (
    <Flex
      justifyContent="space-between"
      justifyItems="center"
      alignContent="center"
      alignItems="center"
      color="#fff"
      style={{ gap: "20px" }}
      w={bp("100%", "600px")}
      maxW="600px"
      p={bp("1.5rem 2rem", "1rem")}
      display={bp(isPopup ? "none" : "flex", "flex")}
    >
      <Flex
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        onClick={() => {
          dispatch.sidebar.setSideBarStep("fit")
          if (quizProgressState === "finished") {
            dispatch.quiz.setProgressState("previous")
          }
        }}
        //w="23%"
      >
        <StepItem
          number="1"
          title="Fit"
          quizStep="fit"
          currentQuizStep={sideBarStep}
        />
      </Flex>

      <StepLine w="15%" />

      <Flex
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        onClick={() => {
          // @ts-ignore
          if (
            quizProgressState === "previous" ||
            quizProgressState === "finished"
          ) {
            dispatch.sidebar.setSideBarStep("select-bike")
            navigate("/quiz-results", {
              state: { previousPath: currentPagePath },
            })
          }
        }}
        //w="23%"
      >
        <StepItem
          number="2"
          title="Select Bike"
          quizStep="select-bike"
          currentQuizStep={sideBarStep}
        />
      </Flex>

      <StepLine w="15%" />

      <Flex
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        onClick={() => {
          if (selectedBike) {
            dispatch.sidebar.setSideBarStep("customize")
          }
        }}
        //w="23%"
      >
        <StepItem
          number="3"
          title="Customizer"
          quizStep="customize"
          currentQuizStep={sideBarStep}
        />
      </Flex>
    </Flex>
  )
}
