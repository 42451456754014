export const mapURL = (pageType, internalTitle) => {
  switch (pageType) {
    case "bike-page":
      return `/products/${internalTitle}`
    case "collection":
      return `/collections/${internalTitle}`
    default:
      return "/404"
  }
}

export const formatCheckoutURL = (checkoutURL: string) => {
  return process.env.GATSBY_SHOPIFY_SHOP_DOMAIN &&
    process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
    ? checkoutURL?.replace(
        process.env.GATSBY_SHOPIFY_SHOP_DOMAIN,
        process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
      )
    : checkoutURL
}
