import { ModelConfig } from "@rematch/core"
import DefaultClient from "apollo-boost"
import * as R from "ramda"
import { v4 as uuid } from "uuid"
import { stringToBase64 } from "../../utils/text"
import * as Storefront from "shopify-storefront-api-typings"
import createShopifyClient from "../../shopify/graphql/createClient"
import createShopifyClientAdmin from "../../shopify/graphql/createClientAdmin"
import { CheckoutLineItemFieldsType } from "../../shopify/graphql/fragments"
import {
  ApplyDiscount,
  createDiscount,
  CreateCheckout,
  CreateCheckoutResponse,
  RemoveDiscount,
  UpdateCheckout,
  UpdateCheckoutResponse,
  UpdateCheckoutEmailResponse,
  UpdateCheckoutEmail,
} from "../../shopify/graphql/mutations"
import { getIn } from "../../utils"
import { BaseRootState } from "../store"

export interface CheckoutUserError {
  code: string
  field: Array<string>
  message: string
}

export type CheckoutLineItem = CheckoutLineItemFieldsType

export interface CheckoutPricing {
  amount: string
  currencyCode: string
}

export type CheckoutData = CreateCheckoutResponse["checkoutCreate"]["checkout"]

export interface CheckoutState {
  checkoutId: string | null
  data: CheckoutData | null
  quantity: number
  isLoading: boolean
  errors: Array<CheckoutUserError> | []
  lastQueryTimestamp: number | null
}

const InitialState: CheckoutState = {
  checkoutId: null,
  data: null,
  quantity: 0,
  isLoading: false,
  errors: [],
  lastQueryTimestamp: null,
}

export interface CartItem {
  productSlug: string
  type: "bike" | "accessory" | "warranty"
  contentfulProductId?: string
  contentfulVariantId?: string
  bundleId?: string
  parentBundleId?: string
  variantId: string
  isPreorder?: boolean
  preorderInfo?: string
  quantity: number
}

// Need to tag checkout items with contentful information,
// since some product info is stored there
export const createCheckoutLineItem = ({
  type,
  productSlug,
  contentfulProductId,
  contentfulVariantId,
  bundleId,
  parentBundleId,
  variantId,
  isPreorder,
  preorderInfo,
  quantity,
}: CartItem) => {
  const customAttributes: Storefront.AttributeInput[] = contentfulProductId
    ? [
        { key: "_type", value: type },
        { key: "_contentfulProductId", value: contentfulProductId },
        { key: "_contentfulVariantId", value: contentfulVariantId! },
        { key: "_productSlug", value: productSlug },
        { key: "_variantId", value: variantId },
      ]
    : [
        { key: "_type", value: type },
        { key: "_productSlug", value: productSlug },
        { key: "_variantId", value: variantId },
      ]

  if (bundleId) {
    customAttributes.push({
      key: "_bundleId",
      value: bundleId,
    })
  }

  if (parentBundleId) {
    customAttributes.push({
      key: "_parentBundleId",
      value: parentBundleId,
    })
  }

  if (isPreorder) {
    customAttributes.push({
      key: "_isPreorder",
      value: isPreorder.toString(),
    })
    customAttributes.push({
      key: "_preorderInfo",
      value: preorderInfo || "",
    })
  }

  return {
    customAttributes,
    variantId,
    quantity,
  } as Storefront.CheckoutLineItemInput
}

export const mapToLineItemInput = ({ node }: { node: CheckoutLineItem }) =>
  ({
    variantId: getIn(node, "variant.id"),
    quantity: getIn(node, "quantity"),
    customAttributes: R.map((customAttribute) =>
      R.pick(["key", "value"], customAttribute)
    )(getIn(node, "customAttributes")),
  } as Storefront.CheckoutLineItemInput)

// Returns two lists, first list contains changedLineItem matching variantId, second is unchangedLineItems
const getPartitionedLineItems = (
  lineItems: Array<{ node: CheckoutLineItem }>,
  variantIds: string[]
) =>
  R.pipe(
    R.map(mapToLineItemInput),
    R.partition(({ variantId: thisVariantId }) =>
      variantIds.includes(thisVariantId)
    )
  )(lineItems)

// Get line item from variantId
const getCurrentLineItem = (
  variantId: string,
  lineItems: Array<{ node: CheckoutLineItem }>
) =>
  R.find(
    (item: { node: CheckoutLineItem }) =>
      getIn(item, "node.variant.id") === variantId
  )(lineItems)

// keys are pre-pended with _ to hide in shopify store
const getCleanedCustomAttributeKey = (key: string) => R.replace(/^\_/, "", key)

export const getNormalizedCustomAttributes = (
  customAttributes: Storefront.Attribute[] | undefined
) => {
  return R.pipe(
    R.map((customAttribute: Storefront.Attribute) => [
      [getCleanedCustomAttributeKey(getIn(customAttribute, "key"))],
      getIn(customAttribute, "value"),
    ]),
    R.fromPairs
  )(customAttributes || [])
}

export const checkout: ModelConfig<CheckoutState> = {
  state: InitialState,
  reducers: {
    updateCheckout(state, data: CheckoutData) {
      return {
        ...state,
        checkoutId: data.id,
        data,
        lastQueryTimestamp: Date.now(),
      }
    },
    clearCheckout(state) {
      return {
        ...state,
        checkoutId: null,
        data: {} as CheckoutData,
        quantity: 0,
      }
    },
    updateQuantity(state, quantity: number) {
      return {
        ...state,
        quantity,
      }
    },
    setIsLoading(state, isLoading: boolean) {
      return {
        ...state,
        isLoading,
      }
    },
    setErrors(state, errors: Array<CheckoutUserError>) {
      return {
        ...state,
        errors,
      }
    },
  },
  effects: (dispatch: any) => ({
    async createCheckout(
      {
        cartItem,
        childCartItems = [],
      }: {
        cartItem: CartItem
        childCartItems?: CartItem[]
      },
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()

      dispatch.checkout.setIsLoading(true)
      const bundleId = childCartItems.length > 0 ? uuid() : undefined
      const lineItems = [
        createCheckoutLineItem({
          ...cartItem,
          bundleId,
        }),
        ...childCartItems.map((item) =>
          createCheckoutLineItem({
            ...item,
            parentBundleId: bundleId,
          })
        ),
      ]
      const { data, errors } = await client.mutate<CreateCheckoutResponse>({
        mutation: CreateCheckout,
        variables: {
          lineItems,
          email: state.user.email,
        },
      })
      dispatch.checkout.setIsLoading(false)

      if (errors) {
        //TODO: Handle graphql errors
        console.log(errors)
        return
      }

      const checkoutErrors: Array<CheckoutUserError> = getIn(
        data || {},
        "checkoutCreate.checkoutUserErrors",
        []
      )

      if (checkoutErrors.length > 0) dispatch.checkout.setErrors(checkoutErrors)

      const checkout: CheckoutData = getIn(
        data || {},
        "checkoutCreate.checkout"
      )
      dispatch.checkout.updateCheckout(checkout)
    },

    async createCheckoutBulk(
      payload: { cartItem: CartItem; childCartItems: CartItem[] }[],
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()

      dispatch.checkout.setIsLoading(true)
      const lineItems: Storefront.CheckoutLineItemInput[] = []
      payload.forEach(
        ({
          cartItem,
          childCartItems,
        }: {
          cartItem: CartItem
          childCartItems: CartItem[]
        }) => {
          const bundleId = childCartItems.length > 0 ? uuid() : undefined
          lineItems.push(
            createCheckoutLineItem({
              ...cartItem,
              bundleId,
            }),
            ...childCartItems.map((item) =>
              createCheckoutLineItem({
                ...item,
                parentBundleId: bundleId,
              })
            )
          )
        }
      )

      const { data, errors } = await client.mutate<CreateCheckoutResponse>({
        mutation: CreateCheckout,
        variables: {
          lineItems,
          email: state.user.email,
        },
      })
      dispatch.checkout.setIsLoading(false)

      if (errors) {
        //TODO: Handle graphql errors
        console.log(errors)
        return
      }

      const checkoutErrors: Array<CheckoutUserError> = getIn(
        data || {},
        "checkoutCreate.checkoutUserErrors",
        []
      )

      if (checkoutErrors.length > 0) dispatch.checkout.setErrors(checkoutErrors)

      const checkout: CheckoutData = getIn(
        data || {},
        "checkoutCreate.checkout"
      )
      dispatch.checkout.updateCheckout(checkout)
    },

    updateCheckout(checkout: CheckoutData) {
      // TODO: Fetch contentful products here
      if(checkout !== null) {
        checkout.id = stringToBase64(checkout.id);
      }

      if(checkout != null && checkout.lineItems != null) {
        checkout.lineItems.edges.map((checkoutLineItemEdge) => {
          checkoutLineItemEdge.node.id = stringToBase64(checkoutLineItemEdge.node.id);
          if(checkoutLineItemEdge.node.variant !== null) {
            checkoutLineItemEdge.node.variant.id = stringToBase64(checkoutLineItemEdge.node.variant.id);
            checkoutLineItemEdge.node.variant.product.id = stringToBase64(checkoutLineItemEdge.node.variant.product.id);
          }
          return checkoutLineItemEdge;
        })
        
      }
      const nodes = getIn(checkout, "lineItems.edges", [])
      dispatch.checkout.updateQuantity(
        nodes.reduce((accumulator: number, item: any) => {
          return accumulator + item.node.quantity
        }, 0)
      )
    },

    async updateCheckoutEmail(email: string, state: BaseRootState) {
      const client: DefaultClient<any> = createShopifyClient()

      const { data, errors } = await client.mutate<UpdateCheckoutEmailResponse>(
        {
          mutation: UpdateCheckoutEmail,
          variables: {
            checkoutId: state.checkout.checkoutId,
            email: email || state.user.email,
          },
        }
      )

      if (errors) {
        //TODO: Handle graphql errors
        console.log(errors)
        return
      }

      const checkoutErrors: Array<CheckoutUserError> = getIn(
        data || {},
        "checkoutEmailUpdateV2.checkoutUserErrors",
        []
      )

      if (checkoutErrors.length > 0) dispatch.checkout.setErrors(checkoutErrors)

      const checkout: CheckoutData = getIn(
        data || {},
        "checkoutEmailUpdateV2.checkout"
      )
      dispatch.checkout.updateCheckout(checkout)
    },

    async replaceCheckoutItems(
      lineItems: Storefront.CheckoutLineItemInput[],
      state: BaseRootState
    ) {
      const { checkoutId } = state.checkout
      const client: DefaultClient<any> = createShopifyClient()

      try {
        dispatch.checkout.setIsLoading(true)
        const { data, errors } = await client.mutate<UpdateCheckoutResponse>({
          mutation: UpdateCheckout,
          variables: {
            checkoutId,
            lineItems,
            email: state.user.email,
          },
        })

        if (errors) {
          //TODO: Handle graphql errors
          console.log(errors)
          return
        }

        const checkoutErrors: Array<CheckoutUserError> = getIn(
          data || {},
          "checkoutLineItemsReplace.checkoutUserErrors",
          []
        )

        if (checkoutErrors.length > 0)
          dispatch.checkout.setErrors(checkoutErrors)

        const checkout: CheckoutData = getIn(
          data || {},
          "checkoutLineItemsReplace.checkout"
        )
        dispatch.checkout.updateCheckout(checkout)
      } catch (error) {
        dispatch.checkout.setErrors([error])
      } finally {
        dispatch.checkout.setIsLoading(false)
      }
    },

    async addToExistingCheckout(
      {
        cartItem,
        childCartItems = [],
      }: { cartItem: CartItem; childCartItems?: CartItem[] },
      state: BaseRootState
    ) {
      const bundleId = childCartItems.length > 0 ? uuid() : undefined
      const currentLineItems =
        state.checkout.data?.lineItems?.edges?.map(mapToLineItemInput) || []
      const lineItems: Storefront.CheckoutLineItemInput[] = [
        ...currentLineItems,
        createCheckoutLineItem({
          ...cartItem,
          bundleId,
        }),
        ...childCartItems.map((item) =>
          createCheckoutLineItem({
            ...item,
            parentBundleId: bundleId,
          })
        ),
      ]

      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async addToExistingCheckoutBulk(
      payload: { cartItem: CartItem; childCartItems?: CartItem[] }[],
      state: BaseRootState
    ) {
      const currentLineItems =
        state.checkout.data?.lineItems?.edges?.map(mapToLineItemInput) || []
      let lineItems: Storefront.CheckoutLineItemInput[] = [...currentLineItems]
      payload.forEach(
        ({
          cartItem,
          childCartItems,
        }: {
          cartItem: CartItem
          childCartItems: CartItem[]
        }) => {
          const bundleId = childCartItems.length > 0 ? uuid() : undefined
          lineItems.push(
            createCheckoutLineItem({
              ...cartItem,
              bundleId,
            }),
            ...childCartItems.map((item) =>
              createCheckoutLineItem({
                ...item,
                parentBundleId: bundleId,
              })
            )
          )
        }
      )

      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async updateCheckoutItems(
      updatedCartItems: Record<string, CartItem>,
      state: BaseRootState
    ) {
      const currentLineItems = state.checkout.data?.lineItems?.edges || []
      const lineItems: Storefront.CheckoutLineItemInput[] = currentLineItems
        .map((lineItem) => {
          const updatedItem = updatedCartItems[lineItem.node?.id]
          if (updatedItem) {
            return createCheckoutLineItem(updatedItem)
          }

          return mapToLineItemInput(lineItem)
        })
        .filter((item) => item.quantity > 0)

      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async updateDiscount(discountCode: string | undefined, state) {
      const client: DefaultClient<any> = createShopifyClient()
      const checkoutId = state.checkout.checkoutId
      if (discountCode) {
        const { data, errors } = await client.mutate({
          mutation: ApplyDiscount,
          variables: {
            checkoutId,
            discountCode,
          },
        })

        if (errors) {
          //TODO: Handle graphql errors
          console.log(errors)
          return
        }

        const checkoutErrors: Array<CheckoutUserError> = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkoutUserErrors",
          []
        )

        if (checkoutErrors.length > 0) {
          dispatch.checkout.setErrors(checkoutErrors)
          return getIn(checkoutErrors, "0", checkoutErrors)
        }

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkout"
        )
        dispatch.checkout.updateCheckout(checkout)
        const discountValue = getIn(
          checkout,
          "discountApplications.edges.0.node.value"
        )
        if (discountValue) {
          if (discountValue.percentage)
            return { percentage: discountValue.percentage }
          if (discountValue.amount)
            return {
              amount: discountValue.amount,
              currencyCode: discountValue.currencyCode,
            }
        }
      } else {
        const { data, errors } = await client.mutate({
          mutation: RemoveDiscount,
          variables: {
            checkoutId,
          },
        })

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeRemove.checkout"
        )
        if (errors) {
          //TODO: Handle graphql errors
          console.log(errors)
          return
        }

        const checkoutErrors: Array<CheckoutUserError> = getIn(
          data,
          "checkoutDiscountCodeRemove.checkoutUserErrors",
          []
        )

        if (checkoutErrors.length > 0)
          dispatch.checkout.setErrors(checkoutErrors)
        dispatch.checkout.updateCheckout(checkout)
      }
      return
    },

    createDiscount(coupon: string | undefined, state) {

      if(state?.checkout?.checkoutId && state?.quiz?.previousQuizAnswers.length && (Date.now() - parseInt(state?.quiz?.previousQuizAnswers[0]?.date) < 86400000) && !state?.checkout?.data?.discountApplications.edges.length){
        // dispatch.checkout.createDiscount()
        
        dispatch.checkout.updateDiscount('QUIZ'+state.quiz.previousQuizAnswers[0]?.date)
        
      }
    },
    async removeCurrentDiscount(coupon: string | undefined, state) {
      if(state?.checkout?.data?.discountApplications.edges.length){
        dispatch.checkout.updateDiscount(undefined)
      }
    },

    addToCart(
      {
        cartItem,
        childCartItems,
      }: {
        cartItem: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
          isPreorder?: boolean
          preorderInfo?: string
        }
        childCartItems?: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
        }[]
      },
      state: BaseRootState
    ) {
      const payload = {
        cartItem: { ...cartItem, quantity: 1 },
        childCartItems: childCartItems?.map((item) => ({
          ...item,
          quantity: 1,
        })),
      }
      if (state.checkout.checkoutId) {
        dispatch.checkout.addToExistingCheckout(payload)
      } else {
        dispatch.checkout.createCheckout(payload)
      }
    },

    addToCartBulk(
      bulkItemList: {
        cartItem: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
          isPreorder?: boolean
          preorderInfo?: string
        }
        childCartItems?: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
        }[]
      }[],
      state: BaseRootState
    ) {
      let payload: any = []
      bulkItemList.forEach((item) => {
        payload.push({
          cartItem: { ...item.cartItem, quantity: 1 },
          childCartItems: item?.childCartItems
            ? item?.childCartItems?.map((item) => ({
                ...item,
                quantity: 1,
              }))
            : [],
        })
      })
      if (state.checkout.checkoutId) {
        dispatch.checkout.addToExistingCheckoutBulk(payload)
      } else {
        dispatch.checkout.createCheckoutBulk(payload)
      }
      if(state.checkout.checkoutId && state.quiz.previousQuizAnswers.length && (Date.now() - parseInt(state.quiz.previousQuizAnswers[0]?.date) < 86400000) && !state.checkout.data?.discountApplications.edges.length){
        // dispatch.checkout.createDiscount()
        dispatch.checkout.updateDiscount('QUIZ'+state.quiz.previousQuizAnswers[0]?.date)
      }
    },
  }),
}
