export const commonStyles = (isInvalid?: boolean) => ({
  borderRadius: "0.5rem",
  _invalid: {
    boxShadow: "0 0 0 0 sunrise",
  },
  borderColor: isInvalid ? "sunrise" : "noon",
  _placeholder: {
    color: isInvalid ? "sunrise" : "dawn",
  },
  _hover: {
    color: "dusk",
  },
  _focus: {
    boxShadow: "none",
    borderColor: isInvalid ? "sunriseDark20" : "dusk",
  },
  errorBorderColor: "sunrise",
})
