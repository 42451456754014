import { useContext } from "react"
import { useSelector } from "react-redux"
import {
  SIDEBAR_WIDTH_CLOSED,
  SIDEBAR_WIDTH_OPEN,
} from "../constants/measurements"
import { ScrollbarWidthContext } from "../context/scrollbarWidth"
import { rem } from "../utils"
import { BaseRootState } from "../redux/store"

export default function useLayoutMeasurements() {
  const scrollbarWidth = useContext(ScrollbarWidthContext)
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)
  const sidebarWidth = sidebarOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED

  const contentWidthDesktop = `calc(100vw - ${rem(
    sidebarWidth + scrollbarWidth
  )}rem)`
  const contentWidthMobile = "100vw"

  return {
    contentWidthMobile,
    contentWidthDesktop,
    contentWidthResponsive: [
      contentWidthMobile,
      null,
      null,
      null,
      contentWidthDesktop,
    ],
    sidebarWidth,
    scrollbarWidth,
  }
}
