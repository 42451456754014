import React from "react"
import {
  Flex,
  Icon,
  Box,
  PseudoBox,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
} from "@chakra-ui/core"

import { Button } from "../Buttons"
import Body from "../../components/typography/Body"
import { Question, NumberQuestion, GenderQuestion } from "../Quiz/QuizQuestions"
import * as QuizTypes from "../../redux/types/QuizTypes"
import { bp, LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import MobileHeader from "../../components/SideBar/MobileHeader"
import { AnswersType } from "../../redux/models/quiz"
import Heading from "../../components/typography/Heading"
import useBreakpointValue from "../../hooks/useBreakpointValue"
import StepHeader from "../StepHeader"
import { filterQuestions } from "../../redux/utils/quiz"

const EditAnswers: React.FC<{
  onExit: VoidFunction
  onSave: VoidFunction
  onAnswerEdit: (questionId: string, answer: string | Array<string>) => void
  formatedDate: string
  questions: Array<
    QuizTypes.Question & {
      answer: string | Array<string>
    }
  >
  answers?: AnswersType
}> = ({ onExit, onSave, onAnswerEdit, questions, answers, formatedDate }) => {
  const [editQuestionId, setEditQuestionId] = React.useState<string>("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onExitAndClean = () => {
    setEditQuestionId("")
    onClose()
  }
  const drawerSize = useBreakpointValue(["full", null, null, null, "md"])

  const fitleredQuestions = React.useMemo(() => {
    const filteredNonPreliminaryQuestions = questions.filter((question) => {
      switch (question.questionId) {
        case "email":
        case "phone":
        case "sms-consent":
          return false
        default:
          return true
      }
    })
    return filterQuestions({
      questions: filteredNonPreliminaryQuestions,
      answers,
    })
  }, [questions, answers])
  const hasUnanswered = !!fitleredQuestions.find((q) => !q.answer)

  return (
    <Flex h="100%" w="100%" flexDir="column">
      <StepHeader onClose={onExit}>
        <LaptopDownOnly>
          <Heading as="span" size="6" fontWeight="bold" color="dusk">
            {formatedDate}
          </Heading>
        </LaptopDownOnly>
        <LaptopUpOnly>
          <Flex>
            <Heading
              as="span"
              size="6"
              fontWeight="bold"
              ml="10px"
              color="#fff"
            >
              Edit fitting: &nbsp;
            </Heading>
            <Heading as="span" size="6" color="#fff">
              {formatedDate}
            </Heading>
          </Flex>
        </LaptopUpOnly>
      </StepHeader>
      <Box px="1.625rem" pb="4.8125rem" overflowY="auto">
        {fitleredQuestions.map((q) => {
          let answerText =
            q?.options?.find((option) => option.answerId === q.answer)
              ?.answerText || ""
          let answerColor = "#fff"
          if (q.questionId === "weight") {
            answerText = `${q.answer?.[0]}lbs`
          } else if (q.questionId === "height") {
            answerText = `${q.answer?.[0]}ft ${q.answer?.[1]}in`
          } else if (q.questionId === "gender" && q.answer) {
            answerText = q.answer as string
          } else if (!answerText) {
            answerText = "* Unanswered"
            answerColor = "sunrise"
          }
          return (
            <PseudoBox
              key={q.questionId}
              role="group"
              borderBottom="1px solid"
              borderBottomColor="dividerLine"
              p="0 0.875rem"
              _hover={{ borderRadius: "0.25rem", border: "1px solid #fff" }}
              cursor="pointer"
              onClick={() => {
                setEditQuestionId(q.questionId)
                onOpen()
              }}
            >
              <Flex
                p="1rem 0.875rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <Body size="md" color="#fff" opacity={1}>
                  {q.shortTitle}
                </Body>
                <Flex as="span" position="relative" alignItems="center">
                  <Body
                    size="md"
                    color={answerColor}
                    textAlign="right"
                    mr="30px"
                  >
                    {answerText}
                  </Body>
                  <PseudoBox
                    position={bp("static", "absolute")}
                    top="0"
                    bottom="0"
                    right="0"
                    margin="auto"
                    h="1.125rem"
                    w={["1.125rem", null, null, null, "0"]}
                    ml={["0.875rem", null, null, null, "0"]}
                    backgroundColor={bp("transparent", "noon")}
                    opacity={bp(1, 0)}
                    _groupHover={{ w: "1.125rem", opacity: 1 }}
                  >
                    <Icon name="pencil" size="100%" color="dusk" mt="-0.5rem" />
                  </PseudoBox>
                </Flex>
              </Flex>
            </PseudoBox>
          )
        })}
      </Box>
      <Flex
        position="relative"
        pt="2.5rem"
        pb="4.8438rem"
        px="2.5rem"
        alignItems="flex-end"
      >
        <Box
          position="absolute"
          bottom="100%"
          left="0"
          className="QuizEditAnswersList"
          background="linear-gradient(180deg,rgba(255,255,255,0) 0%,#FFFFFF 133.9%)"
          w="100%"
          h="4.8125rem"
          pointerEvents="none"
          zIndex={1}
        />
        <Box w="100%">
          {hasUnanswered && (
            <Body w="100%" color="sunrise" mb="1rem" textAlign="center">
              * Please answer new questions above
            </Body>
          )}
          <Button
            className="QuizButtonWhite"
            w="100%"
            isDisabled={hasUnanswered}
            onClick={() => {
              onSave()
              onExit()
            }}
          >
            Save &amp; Continue
          </Button>
        </Box>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={drawerSize}
        onClose={onExitAndClean}
      >
        <DrawerContent zIndex={1000002} className="BodyFitEditAnswerScreen">
          <DrawerHeader
            as={StepHeader}
            marginTop={bp("3.5rem", 0)}
            // @ts-ignore
            onClose={onExitAndClean}
          >
            <Heading
              as="span"
              size="6"
              fontWeight="bold"
              color="#fff"
              ml="10px"
            >
              Edit response
            </Heading>
          </DrawerHeader>
          <DrawerBody p="0 2.5rem">
            <Flex flexDir="column" justifyContent="center" h="100%">
              <LaptopDownOnly>
                <Box position="absolute" top="0" left="0" w="100vw">
                  <MobileHeader state="quiz" onExit={onExitAndClean} />
                </Box>
              </LaptopDownOnly>
              {questions.map((question) => {
                const questionProps = {
                  questionText: question.questionText,
                  currentQuestion: question.questionId === editQuestionId,
                  options: question.options,
                  onAnswer: (answerId: string | Array<string>) => {
                    onAnswerEdit(question.questionId, answerId)
                    onExitAndClean()
                  },
                }
                const answerId = answers?.[question.questionId]
                if (
                  question.questionId === "height" ||
                  question.questionId === "weight"
                ) {
                  return (
                    <NumberQuestion
                      key={`question-${question.questionId}`}
                      isEditing
                      {...questionProps}
                    />
                  )
                }
                if (question.questionId === "gender") {
                  return (
                    <GenderQuestion
                      key={`question-${question.questionId}`}
                      {...questionProps}
                    />
                  )
                }

                return (
                  <Question
                    key={`question-${question.questionId}`}
                    answerId={typeof answerId !== "string" ? "" : answerId}
                    {...questionProps}
                  />
                )
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default EditAnswers
