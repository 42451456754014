// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-addresses-tsx": () => import("./../../../src/pages/account/addresses.tsx" /* webpackChunkName: "component---src-pages-account-addresses-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-invalid-token-tsx": () => import("./../../../src/pages/account/invalid_token.tsx" /* webpackChunkName: "component---src-pages-account-invalid-token-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-pedal-points-tsx": () => import("./../../../src/pages/account/pedal-points.tsx" /* webpackChunkName: "component---src-pages-account-pedal-points-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-apps-product-registration-tsx": () => import("./../../../src/pages/apps/product-registration.tsx" /* webpackChunkName: "component---src-pages-apps-product-registration-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-pages-become-a-brand-ambassador-tsx": () => import("./../../../src/pages/pages/become-a-brand-ambassador.tsx" /* webpackChunkName: "component---src-pages-pages-become-a-brand-ambassador-tsx" */),
  "component---src-pages-pages-corporate-bulk-orders-tsx": () => import("./../../../src/pages/pages/corporate-bulk-orders.tsx" /* webpackChunkName: "component---src-pages-pages-corporate-bulk-orders-tsx" */),
  "component---src-pages-pages-pedal-points-tsx": () => import("./../../../src/pages/pages/pedal-points.tsx" /* webpackChunkName: "component---src-pages-pages-pedal-points-tsx" */),
  "component---src-pages-pages-rider-styles-tsx": () => import("./../../../src/pages/pages/rider-styles.tsx" /* webpackChunkName: "component---src-pages-pages-rider-styles-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-accessory-collection-tsx": () => import("./../../../src/templates/accessoryCollection.tsx" /* webpackChunkName: "component---src-templates-accessory-collection-tsx" */),
  "component---src-templates-accessory-product-list-tsx": () => import("./../../../src/templates/accessoryProductList.tsx" /* webpackChunkName: "component---src-templates-accessory-product-list-tsx" */),
  "component---src-templates-accessory-product-tsx": () => import("./../../../src/templates/accessoryProduct.tsx" /* webpackChunkName: "component---src-templates-accessory-product-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-bicycle-product-list-tsx": () => import("./../../../src/templates/bicycleProductList.tsx" /* webpackChunkName: "component---src-templates-bicycle-product-list-tsx" */),
  "component---src-templates-bicycle-product-tsx": () => import("./../../../src/templates/bicycleProduct.tsx" /* webpackChunkName: "component---src-templates-bicycle-product-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-customizer-tsx": () => import("./../../../src/templates/customizer.tsx" /* webpackChunkName: "component---src-templates-customizer-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-quiz-bike-select-tsx": () => import("./../../../src/templates/quizBikeSelect.tsx" /* webpackChunkName: "component---src-templates-quiz-bike-select-tsx" */)
}

