import React from "react"
import { Icon, Flex, Button, Box, ButtonProps } from "@chakra-ui/core"

import Body from "../../typography/Body"

const HeaderButton = (props: ButtonProps) => (
  <Button
    className="QuizMobileHeaderTabs"
    w="100%"
    h="100%"
    borderRadius="0"
    bg="primary"
    _hover={{ bg: "primary100" }}
    _active={{ bg: "primary200" }}
    {...props}
  />
)

const HeaderText: React.FC = ({ children }) => (
  <Body color="#F7F9FB" size="sm" fontWeight="bold">
    {children}
  </Body>
)

const OnePartLinkHeader: React.FC<{
  onClick: VoidFunction | undefined
  children
  className?: string
  style?: any
}> = ({ onClick, children, className, style }) => (
  <HeaderButton
    h="3.5rem"
    onClick={onClick}
    className={className}
    style={style}
  >
    <HeaderText>{children}</HeaderText>
    <Icon
      name="chevron"
      color="white"
      size="1.25rem"
      transform="rotate(180deg)"
      ml="0.75rem"
    />
  </HeaderButton>
)

const SplitClickText: React.FC<{
  position?: any
  top?: any
  zIndex?: any
  w?: any
  className?: any
  leftElement: JSX.Element
  rightElement: JSX.Element
}> = ({ rightElement, leftElement, position, top, zIndex, w, className }) => (
  <Flex
    className={className}
    position={position}
    top={top}
    zIndex={zIndex}
    bg="primary"
    h="3.5rem"
    w={w}
  >
    <Flex
      h="100%"
      w="50%"
      borderRight="1px solid"
      borderColor="rgba(255,255,255,0.6)"
      align="center"
      justify="center"
    >
      {leftElement}
    </Flex>
    <Flex h="100%" w="50%" align="center" justify="center">
      {rightElement}
    </Flex>
  </Flex>
)

interface QuizHeaderProps {
  isHomeScreen?: boolean
  hasPreviousResults?: boolean
  isQuizInProgress?: boolean
  onGetFitted?: VoidFunction
  onViewResults?: VoidFunction
  onContinue?: VoidFunction
  position?: any
  top?: any
  zIndex?: any
  w?: any
  className?: any
}

const QuizHeader: React.FC<QuizHeaderProps> = ({
  isHomeScreen = false,
  hasPreviousResults = false,
  isQuizInProgress = false,
  onGetFitted = () => {},
  onViewResults = () => {},
  onContinue,
  position,
  top,
  zIndex,
  w,
  className,
}) => {
  if (hasPreviousResults) {
    return (
      <SplitClickText
        className="topMobileFixedHeader"
        position={position}
        top={top}
        zIndex={zIndex}
        w={w}
        leftElement={
          <HeaderButton onClick={onViewResults}>
            <HeaderText>My Fittings</HeaderText>
          </HeaderButton>
        }
        rightElement={
          isQuizInProgress ? (
            <HeaderButton onClick={onContinue}>
              <HeaderText>Continue Fitting</HeaderText>
              <Icon
                name="chevron"
                color="white"
                size="1.25rem"
                transform="rotate(180deg)"
                ml="0.125rem"
                cursor="pointer"
              />
            </HeaderButton>
          ) : (
            <HeaderButton onClick={onGetFitted}>
              <Icon
                name="plus"
                color="white"
                size="1.25rem"
                mr="0.25rem"
                cursor="pointer"
              />
              <HeaderText>New Fitting</HeaderText>
            </HeaderButton>
          )
        }
      />
    )
  } else if (isQuizInProgress) {
    return (
      <OnePartLinkHeader
        onClick={onContinue}
        className="QuizMobileHeaderTabs topMobileFixedHeader continueFitting"
        style={{ position: "fixed", width: "100%", zIndex: "1004" }}
      >
        Continue Fitting
      </OnePartLinkHeader>
    )
  } else {
    return (
      <Flex
        className="QuizMobileHeader topMobileFixedHeader"
        bg="primary"
        h="auto"
        p="1rem 2rem"
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        top="3.5rem"
        w="100%"
        zIndex={1004}
      >
        <Box as="span">
          <HeaderText>
            <Flex
              className="QuizMobileHeaderText"
              justifyContent="space-between"
              flexDirection="column"
            >
              <span className="QuizMobileHeaderHeading">
                Your <span className="mobile-text-underline">PERFECT BIKE</span>{" "}
                Starts Here
              </span>
              <p className="QuizMobileHeaderSubHeading">
                E-Bikes & Bikes Customised to You
              </p>
            </Flex>
          </HeaderText>
        </Box>
        <span className="QuizMobileHeaderArrow">&#8594;</span>
        <Button
          className="QuizMobileWhiteBtn"
          bg="white"
          borderRadius="0.5rem"
          color="primary"
          w="7.9375rem"
          h="3rem"
          p="0"
          boxShadow="0px 0.625rem 1.875rem rgba(0, 196, 209, 0.2)"
          fontFamily="din"
          fontWeight="bold"
          fontSize="0.875rem"
          lineHeight="1.0625rem"
          letterSpacing="1px"
          textTransform="uppercase"
          onClick={onGetFitted}
        >
          Start Fitting
        </Button>
      </Flex>
    )
  }
}

export default QuizHeader
