import React from "react"
import { Box, CircularProgress, Flex, FlexProps } from "@chakra-ui/core"
import { useForm } from "react-hook-form"

import Heading from "../typography/Heading"
import { Button } from "../Buttons"
import { useDispatch, useSelector } from "react-redux"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { BaseSuccessStatusResponse } from "../../redux/models"
import SmallCaps from "../typography/SmallCaps"
import LinkButton from "../LinkButton"
import GoogleSignInButton from "../GoogleSignInButton"
import FacebookSignInButton from "../FacebookSignInButton"
import Body from "../typography/Body"
import TextField from "../FormFields/TextField"

export interface LoginFormValues {
  email: string
  password: string
}

export type LoginFormProps = FlexProps & {
  onLoginSuccess?: VoidFunction
  onSignUp: VoidFunction
  onForgotPassword: VoidFunction
}

const LoginForm: React.FC<LoginFormProps> = ({
  onLoginSuccess = () => {},
  onSignUp,
  onForgotPassword,
  ...props
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { accessToken, error } = useSelector(
    (state: BaseRootState) => state.user
  )
  const [loginSuccess, setLoginSuccess] = React.useState<
    "success" | "failed" | null
  >(null)
  const { handleSubmit, register } = useForm<LoginFormValues>()
  const [socialLoginStatus, setSocialLoginStatus] = React.useState<
    "loading" | "error" | null
  >(null)
  const [isLoggingIn, setIsLoggingIn] = React.useState(false)

  React.useEffect(() => {
    if (accessToken === null && loginSuccess === "success") {
      setLoginSuccess(null)
    }
  }, [accessToken])

  const onSubmit = async (values: LoginFormValues) => {
    setIsLoggingIn(true)
    setLoginSuccess(null)
    // @ts-ignore
    const response: BaseSuccessStatusResponse = await dispatch.user.login(
      values
    )
    if (response.success) {
      setLoginSuccess("success")
      onLoginSuccess()
    } else {
      setLoginSuccess("failed")
    }
    setIsLoggingIn(false)
  }

  const onGoogleSignIn = async (token: string) => {
    setSocialLoginStatus("loading")
    const response: any = await dispatch.user.loginWithSocial({
      type: "GOOGLE",
      token,
    })
    if (response.success) {
      setLoginSuccess("success")
      setSocialLoginStatus(null)
      onLoginSuccess()
    } else {
      setSocialLoginStatus("error")
    }
  }
  const onFacebookSignIn = async (token: string) => {
    setSocialLoginStatus("loading")
    const response: any = await dispatch.user.loginWithSocial({
      type: "FACEBOOK",
      token,
    })
    if (response.success) {
      setLoginSuccess("success")
      setSocialLoginStatus(null)
      onLoginSuccess()
    } else {
      setSocialLoginStatus("error")
    }
  }

  return (
    <Flex flexDirection="column" align="center" w="100%" {...props}>
      <Heading
        as="h5"
        size="5"
        color="night"
        fontWeight="bold"
        mb={["3.875rem", null, null, null, "3.25rem"]}
      >
        Login
      </Heading>
      <Box as="form" w="100%" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          placeholder="Email"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        <TextField
          name="password"
          type="password"
          placeholder="Password"
          w="100%"
          h="3rem"
          mb="0.75rem"
          ref={register({ required: true })}
        />
        {loginSuccess === "failed" && (
          <SmallCaps size="sm" color="sunrise" mt="1rem">
            Incorrect Email or Password
          </SmallCaps>
        )}
        <Button type="submit" w="100%" mt="2rem" isLoading={isLoggingIn}>
          Log In
        </Button>
      </Box>
      <Flex w="100%" justifyContent="center" mt="2rem">
        <LinkButton
          color="dawn"
          fontWeight="100"
          mr="40"
          onClick={onForgotPassword}
        >
          Forgot Password
        </LinkButton>
        <LinkButton color="primary" fontWeight="100" onClick={onSignUp}>
          Create Account
        </LinkButton>
      </Flex>
      <Flex direction="column" align="center" mt="10vh">
        <SmallCaps size="sm" fontWeight="bold" color="dusk" mb="1.5rem">
          or sign in with
        </SmallCaps>
        {socialLoginStatus !== "loading" && (
          <>
            <Flex>
              <GoogleSignInButton onSuccess={onGoogleSignIn} mr="0.375rem" />
              <FacebookSignInButton onSuccess={onFacebookSignIn} />
            </Flex>
            {socialLoginStatus === "error" && (
              <Body color="sunriseDark20" textAlign="center" mt="0.25rem">
                Something went wrong.
                <br />
                Please wait and try again
              </Body>
            )}
          </>
        )}
        {socialLoginStatus === "loading" && (
          <CircularProgress isIndeterminate color="gray" />
        )}
      </Flex>
    </Flex>
  )
}

export default LoginForm
