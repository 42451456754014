import { theme, ColorHues } from "@chakra-ui/core"

export default {
  ...theme.colors,
  buttonSecondary: "#EBF0F1",
  buttonSecondary100: "#E6EBEC",
  buttonSecondary200: "#E3E8E9",
  black: "#000000",
  blackTransparent: "rgba(0,0,0,0.4)",
  dark: "#1F1F1F",
  lightGray: "#E3E3E3",
  lightestGray: "#F3F3F3",
  gray: {
    ...theme.colors.gray,
    [400]: "#767676",
  },
  grayDisabled: "rgba(118, 118, 118, 0.5)",
  midDark: "#333742",
  blackAlpha05: "rgba(0, 0, 0, 0.05)",
  none: {
    50: "#00000000",
    100: "#00000000",
    200: "#00000000",
    300: "#00000000",
    400: "#00000000",
    500: "#00000000",
    600: "#00000000",
    700: "#00000000",
    800: "#00000000",
    900: "#00000000",
  } as ColorHues,

  // This Projects:
  primary: "#00C4D1",
  primary100: "#0ACEDB",
  primary200: "#00BCC9",
  // Light versions over the color over opacity x over white
  // Ex: primaryLight7 is primary over opacity 0.07 over white background
  primaryLight7: "#EAF9FB",
  primaryLight10: "#E6F9FA",
  primaryLight20: "#CAF1F5",
  primaryDividerLine: "rgb(0, 196, 209, 0.1)",
  primarySet: {
    50: "#d6ffff",
    100: "#aafbff",
    200: "#7af8ff",
    300: "#47f4ff",
    400: "#1af1ff",
    500: "#00d7e6",
    600: "#00a7b4",
    700: "#007981",
    800: "#00494f",
    900: "#001a1e",
  },
  sunrise: "#F9A59A",
  sunriseDark7: "#F19D92",
  sunriseDark20: "#E59186",
  sunriseLight7: "#FCF7F7",
  sunriseLight20: "#FFB5AA",
  daylight: "#FED88A",
  seafoam: "#7EDEE3",
  lavendar: "#C5BAE4",
  night: "#2C2E34",
  nightLight7: "#393b43",
  nightLight20: "#515560",
  // dusk: "#586067",
  dusk: "#000000",
  // dawn: "#909AA0",
  dawn: "#000000",
  noon: "#EBF0F1",
  dividerLine: "rgba(20, 32, 36, 0.05)",
  white: "#FFFFFF",
  markup: "#FF5CEF",
  background: "white",
  backgroundOpaque: "#FCFDFE",
  globalOverlay: "rgba(0, 58, 126, 0.01)",
  shadow: "rgba(18, 25, 35, 0.05)",
}
