import * as R from "ramda"
import { CheckoutLineItem } from "../../redux/models/checkout"
import { getIn } from "../../utils"

export const getFixedPrice = (numAsString: string | undefined) => {
  if (numAsString === undefined || numAsString === "NaN") return "--"
  try {
    return numAsString
      ? `$${(Math.round(parseFloat(numAsString as string) * 100) / 100).toFixed(
          2
        )}`
      : "--"
  } catch (e) {
    return ""
  }
}

export const getFixedPriceFromValue = (num: number | undefined) => {
  if (num === undefined || isNaN(num)) return "--"
  try {
    return num ? `$${(Math.round(num * 100) / 100).toFixed(2)}` : "--"
  } catch (e) {
    return ""
  }
}

export const getPriceValue = (
  pricingData: {
    id: string
    priceV2?: { amount?: string }
  }[],
  itemVariantId
) => {
  try {
    return (
      Number(
        getIn(
          pricingData?.find(({ id }) => id === itemVariantId) || {},
          "priceV2.amount",
          ""
        )
      ) || 0
    )
  } catch (e) {
    return 0
  }
}

export const getFormattedPrice = (
  pricingData: { priceV2?: { amount? } }[],
  itemVariantId
) => {
  try {
    return `${getFixedPrice(
      getIn(
        R.find(({ id }) => id === itemVariantId)(pricingData) as any,
        "priceV2.amount",
        ""
      )
    )}`
  } catch (e) {
    return ""
  }
}

/**
 * Grouping bundled cart/order items
 */

type BaseLineItem = {
  node: { customAttributes?: CheckoutLineItem["customAttributes"] }
}
const groupByCustomized = R.groupBy(
  <LineItem extends BaseLineItem>(item: LineItem) => {
    const attributes = R.indexBy(
      R.prop("key"),
      item.node?.customAttributes || []
    )
    const parentBundleId = attributes["_parentBundleId"]?.value
    return parentBundleId || "none"
  }
)

export type GroupedCustomizedLineItem<
  LineItem extends BaseLineItem
> = LineItem["node"] & {
  accessories: LineItem["node"][]
  warranty?: LineItem["node"]
}
export const groupCustomizedLineItems = <LineItem extends BaseLineItem>(
  lineItems: LineItem[]
) => {
  const customizedItemsMap = groupByCustomized<LineItem>(lineItems)

  return lineItems
    .map(({ node }) => {
      const attributes = R.indexBy(R.prop("key"), node?.customAttributes || [])
      const bundleId = attributes["_bundleId"]?.value!
      const hasParent = !!attributes["_parentBundleId"]
      const [accessories, warranty] = R.pipe(
        R.map<LineItem, LineItem["node"]>((item) => item.node),
        R.partition(
          (node) =>
            node.customAttributes!.find((attr) => attr.key === "_type")
              ?.value !== "warranty"
        )
      )(customizedItemsMap[bundleId!] || [])

      return !hasParent
        ? {
            ...node,
            accessories,
            warranty: warranty?.[0],
          }
        : null
    })
    .filter((x) => x !== null) as GroupedCustomizedLineItem<LineItem>[]
}
