import React from "react"
import { Text, BoxProps } from "@chakra-ui/core"

export type HeadingProps = Omit<BoxProps, "size"> & {
  size?: "1" | "2" | "3" | "4" | "5" | "6"
}

const Heading = ({ size = "1", ...props }: HeadingProps) => {
  let fontSize, letterSpacing
  switch (size) {
    case "1":
      fontSize = ["h3", null, null, null, "h1"]
      letterSpacing = "wide300"
      break
    case "2":
      fontSize = ["h4", null, null, null, "h2"]
      letterSpacing = ["wide200", null, null, null, "wide300"]
      break
    case "3":
      fontSize = ["h5", null, null, null, "h3"]
      letterSpacing = ["wide100", null, null, null, "wide300"]
      break
    case "4":
      fontSize = "h4"
      letterSpacing = "wide200"
      break
    case "5":
      fontSize = "h5"
      letterSpacing = "wide100"
      break
    case "6":
      fontSize = "h6"
      letterSpacing = "wide100"
      break
  }

  return (
    <Text
      as="h1"
      fontFamily="heading"
      fontSize={fontSize}
      lineHeight="normal"
      letterSpacing={letterSpacing}
      textTransform="uppercase"
      color="night"
      {...props}
    />
  )
}

export default Heading
