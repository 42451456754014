import React from "react"
import { Text, BoxProps } from "@chakra-ui/core"

export type BodyProps = Omit<BoxProps, "size"> & {
  size?:
    | "large"
    | "lg"
    | "normal"
    | "medium"
    | "md"
    | "small"
    | "sm"
    | "xsmall"
    | "xs"
}

export const bodyProps = (size: BodyProps["size"]) => {
  let fontSize, letterSpacing
  switch (size) {
    case "large":
    case "lg":
      fontSize = "bodyLg"
      break
    case "small":
    case "sm":
      fontSize = "bodySm"
      letterSpacing = "thin100"
      break
    case "xsmall":
    case "xs":
      fontSize = "bodyXs"
      break
    case "normal":
    case "medium":
    case "md":
    default:
      fontSize = "body"
      letterSpacing = "thin200"
  }

  return {
    fontSize,
    letterSpacing,
    lineHeight: "normal",
    fontFamily: "body",
  }
}

const Body = ({ size, ...props }: BodyProps) => {
  return <Text {...bodyProps(size)} {...props} />
}

export default Body
