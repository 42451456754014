import React from "react"
import {
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuButtonProps,
  MenuItemProps,
  Flex,
  MenuListProps,
} from "@chakra-ui/core"

import Body from "../typography/Body"
import ColorDot from "../ColorDot"

export interface OptionsProps {
  title: string
  name: string
  colorDot?: string
  selected: boolean
}

type DropDownType = "multi" | "single" | "single_top"

type FilterDropDownProps = Omit<MenuButtonProps, "children"> & {
  title?: string
  options: Array<OptionsProps>
  onOptionSelected?: (name: string) => void
  dropDownType?: DropDownType
  showCheckMarks?: boolean
  showChevron?: boolean
  color?: string
  mobileHeight?: any
  itemProps?: MenuItemProps
  menuListProps?: Omit<MenuListProps, "children">
  isDisabled?: boolean
}

const DropDownSelector: React.FC<FilterDropDownProps> = ({
  options,
  title,
  dropDownType = "multi",
  onOptionSelected,
  showCheckMarks = true,
  showChevron = false,
  mobileHeight,
  color = "sunrise",
  itemProps = {},
  menuListProps = {},
  isDisabled = false,
  ...props
}: FilterDropDownProps) => {
  const [optionsState, setOptionsState] = React.useState(options)

  React.useEffect(() => {
    setOptionsState(options)
  }, [options])

  const onItemSelect = (name: string) => {
    if (onOptionSelected) onOptionSelected(name)
    if (dropDownType === "multi")
      setOptionsState(
        optionsState.map((opt) => ({
          ...opt,
          selected: name === opt.name ? !opt.selected : opt.selected,
        }))
      )
    if (dropDownType === "single" || dropDownType === "single_top")
      setOptionsState(
        optionsState.map((opt) => ({
          ...opt,
          selected: opt.name === name,
        }))
      )
  }

  const isSingleTop = dropDownType === "single_top"

  let height = mobileHeight ? mobileHeight : "4rem"

  return (
    <Menu closeOnSelect={dropDownType === "single" || isSingleTop}>
      {({ isOpen }) => (
        <>
          <MenuButton
            className="QuizMobileDropdown"
            as={Button}
            h={[height, null, null, null, "2rem"]}
            _hover={optionsState.length > 1 ? { bg: "dividerLine" } : {}}
            _active={optionsState.length > 1 ? { bg: "noon" } : {}}
            cursor={
              isSingleTop && optionsState.length <= 1 ? "default" : "pointer"
            }
            display="flex"
            justifyContent="space-between"
            // @ts-ignore
            variant="ghost"
            isDisabled={isDisabled}
            borderRadius="0.5rem"
            rightIcon={showChevron ? "chevron-down" : undefined}
            color={isOpen || isSingleTop ? color : "dusk"}
            {...props}
          >
            <Body
              lineHeight="1.375rem"
              fontWeight={isSingleTop ? "500" : isOpen ? "medium" : "normal"}
              fontFamily={isSingleTop ? "din" : undefined}
            >
              {dropDownType === "multi"
                ? title
                : optionsState.find((opt) => opt.selected)?.title || title}
            </Body>
            {!isSingleTop ||
              (optionsState.length > 1 && (
                <Icon
                  name="chevron"
                  size="1.25rem"
                  transform={isOpen ? "rotate(90deg)" : "rotate(-90deg)"}
                  ml="0.75rem"
                />
              ))}
          </MenuButton>
          <MenuList
            minWidth={["100vw", null, null, null, "0px"]}
            border="none"
            borderRadius="0.5rem"
            p="0"
            overflow="hidden"
            style={{
              boxShadow: "0px 0.25rem 6.25rem rgba(0, 0, 0, 0.05)",
            }}
            {...menuListProps}
          >
            {optionsState.map(({ name, title, colorDot, selected }, index) => (
              <MenuItem
                key={name}
                onClick={() => onItemSelect(name)}
                display={isSingleTop && selected ? "none" : "flex"}
                justifyContent="space-between"
                p="1rem"
                minWidth="9.5rem"
                borderBottom={
                  index !== optionsState.length - 1 ? "1px solid" : ""
                }
                borderColor="dividerLine"
                _active={{
                  bg: "noon",
                }}
                _hover={{
                  bg: "dividerLine",
                }}
                {...itemProps}
              >
                <Flex align="center" justify="center">
                  {colorDot && <ColorDot colorCode={colorDot} mr="0.75rem" />}
                  <Body
                    as="span"
                    lineHeight="1.375rem"
                    color={selected ? color : "dusk"}
                    fontWeight={selected ? "medium" : "normal"}
                  >
                    {title}
                  </Body>
                </Flex>
                {selected && showCheckMarks && (
                  <Icon name="checkMark" size="1rem" color={color} />
                )}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default DropDownSelector
