import { BaseDispatch, BaseRootState } from "../store"
import { ModelConfig } from "@rematch/core"

export type SideBarStep = "fit" | "select-bike" | "customize"

export interface SideBarStateType {
  isOpen: boolean
  sideBarStep: SideBarStep
  animate: boolean
}

const initialState: SideBarStateType = {
  isOpen: true,
  sideBarStep: "fit",
  animate: false,
}

export const sidebar: ModelConfig<SideBarStateType> = {
  state: initialState,
  reducers: {
    setIsOpen(state: SideBarStateType, isOpen: boolean) {
      return { ...state, isOpen }
    },
    setAnimate(state: SideBarStateType, animate: boolean) {
      return { ...state, animate }
    },
    setSideBarStep(state: SideBarStateType, sideBarStep: SideBarStep) {
      return { ...state, sideBarStep }
    },
  },
  effects: (dispatch: any) => ({
    toggleOpen(_, state: BaseRootState) {
      dispatch.sidebar.setIsOpen(!state.sidebar.isOpen)
    },
  }),
}
