import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { up, between } from "styled-breakpoints"
import facepaint from "facepaint"
import { useMediaQuery } from "react-responsive"
import breakpoints, { breakpointsList } from "../constants/breakpoints"

/**
 * Facepaint media query utility for creating custom responsive props like chakra
 */
export const mq = facepaint(
  breakpointsList.map((bp) => `@media (min-width: ${bp}px)`)
)

/**
 * Responsive breakpoint shortcut for chakra props
 * @param mobile Mobile up value
 * @param desktop Desktop up value
 */
export const bp = (mobile: any = null, desktop: any = null) => [
  mobile,
  null,
  null,
  null,
  desktop,
]

/**
 * Responsive breakpoint shortcut for handling the limbo zone with the sidebar
 * @param mobile Mobile up value
 * @param desktop Desktop up value
 * @param isSideBarOpen Are we in the limbo zone
 */
export const bpWithSidebar = (
  mobile: any = null,
  desktop: any = null,
  isSideBarOpen: boolean
) => [mobile, null, null, null, isSideBarOpen ? mobile : desktop, desktop]

/**
 * Responsive hooks
 */
export const useLaptopUpQuery = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.laptop}px)`,
  })

/**
 * Responsive component sections
 */
type WithSideBar = {
  isSideBarOpen?: boolean
}

export const MobileOnly = styled.div`
  ${up("mobile")} {
    display: none;
  }
`

export const MobileUpOnly = styled.div`
  display: none;
  ${up("mobile")} {
    display: inline;
  }
`

export const BelowTabletOnly: React.FC<WithSideBar> = ({
  children,
  isSideBarOpen,
}) => (
  <Box as="span" display={bpWithSidebar("contents", "none", !!isSideBarOpen)}>
    {children}
  </Box>
)

export const TabletUpOnly = styled.div`
  display: none;
  ${up("tablet")} {
    display: inline;
  }
`
export const LaptopDownOnly: React.FC<BoxProps & WithSideBar> = ({
  children,
  isSideBarOpen,
  ...props
}) => (
  <Box
    as="span"
    {...props}
    display={bpWithSidebar("contents", "none", !!isSideBarOpen)}
  >
    {children}
  </Box>
)

export const LaptopUpOnly: React.FC<BoxProps & WithSideBar> = ({
  children,
  isSideBarOpen,
  ...props
}) => (
  <Box
    as="span"
    {...props}
    display={bpWithSidebar("none", "contents", !!isSideBarOpen)}
  >
    {children}
  </Box>
)

export const DesktopOnly = styled.div`
  display: none;
  ${between("desktop", "maxWidth")} {
    display: inline;
  }
`
