import {
  pintrkAddToCart,
  pintrkPageView,
  pintrkSignUp,
  pintrkSearch,
} from "./PinterestPixel"
import {
  fbqPageView,
  fbqAddToCart,
  fbqSignUp,
  fbqSearch,
  fbqProductView,
} from "./FacebookPixel"
import { tikTokPageView, tikTokTrack } from "./TikTokPixel"

export const trackPageView = () => {
  pintrkPageView()
  fbqPageView()
  tikTokPageView()
}

export const trackProductView = ({
  productId,
  productName,
  productType,
  value,
}: {
  productId: string
  productName: string
  productType: string
  sku: string
  quantity: number
  value: number
}) => {
  const productIdNumber = parseInt(productId);
  fbqProductView({
    product_name: productName,
    content_category: productType,
    content_ids: [productIdNumber],
    value,
  })
  tikTokTrack("ViewContent")
}

export const trackAddToCart = ({
  productId,
  productName,
  productType,
  value,
}: {
  productId: string
  productName: string
  productType: string
  value: number
}) => {
  const productIdNumber = parseInt(productId);
  pintrkAddToCart(value, 1)
  fbqAddToCart({
    product_name: productName,
    content_category: productType,
    content_ids: [productIdNumber],
    value,
  })
  tikTokTrack("AddToCart", {
    content_id: productId,
    quantity: 1,
    price: value,
    value: value,
    currency: "USD",
  })
}

export const trackSignUp = () => {
  pintrkSignUp()
  fbqSignUp()
  tikTokTrack("CompleteRegistration")
}

export const trackSearch = (query: string) => {
  pintrkSearch(query)
  fbqSearch(query)
  tikTokTrack("Search")
}
