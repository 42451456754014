import React from "react"
import { getIn } from "."
import * as TransformTypes from "./TransformTypes"
import { mapURL } from "./url"
import { FluidObject } from "gatsby-image"
import { gatsbyFluidImage } from "./Storybook"
import { BikeType } from "../redux/types/BikeTypes"

export interface BikeListingType {
  title: string
  internalTitle: string
  slug: string
  gender: string
  styles: Array<string>
  terrains: Array<string>
  speeds?: Array<string>
  wheelSizes?: Array<string>
  types: Array<string>
  bodyFit: TransformTypes.BikeBodyFit
  bestSeller?: boolean
  variants: Array<{
    uniqueId: string
    productHandle: string
    speed: string
    color: { internalTitle: string; hexCode: string; name: string }
    isNew?: boolean
    forSale?: boolean
    image?: FluidObject | null
  }>
}

const defaultPlaceholderImage = gatsbyFluidImage(
  require("../assets/images/placeholder.png"),
  1
)

export const getVariantsFromSpeed = (speeds: BikeType["speeds"]) => {
  return speeds?.reduce((acc, speed) => {
    const speedVariants =
      speed?.variants?.map((variant) => {
        const image = variant?.productListingImage?.fluid
        return {
          product: speed,
          speed: speed.speed,
          uniqueId: variant?.shopifyId,
          productHandle: speed.internalTitle,
          color: variant?.color
            ? {
                internalTitle: variant.color?.internalTitle,
                hexCode: variant.color?.hexCode,
                name: variant.color?.name,
              }
            : null,
          isNew: variant?.isNew || false,
          forSale: variant?.forSale || false,
          image: image || defaultPlaceholderImage,
        }
      }) || []
    return [...acc, ...speedVariants]
  }, [])
}

const getTransformedData = ({ data, pageContext }) => {
  const { bicycleSlugs, bikeSlug } = pageContext
  const cleanedData = {} as any

  if (bicycleSlugs) {
    const formatedBikes: Array<BikeListingType> = getIn(
      data,
      "bicycles.edges",
      []
    ).reduce((acc, { node }, i) => {
      if (node.speeds) {
        const speeds = node.speeds.filter(
          ({ speed, variants }) =>
            speed != null &&
            variants?.filter(({ color }) => color != null)?.length > 0
        )
        if (speeds.length > 0) {
          return [
            ...acc,
            {
              title: node.formattedTitle,
              internalTitle: node.internalTitle,
              slug: mapURL("bike-page", node.internalTitle),
              gender: node.gender,
              styles: node.style,
              terrains: node.terrain,
              speeds: node.speeds.map((bike) => bike.speed),
              wheelSizes: node.speeds.map((bike) => {
                if (bike.wheelSize) {
                  return bike.wheelSize
                }
              }),
              types: node.type,
              variants: getVariantsFromSpeed(speeds),
              bestSeller: node.bestSeller,
              bodyFit: {
                maxWeight: node.bodyFitMaxWeight,
                minHeight: node.bodyFitMinHeight,
                maxHeight: node.bodyFitMaxHeight,
              },
            },
          ]
        }
      }
      return acc
    }, [])
    cleanedData.bikes = formatedBikes
    cleanedData.filters = [
      {
        title: "Speed", // TODO: Change this once the speed structure is final
        options: [
          { title: "Mens", name: "mens", selected: false },
          { title: "Womens", name: "womens", selected: false },
        ],
      },
      {
        title: "Color", // TODO: change once color are in
        options: [
          { title: "Mens", name: "mens", selected: false },
          { title: "Womens", name: "womens", selected: false },
        ],
      },
    ]
  }

  if (bikeSlug) {
    const { bike, images, messages, ...filteredOut } = data
    const variants: Array<TransformTypes.BikeProductPageVariantType> =
      bike?.speeds?.reduce((acc, cur) => {
        const tmp =
          cur.variants?.map((val) => ({
            shopifyId: val.shopifyId,
            productListingImage: val.productListingImage?.fluid,
            internalTitle: val.internalTitle,
            contentfulProductId: cur.contentful_id,
            contentfulVariantId: val.contentful_id,
            sku: val.sku,
            speed: cur.speed,
            layout: val.imageLayout?.internal?.content
              ? JSON.parse(val.imageLayout?.internal?.content).layout
              : null,
            color: val.color,
            product: cur,
            hideCustomizerButton: val.hideCustomizerButton,
            hideNotifyMeButton: val.hideNotifyMeButton,
          })) || []
        return [...acc, ...tmp]
      }, [])
    const bikeData: TransformTypes.BikeProductPageDataType = {
      ...bike,
      title: bike.title,
      variants,
      internalTitle: bike.internalTitle,
      formattedTitle: bike.formattedTitle,
      imageLayout: JSON.parse(
        bike?.bikeImages?.internal?.content || '{ "layout": [] }'
      ),
      bodyFit: {
        maxHeight: bike.bodyFitMaxHeight,
        minHeight: bike.bodyFitMinHeight,
        maxWeight: bike.bodyFitMaxWeight,
      },
      enableFreeShippingMessage: bike.enableFreeShippingMessage,
      enable365DayTestRideMessage: bike.enable365DayTestRideMessage,
      enable90DayTestRideMessage: bike.enable90DayTestRideMessage,
      enableNationwideServiceMessage: bike.enableNationwideServiceMessage,
      enableWarrantyMessage: bike.enableWarrantyMessage
    }
    cleanedData.freeShipping = {
      modalTitle: messages.freeShippingModalTitle,
      body: messages.freeShippingMessage.raw,
    }
    cleanedData.testRide = {
      modalTitle: messages.testRideModalTitle,
      body: messages.dayTestRideDescription.raw,
    }
    cleanedData.ninetyTestRide = {
      modalTitle: messages.ninetyDayTestRideModalTitle,
      body: messages.ninetyDayTestRideModalDescription.raw,
    }
    cleanedData.bike = bikeData
    cleanedData.layoutImages = images
  }

  return {
    ...data,
    ...cleanedData,
  }
}

interface Props {
  data: any
  pageContext: any
}
export default function <A extends Props>(
  WrappedComponent: React.ComponentType<A>
) {
  const Wrapped = (props: A) => (
    <WrappedComponent {...props} data={getTransformedData(props)} />
  )
  return Wrapped
}
