import gql from "graphql-tag"
import {
  CheckoutFields,
  ShopifyProductFields,
  ShopifyProductVariantFields,
  CustomerFields,
  ShopifyProductVariantFieldsType,
} from "./fragments"

export const GetCheckout = gql`
  ${CheckoutFields}
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...CheckoutFields
    }
  }
`

export const GetShopifyProductPricing = gql`
  ${ShopifyProductFields}
  query GetShopifyProductPricing($productIds: [ID!]!) {
    nodes(ids: $productIds) {
      ...ShopifyProductFields
    }
  }
`

export type ShopifyVariantPricing = ShopifyProductVariantFieldsType
export type GetShopifyVariantPricingResponse = {
  nodes: ShopifyProductVariantFieldsType[]
}
export const GetShopifyVariantPricing = gql`
  ${ShopifyProductVariantFields}
  query GetShopifyVariantPricing($productIds: [ID!]!) {
    nodes(ids: $productIds) {
      ...ShopifyProductVariantFields
    }
  }
`

export const GetCheckoutCompleted = gql`
  query GetCheckoutCompleted($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        completedAt
      }
    }
  }
`

export const GetProducts = gql`
  ${ShopifyProductFields}
  query GetProducts {
    products(first: 100) {
      edges {
        node {
          ...ShopifyProductFields
        }
      }
    }
  }
`

export const GetVariants = gql`
  ${ShopifyProductVariantFields}
  query GetVariants($productId: ID!) {
    node(id: $productId) {
      ... on Product {
        id
        variants(first: 100) {
          edges {
            node {
              ...ShopifyProductVariantFields
            }
          }
        }
      }
    }
  }
`

export const GetCustomerInfo = gql`
  ${CustomerFields}
  query GetUserInfo($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      ...CustomerFields
    }
  }
`

export const GetShipsToCountries = gql`
  query GetShipsToCountries {
    shop {
      shipsToCountries
    }
  }
`
