import React from "react"
import { Button, ButtonProps } from "@chakra-ui/core"
import { css } from "@emotion/core"
import animations from "../../constants/animations"

type LinkButtonProps = ButtonProps & {
  // to: string
}

const LinkButton = ({ color = "primary", ...props }: LinkButtonProps) => {
  return (
    <Button
      variant="link"
      display="inline-block"
      fontFamily="avenirNext"
      fontSize="1rem"
      lineHeight="1.375rem"
      color={color}
      textDecoration="none"
      css={css`
        :hover {
          :after {
            transform: scaleX(1);
          }
        }
      `}
      _hover={{
        textDecoration: "none",
      }}
      _after={{
        content: `""`,
        display: "block",
        height: "1px",
        backgroundColor: color,
        transform: "scaleX(0)",
        transformOrigin: "left",
        transition: `transform 200ms ${animations.bezier}`,
      }}
      {...props}
    />
  )
}

export default LinkButton
