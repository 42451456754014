import React from "react"
import { Box, BoxProps, Flex } from "@chakra-ui/core"
import Slider from "../Slider"
import IconButton from "../Buttons/IconButton"
import Body, { BodyProps } from "../typography/Body"
import styled from "@emotion/styled"

type InfoBannerProps = BoxProps & {
  slides: {
    content: string | JSX.Element
  }[]
  slideDuration?: number
  backgroundColor?: string
  textColor?: string
}

const SlideContent = styled(Body)`
  a {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }

  p {
    margin-bottom: 0;
  }
`

const Slide: React.FC<
  BodyProps & { content: InfoBannerProps["slides"][0]["content"] }
> = ({ content, ...props }) => {
  return (
    <SlideContent
      as="div"
      textAlign="center"
      padding="1rem"
      dangerouslySetInnerHTML={
        typeof content === "string" ? { __html: content } : undefined
      }
      {...props}
    >
      {typeof content !== "string" ? content : undefined}
    </SlideContent>
  )
}

export default function InfoBanner({
  slideDuration = 5,
  textColor,
  slides = [],
  ...props
}: InfoBannerProps) {
  if (slides.length === 0) return null
  return (
    <Box {...props}>
      {slides.length === 1 ? (
        <Slide content={slides[0].content} color={textColor} />
      ) : (
        <Slider
          items={slides.map((slide, index) => {
            return (
              <Slide key={index} content={slide.content} color={textColor} />
            )
          })}
          loop
          autoPlay
          autoPlayInterval={slideDuration}
          hideArrows
          draggable={false}
          slideContainerProps={{
            alignItems: "center",
          }}
        >
          {({ Slides, scrollNext, scrollPrev }) => (
            <Flex align="center" justify="center">
              <IconButton
                aria-label="Previous"
                icon="chevron"
                onClick={scrollPrev}
                color={textColor}
                size="xl"
              />
              <Box minWidth="300px">{Slides}</Box>
              <IconButton
                aria-label="Next"
                icon="chevron"
                onClick={scrollNext}
                color={textColor}
                transform="rotate(180deg)"
                size="xl"
              />
            </Flex>
          )}
        </Slider>
      )}
    </Box>
  )
}
