import React, { useEffect, useState } from "react"
import config from "../../config"
import { initTikTokPixel } from "../../vendor/tikTokPixel"

export const tikTokTrack = (event: string, data?: any) => {
  if (window) {
    try {
      //@ts-ignore
      window.ttq.track(event, data)
    } catch (e) {
      console.error(e)
    }
  }
}

export const tikTokPageView = () => {
  if (window) {
    try {
      //@ts-ignore
      window.ttq.page()
    } catch (e) {
      console.error(e)
    }
  }
}

export default function TikTokPixel({ location }) {
  if (!window || !config.GATSBY_TIKTOK_PIXEL_ID) return null
  const [inited, setInited] = useState(false)
  const [initLocation, setInitLocation] = useState<string | null>(null)

  useEffect(() => {
    initTikTokPixel()
    setInited(true)
    setInitLocation(location.pathname)
  }, [])

  useEffect(() => {
    if (inited && initLocation !== location.pathname) {
      tikTokPageView()
    }
  }, [inited, location.pathname])

  return <></>
}
