import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import config from "../../config"
import fetchPonyfill from "fetch-ponyfill"

const { fetch } = fetchPonyfill()
export default () =>
  new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    link: new HttpLink({
      uri: config.GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          config.GATSBY_SHOPIFY_STOREFRONT_API_KEY,
      },
      fetch,
    }),
  })
