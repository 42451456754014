import Maybe from "graphql/tsutils/Maybe"

export const capitlizeAllWord = (text: string): string =>
  text
    .split(" ")
    .map((txt) => txt.charAt(0).toUpperCase() + txt.slice(1))
    .join(" ")

export const stripHTML = (string = "") => string.replace(/(<([^>]+)>)/gi, "")

export const unformatPhoneNumber = (phoneNumber?: Maybe<string>) => {
  return phoneNumber ? phoneNumber.replace(/\D/g, "") : ""
}

export const stringToBase64 = (inputString: string) => {
  if(isBase64(inputString)) return inputString
  const utf8Bytes = new TextEncoder().encode(inputString);
  const base64String = btoa(String.fromCharCode(...utf8Bytes));
  return base64String;
}

export const isBase64 = (str: string) => {
  // Base64 characters: A-Z, a-z, 0-9, +, /, =
  const base64Chars = /^[A-Za-z0-9+/=]+$/;

  // Check for valid length and characters
  return base64Chars.test(str) && str.length % 4 === 0 && str.length >= 4;
}
