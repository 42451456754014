import React from "react"
import {
  ModalCloseButton as ChakraModalCloseButton,
  CloseButtonProps,
} from "@chakra-ui/core"

type ModalCloseButtonProps = CloseButtonProps & {}

export default function ModalCloseButton({ ...props }: ModalCloseButtonProps) {
  return (
    <ChakraModalCloseButton
      w="2.75rem"
      h="2.75rem"
      size="lg"
      color="dawn"
      _active={{
        outline: "none",
      }}
      {...props}
    />
  )
}
