import get from "lodash/get"
import isNil from "lodash/isNil"

export type SortMethodValue =
  | "BY_RELEVANCE"
  | "BY_PRICE_LOW"
  | "BY_PRICE_HIGH"
  | "BY_A_Z"
  | "BY_Z_A"

const getVariantPrice = (variant) =>
  Number(get(variant, "pricing.priceV2.amount", "-"))

export const getLowestPrice = (variants) => {
  const result =
    variants?.reduce((lowest, next) => {
      const price = getVariantPrice(next)
      if (isNaN(price)) {
        return lowest
      }
      if (price < lowest) {
        return price
      }
      return lowest
    }, Infinity) || Infinity
  return result !== Infinity ? result : null
}

export const getHighestPrice = (variants) => {
  const result =
    variants?.reduce((highest, next) => {
      const price = getVariantPrice(next)
      if (isNaN(price)) {
        return highest
      }
      if (price > highest) {
        return price
      }
      return highest
    }, -1) || -1
  return result !== -1 ? result : null
}

const hasInStockVariant = (bike) =>
  bike?.variants?.find(
    (variant) =>
      variant?.pricing?.availableForSale &&
      !variant?.pricing?.currentlyNotInStock
  )

export const sortMethods: Record<
  SortMethodValue,
  (a: any, b: any) => number
> = {
  BY_RELEVANCE: (a, b) => {
    // Preferred index
    const aPreferredIndex =
      a?.preferredIndex >= 0 ? a.preferredIndex : undefined
    const bPreferredIndex =
      b?.preferredIndex >= 0 ? b.preferredIndex : undefined
    if (!isNil(aPreferredIndex) && !isNil(bPreferredIndex)) {
      return a.preferredIndex - b.preferredIndex
    } else if (!isNil(aPreferredIndex)) {
      return 1
    } else if (!isNil(bPreferredIndex)) {
      return -1
    }

    // In stock
    const aInStock = hasInStockVariant(a)
    const bInStock = hasInStockVariant(b)
    if (aInStock && bInStock) {
      return 0
    } else if (aInStock) {
      return -1
    } else if (bInStock) {
      return 1
    }

    // Quiz match percent
    if (a?.matchPercent || b?.matchPercent) {
      const aScore = a?.matchPercent || 0
      const bScore = b?.matchPercent || 0
      return bScore - aScore
    }

    // Best seller
    if (a?.bestSeller && !b?.bestSeller) {
      return -1
    }
    if (!a?.bestSeller && b?.bestSeller) {
      return 1
    }

    return 0
  },
  BY_PRICE_LOW: (a, b) => {
    const priceA = getLowestPrice(a.variants)
    const priceB = getLowestPrice(b.variants)
    return (
      (priceA !== null ? priceA : Infinity) -
      (priceB !== null ? priceB : Infinity)
    )
  },
  BY_PRICE_HIGH: (a, b) => {
    const priceA = getHighestPrice(a.variants)
    const priceB = getHighestPrice(b.variants)
    return (priceB !== null ? priceB : -1) - (priceA !== null ? priceA : -1)
  },
  BY_A_Z: (a, b) => {
    return a.title.localeCompare(b.title)
  },
  BY_Z_A: (a, b) => {
    return b.title.localeCompare(a.title)
  },
}
