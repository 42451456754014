import contentfulClient from "./contentfulClient"

const GRAPHQL_SCHEMA_TITLE = "GraphQL schema - do not delete"

export const contentfulSearchBikes = async (searchText: string, limit = 48) => {
  if (searchText.length < 1) return
  const { items } = await contentfulClient.getEntries({
    query: searchText,
    "fields.title[ne]": GRAPHQL_SCHEMA_TITLE,
    content_type: "bicycleModel",
    limit,
    include: 1,
  })
  return items
}

export const contentfulGlobalsettings = async (searchText: string, limit = 48) => {
  if (searchText.length < 1) return
  const { items } = await contentfulClient.getEntries({
    query: searchText,
    "fields.configTitle[ne]": GRAPHQL_SCHEMA_TITLE,
    content_type: "globalConfiguration",
    limit,
    include: 1,
  })
  return items
}


export const contentfulCollectionsSearch = async (
  searchText: string,
  limit = 48
) => {
  if (searchText.length < 1) return
  const { items } = await contentfulClient.getEntries({
    query: searchText,
    "fields.title[ne]": GRAPHQL_SCHEMA_TITLE,
    content_type: "collection",
    limit,
    include: 2,
  })
  return items
}
