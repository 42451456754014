import React from "react"
import { Flex, FlexProps, Icon } from "@chakra-ui/core"

type SidebarToggleProps = FlexProps & {
  isOpen?: boolean
}

const SidebarToggle = ({ isOpen = true, ...props }: SidebarToggleProps) => {
  return (
    <Flex
      role="button"
      aria-label="toggle sidebar"
      h="3rem"
      w="1rem"
      bg="white"
      border="1px"
      borderColor="dividerLine"
      borderTopLeftRadius="0.625rem"
      borderBottomLeftRadius="0.625rem"
      borderRight="none"
      justify="flex-end"
      align="center"
      zIndex={2}
      position="relative"
      top="0"
      bottom="0"
      right={"-1px"}
      my="auto"
      cursor="pointer"
      {...props}
    >
      <Icon
        name="chevron"
        size="1.25rem"
        transform={
          isOpen
            ? "rotate(180deg) translateX(-0.25rem)"
            : "rotate(0deg) translateX(0.25rem)"
        }
        color={isOpen ? "dawn" : "primary"}
      />
    </Flex>
  )
}

export default SidebarToggle
