import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import ForgotPasswordForm from "./ForgotPasswordForm"
import LoginForm, { LoginFormProps } from "./LoginForm"
import SignUpForm, { SignUpFormProps } from "./SignUpForm"

export type AuthContainerFormState = "login" | "signup" | "forgot"

export type AuthContainerProps = FlexProps & {
  initialForm?: AuthContainerFormState
  onLoginSuccess?: LoginFormProps["onLoginSuccess"]
  onSignUpSuccess?: SignUpFormProps["onSignUpSuccess"]
}

export default function AuthContainer({
  initialForm = "login",
  onLoginSuccess,
  onSignUpSuccess,
  ...props
}: AuthContainerProps) {
  const [formState, setFormState] = React.useState<AuthContainerFormState>(
    initialForm
  )

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      minWidth="22.5rem"
      maxWidth="100%"
      h="100%"
      px="3.625rem"
      {...props}
    >
      {formState === "login" && (
        <LoginForm
          w="100%"
          px="0"
          onSignUp={() => setFormState("signup")}
          onForgotPassword={() => setFormState("forgot")}
          onLoginSuccess={onLoginSuccess}
        />
      )}
      {formState === "signup" && (
        <SignUpForm
          w="100%"
          px="0"
          onLogin={() => setFormState("login")}
          onSignUpSuccess={onSignUpSuccess}
        />
      )}
      {formState === "forgot" && (
        <ForgotPasswordForm
          w="100%"
          onBackToLogin={() => setFormState("login")}
        />
      )}
    </Flex>
  )
}
